.my-pagination ::ng-deep .ngx-pagination .current {
  background: rgb(34, 34, 34);
  margin-top: 14px;
}
#veg-img-prt {
    margin-right: -8px !important;
}
.inq-sp-1 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;
}

.product-lisitng{
  .inq-cart-bw-st{
    width: fit-content;
  }
  .inq-sp-2{
    padding: 0px 18px;
  }
}

#productRow{
  padding-top: 20px !important;
}
#totalItems{
  font-size: 14px; margin-top: 10px;
}
#productListHead
{
  margin-bottom: 2px !important;
 
}
.clelistProduct
{
  cursor: pointer;
   margin: 15px 10px ;
}
@media only screen and (max-width: 768px) {
}
.breadCrumbListing
{
  margin-bottom: 5px !important;
}
@media  screen and (max-width: 600px) {
  .breadCrumbListing {
    padding-left: 10px;
  }
}
@media  screen and (min-width: 640px) {
.left_off_container{
  padding-left: 0px !important;
  margin-left: 0px !important;
  max-width: 100% !important;
}

}
.listProduct
{
  cursor: pointer;
  margin: 15px 10px !important;
}
.listProductkbook
{
  cursor: pointer;
  margin: 0px 0% !important;
}
.clelistProduct
{
  cursor: pointer;
  margin: 0px 0px !important;
}
.inq-sp-3 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;

}
.inq-sp-1 i:hover,.inq-sp-3 i:hover{
    background: $pallet2;
    color: #fff;
    cursor: pointer;   
}

@import "../../../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
.wishlist-heart
{
float: right; 
color: #375673;
}
#img-list{
  cursor: pointer;
  object-fit: contain;
  display: block;
  margin: auto;
}
.mayan_hide{
  display:none;
  

}
#mydiv:hover + .mayan_hide{
  display: block!important;
  width: 98%;
  height: 11%;
  border-radius: 10px;
  box-shadow: 0 2px 14px #0003, 
  0 4px 14px #00000020
}
  
#img-list-vimpro{
  cursor: pointer;
  object-fit: cover;
}
.img-fluid1{
    width: 350px;
    height: 375px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 10%;
  }

.productTitleList  
{
  transform: translate3d(0px, 0px, 0px);
}
.product-image {
    width: 250px;
    max-width: 100%;
    height: 200px;
    max-height: 100%;
    overflow: hidden;
    // object-fit: contain;
}
.product-image-shubhcards {
  width: 300px;
  max-width: 100%;
  height: 200px;
  max-height: 100%;
  overflow: hidden;
  // object-fit: contain;
}

#priceListing{
  color: #212529 !important
}
.add-cart{
  border-radius: 5px;
  background-color: $white !important;
}
.review-productList{
  color: #373b3f !important; 
  font-size: 11px !important
}
.rating{
  color: #373b3f !important; 
  font-size: 11px !important;
}
#pagination-productList{
  font-size: 14px; float: right;
}
.add-cart1{
  color: $white;
  border-radius: 5px;
  // background-color: #8c945b !important;
  .inq-cart-bw-st{
    border-radius: 5px;
    border: 1px solid $footer-bg !important;
    background-color: $footer-bg !important;
  }
}



.inq-cart-bw-st{
  border: 1px solid $footer-bg !important;
  background-color: $white !important;
}

.add-cart:hover
{
  cursor: pointer;
}

.home:hover
{
  cursor: pointer;
}

.img-fluid{
  @include media-breakpoint-up(sm) {
      height: 250px;
  }

  @include media-breakpoint-down(sm) {
      height: 230px;
  }
}

.mobile-cart{
	display: none !important;
}

.mobile-only{
	display: none !important;
}

@media screen and (max-width:600px) {
  .mobile-cart{
		display: flex !important;
		float:right;
	}

  .listProduct
{
  cursor: pointer;
  margin-top:10px !important;
  margin-bottom: 10px !important;
  
}
.listProductkbook
{
  cursor: pointer;
  margin-top:10px !important;
  margin-bottom: 10px !important;
  
}

	.desktop-carousel{
		display: none !important;
	}
	.desktop-only{
		display: none !important;
	}

	.mobile-only{
		display: block !important;
	}

	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
	.scale-9{
		transform: scale(0.9);
	}
	.scale-8{
		transform: scale(0.8);
	}
	.earthy-card{
        margin: 15px !important;
    }
  
}