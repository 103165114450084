.box{
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 35px 15px;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  width: 100%;
  background-color: $white;
}
.order-confirm
{

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-top: 20px;

}

.order-circle
{

  width: 20px;
  height: 20px;
  border: 2px solid #90ba4c;
  border-radius: 50%;
}

.thanks
{
  font-size: 20px;
  font-weight: bolder;
}

.order-info
{
  font-size: medium;

  a:hover
  {
      cursor: pointer;
  }

  span
  {
      font-size: large;
  }
}
.order-confirm-page{
  .mt-2.inq-cart-pay-st.img-size img {
    width: 100px;
    height: 31px;
  }
  #emailinput{

      border-radius: 10px !important;
  }

  @media screen and (max-width:600px) {
      .inq-cart-ul-st li.nav-item {
          padding: 0px !important;
      }
  }
  @media screen and (max-width:768px) {
      .inq-cart-ul-st li.nav-item {
          margin-bottom: 15px !important;
      }
  }

  .inq-btn-st {
      font-weight: 600 !important;
  }

  .inq-mc-ordn1-st p img {
    margin: 0px 5px;
  }
  .inq-mc-ordn1-st p  {
  line-height: 27px; 
  }


    
    @media screen and (min-width: 1200px) {
      .card {
        height: 350px;
      }
    }
    .card {
      background: transparent;
      cursor: default;
      transform-style: preserve-3d;
      transition: transform 0.4s ease 0s;
      width: 100%;
      -webkit-animation: giro 1s 1;
      animation: giro 1s 1; border: none;
    }
    
    .front p {
        color: #323232 !important;
    }
    .front h5 {
      color: #323232 !important;
      font-size: 17.5px;
    
  }
    .card .front {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: $white;
      display: block;
      height: 100%;
      padding: 0;
      
      position: absolute;
      text-align: center;
      width: 100%
    }
    
    .card .back {
      // backface-visibility: hidden;
      box-sizing: border-box;
      color: $white;
      display: block;
      font-size: 1.2em;
      padding:0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
    .card .front strong {
      background: $white;
      border-radius: 100%;
      color: #222;
      font-size: 1.5em;
      line-height: 30px;
      padding: 0 7px 4px 6px;
    }
    
    .card .back {
      transform: rotateY( 180deg);
    }
    
    
    @media screen and (max-width: 768px) {
      .cardBox {
        margin-left: 2.8%;
        margin-top: 3%;
        width: 90%;
      }
      .card {
        height: 385px;
      }
      .cardBox:last-child {
        margin-bottom: 3%;
      }
      .tick-image {
        position: absolute;
      /* right: 55%; */
      // left: 34%;
      bottom: 4px;
      }
    }
    @media screen and (max-width: 1199px) and (min-width:769px) {
      .card {
        height: 385px;
      }
      #innerpart{
        padding: 10px;
      }
      .tick-image {
        position: absolute;
        left: 35%;
        bottom: 4px;
      }
    }
    @media screen and (max-width: 767px) and (min-width:481px) {
      .tick-image {
        position: absolute;
        right: 35%;
        bottom: 4px;
      }
    }
    @media screen and (max-width: 480px) {
      .cardBox {
        width: 94.5%;
      }
      .card {
        height: 170px;
      }
      .tick-image {
        position: absolute;
        right: 35% !important;
        bottom: 0px;
        width: 38px;
        height: 38px;
      }
    }


  // OUR CODE 
  .help-text{
    color: #807f7f;
  }

  .cutome-deliverylabel{
    margin-left: 0px !important;
  } 
}
@media  screen and (max-width:600px) {
  .orders-container-mob{
    margin-left: -5%;
    width: 106%;
    transform: scale(0.9);
  }
  
}