.header-section{
    top:0px;
    width: 100%;
    background-color: $primary;
    min-height: 55px;
    @include media-breakpoint-down(sm){
        margin-top:55px;
        min-height: auto;
    }
}





@media (max-width: 640px) {
    .topspace{
        margin-top: 100px; 
    }

   
   
}

@media only screen and (min-width: 640px) {
    .topspace{
       
        margin-top: 0px;
    }

    
}


.logo-section{
    margin-left: 150px;
    padding: 5px 0px;
    @include media-breakpoint-down(sm){
        margin-left: 10px; 
    }
}

.logo-section1{
    margin-left: 150px;
    padding: 5px 0px;
    @include media-breakpoint-down(sm){
        margin-left: 10px; 
        margin-top: 12%;
    }
}

.logo-images{
    width: 100px;
    cursor: pointer;
    @include media-breakpoint-down(sm){
        width: 165px;
        height:40px;
    }
}

.banner-section{
    margin-left: 30px;
    margin-top: 15px;
    @include media-breakpoint-down(sm){
        margin-left: 5px;
        margin-top: 3px;
        font-size: 10px !important;
    }
    }

    @media (max-width: 640px) {
        .header-section1{
            top:0px;
            width: 100%;
            margin-top: 100px;
            background-color: $primary;
            min-height: 55px;
            @include media-breakpoint-down(sm){
                margin-top:55px;
                min-height: auto;
            }
        }
    .header-section2{
        top:0px;
        margin-top: 100px !important;
        width: 100%;
        background-color: #0095da;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
    .header-sectionLush{
        top:0px;
        margin-top: 101px !important;
        width: 100%;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
    .header-section3{
        top:0px;
        margin-top: 100px !important;
        width: 100%;
        background-color: #4e90b2;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
}
@media (min-width: 640px) {
    .header-section1{
        margin-top: 0px;
        top:0px;
        width: 100%;
        background-color: $primary;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
    .header-section2{
        margin-top: 0px !important;
        top:0px;
        width: 100%;
        background-color: #0095da;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
    .header-section3{
        margin-top: 0px !important;
        top:0px;
        width: 100%;
        background-color: #4e90b2 ;
        min-height: 55px;
        @include media-breakpoint-down(sm){
            margin-top:55px;
            min-height: auto;
        }
    }
}