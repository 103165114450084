    .mt-2.inq-cart-pay-st.img-size img {
        width: 100px;
        height: 31px;
      }
      #emailinput{
      
          border-radius: 10px !important;
      }
      
      @media screen and (max-width:600px) {
          .inq-cart-ul-st li.nav-item {
              padding: 0px !important;
          }

          .float-right-mob {
            float: right;
          }
      }
      @media screen and (max-width:768px) {
          .inq-cart-ul-st li.nav-item {
              margin-bottom: 15px !important;
          }
      }
      select {
         font-weight: bolder;
      }
      .font-bold-two {
          font-weight: 500;
          font-family: system-ui;
      
      }
      .font-bold {
          font-weight: bolder;
          font-family: system-ui;
      
      }
      .inq-btn-st {
          font-weight: 600 !important;
      }
      .codlable {
          color:orange;
          font-weight: bolder;
          font-size: 22px;
          padding-right: 13px;
      }
      .inq-mc-ordn1-st p img {
        margin: 0px 5px;
      }
      .inq-mc-ordn1-st p  {
       line-height: 27px; 
      }
      
      .reward {
          color: $footer-bg;
          font-weight: bold;
      }
      .text-bold {
          font-weight: 600;
      }
      
      .image-tick {
          position: absolute;
          top: -4px;
          right: 25%;
      
      }
      
      @media screen and (max-width:576px) {
          .image-tick {
      
              right: 36%;
          
          }
      }
      
      #take {
          color:grey;
          font-weight: 500;
      }

      .add-cart1{
        .fa-style i.fa{
          background-color: transparent;
        }
      }
      
     .cardBox {
          float: left;
          font-size: 1.2em;
          margin: 1% 0 0 1%;
          perspective: 800px;
          transition: all 0.3s ease 0s;
          width: 90%;
        }
      //   .customer-card {
      //     background:  url(../../assets/images/vendor/dumm/du1/Prorect.png) center center no-repeat;
      //   }
        
        .cardBox:hover .customer-card {
          transform: rotateY( 180deg);
        }

        .cardBox:hover{
          @if $product-cd != "tams"{
            .back{
              z-index: 99;
            }
            .front{
              z-index: 1;
              display: none;
            }
          }
          
        }
     
        .cardBox:not(:hover){
          @if $product-cd != "tams"{
            .back{
              z-index: 0;
            }
            .front{
              z-index: 99;
            }
          }
        }
        
        @media screen and (min-width: 1200px) {
          .customer-card {
            height: 350px;
          }
          .cardBox {
              width: 90%;
          }
          #innerpartFront {
              padding: 2.5rem 0rem;
      }
        }
        .customer-card {
          background: transparent;
          cursor: default;
          transform-style: preserve-3d;
          transition: transform 0.4s ease 0s;
          width: 100%;
          -webkit-animation: giro 1s 1;
          animation: giro 1s 1; border: none;
        }
        
        #innerpart {
            background-color: #EDE8CA;
            border-radius: 30px;
            padding: 4rem 0rem;
        }
       
        #innerpartFront {
          background-color: #E8E8E8;
          border-radius: 30px;
          padding: 2.5rem 0rem;
      }
       #innerpartFront img {
          max-width: 100%;
          padding: 0.5rem;
          height: 185px;
          margin-bottom: 1rem;
       }
        .front p {
            color: #323232 !important;
        }
        .front h5 {
          color: #323232 !important;
          font-size: 17.5px;
         
      }
      .checkout {
        color: #FFFCF8;
          font-size: 13px;
          position: absolute;
          padding: 11px 22px;
          text-transform: uppercase;
          text-decoration: none;
          top:28%;
          left: 28%;
          transform: translate(-28% , -28% );
          border-radius: 28px;
          background: $pallet2;
          border: 1px solid $pallet2;
          font-weight: 600;
          transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          margin: 0;
      }
      @media screen and (max-width:786px) {
        /* .checkout {
          padding: 6px 11px;
          font-size: 10px;
          position: relative;
          left: 14%;
          bottom: 40px
        } */
      }
      @media screen and (max-width:480px) {
        .checkout {
          padding: 6px 11px;
          font-size: 10px;
          position: relative;
          bottom: 3px;
          left: 11%;
        }
      }
      .checkout a {
        text-decoration: none;
        color: #FFFCF8
      }
        .customer-card .front {
          // backface-visibility: hidden;
          box-sizing: border-box;
          color: white;
          display: block;
          height: 100%;
          padding: 0;
          
          position: absolute;
          text-align: center;
          width: 100%
        }
        
        .customer-card .back {
          // backface-visibility: hidden;
          box-sizing: border-box;
          color: white;
          display: block;
          font-size: 1.2em;
          padding:0;
          position: absolute;
          text-align: center;
          width: 100%;
        }
        .customer-card .front strong {
          background: #fff;
          border-radius: 100%;
          color: #222;
          font-size: 1.5em;
          line-height: 30px;
          padding: 0 7px 4px 6px;
        }
        
        .customer-card .back {
          transform: rotateY( 180deg);
        }
        
        .customer-card .back button {
          background: $pallet2 !important;
          border: 1px solid  $pallet2 !important;
          border-radius: 20px !important;
          color: white;
          padding: 0.375rem 1.5rem !important;
        }
        
        /* .customer-card .back a:hover {
          background: #fff;
          color: #333;
          text-shadow: 0 0 1px #333;
        } */
        
        .textblack{
          color: #333 !important;
          font-size: 15px;
          line-height: 1.7;
        }
        .textblack1 {
            font-size: 22px;
            color: #333 !important;
        }
        .textbrrown {
            color: $footer-bg;
            font-weight: bold;
        }
        .cardBox:nth-child(2) .customer-card {
          -webkit-animation: giro 1.5s 1;
          animation: giro 1.5s 1;
        }
        
        .cardBox:nth-child(3) .customer-card {
          -webkit-animation: giro 2s 1;
          animation: giro 2s 1;
        }
        
        .cardBox:nth-child(4) .customer-card {
          -webkit-animation: giro 2.5s 1;
          animation: giro 2.5s 1;
        }
        
        @-webkit-keyframes giro {
          from {
            transform: rotateY( 180deg);
          }
          to {
            transform: rotateY( 0deg);
          }
        }
        
        @keyframes giro {
          from {
            transform: rotateY( 180deg);
          }
          to {
            transform: rotateY( 0deg);
          }
        }
        
        @media screen and (max-width: 768px) {
          .cardBox {
            margin-left: 2.8%;
            margin-top: 3%;
            width: 90%;
          }
          .customer-card {
            height: 385px;
          }
          .cardBox:last-child {
            margin-bottom: 3%;
          }
          .tick-image {
            position: absolute;
          /* right: 55%; */
          // left: 34%;
          bottom: 4px;
          }
        }
        @media screen and (max-width: 1199px) and (min-width:769px) {
          .customer-card {
            height: 385px;
          }
          #innerpart{
            padding: 10px;
          }
          .tick-image {
            position: absolute;
            left: 35%;
            bottom: 4px;
          }
        }
        @media screen and (max-width: 767px) and (min-width:481px) {
          .tick-image {
            position: absolute;
            right: 35%;
            bottom: 4px;
          }
        }
        @media screen and (max-width: 480px) {
          .cardBox {
            width: 94.5%;
          }
          .customer-card {
            height: 345px;
          }
          .tick-image {
            position: absolute;
            right: 35% !important;
            bottom: 0px;
            width: 38px;
            height: 38px;
          }
        }
       
        @media screen and  (min-width:1200px) {
          #innerpart {
            height: 350px !important;
          }
          #innerpartFront {
            height: 350px !important;
          }
          .tick-image {
            width: 37px;
            height: 36px;
            margin-top: -2px;
          }
        }
      
        .innerpart {
            padding: 30px;
        }
      
      .bag {
        left: 17%;
      }
      
      .static-image{
        right:0%;
        margin-top:75px;
      }
      
      
      // OUR CODE
      .loginLink{
        cursor: pointer;
      }
      
      .custom-buttons{
        padding: 1px 20px;
      }
      
      .pad{
        margin-right: -70px;
      }
      
      .help-text{
        color: #807f7f;
      }
      
      .disable-btn{
        pointer-events: none;
      }
      
      .reveal-if-active {
        opacity: 1;
        transition: 0.5s;
        // transform: scale(1);
        overflow: visible;
      }
      
      .hide-if-inactive{
        opacity: 0;
        max-height: 0;
        transition: 0.5s;
        transform: scale(0.8);
        overflow: hidden;
      }
      
      .custom-brn-grp{
          border-radius: 0px !important;
      }
      
      .otp-form{
          margin-top: 30px;
      }
      
      .custome-nospace{
          padding-left: 0px !important;
      }
      
      .login-seperator {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
      }
      .login-seperator:before, .login-seperator:after{
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #cacaca;
        margin: auto;
      }
      .login-seperator:before {
        margin-right: 10px
      }
      .login-seperator:after {
        margin-left: 10px
      }
      
      .form-check{
        font-size: 1.4rem !important;
        margin-left: 15px;
        color: $footer-bg;
        font-weight: bold;
      }
      
      .form-check-label{
        margin-left: 7px !important;
      }
      
      .form-check-input{
        margin-top: 7px;
      }
      
      // input[type=radio]{
      //   width: 20px !important;
      //   height: 20px !important;
      // }
      
      .shipping-container{
        border: 1px solid #ced4da;
        padding: 10px 15px;
        border-radius: .25rem;
        box-shadow: rgba(140,148,91,0.75) 0px 1px 4px;
      }
      
      .sub-heading{
        color: #000000;
        font-weight: 600;
        cursor: pointer;
      }
      
      .address-container{
        border: 1px solid #706e6e;
        padding: 10px 15px;
        border-radius: .25rem;
        margin-bottom: 15px;
      }
      
      .address-font{
        font-size: 0.8rem;
        color: #706e6e;
      }
      
      .address-divider{
        margin: 10px 0px;
        border-top: 1px solid #ced4da;
      }
      
      .address-lines{
        margin-top: 8px;
      }
      
      .cutome-deliverylabel{
        margin-left: 0px !important;
      }
      
      // RAINBOW TEXT
      #shadowBox {
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.2);
        /* Black w/opacity/see-through */
        border: 3px solid;
      }
      
      .rainbow {
        // text-align: center;
        // text-decoration: underline;
        margin-top: 5px;
        font-weight: 800;
        font-style: italic;
        // font-family: monospace;
        // letter-spacing: 5px;
      }
      .rainbow_text_animated {
        background: linear-gradient(to right, #F51720, #FA26A0, #F8D210, #2FF3E0);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        animation: rainbow_animation 6s ease-in-out infinite;
        background-size: 400% 100%;
      }
      
      @keyframes rainbow_animation {
        0%,100% {
            background-position: 0 0;
        }
      
        50% {
            background-position: 100% 0;
        }
      }
      
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      
      .payment-option-card{
        // border: 1px solid #a7a6a6;
        border-radius: 5px;
        padding: 10px 15px;
        box-shadow: 0 0 4px #dad8d8;
        min-width: 450px;
        max-width: 450px;
      }
      
      .payment-option-active{
        border: 1px solid $footer-bg;
      }
      
      .payment-option-title{
        color: #000000;
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
      
      .payment-option-instructions{
        color: #919191;
        font-size: 0.8rem;
        font-weight: 100;
      }

      .customer-card{
        @if $product-cd == "tams"{
            display:none;
        }
        @else if $product-cd == "earthyworthy"{
          display:none;
        }
        @else{
            display: block;
        }
      }
      
      .customer-breadcrumb:hover
      {
          cursor: pointer;
      }
      
      .inq-sp-1 i{
        font-size: 12px !important;
        // background: #333;
        color: #fff;
        // padding: 3px;
        // border-radius: 25px;
        // width: 18px;
        // height: 18px;
      }
      
      
      .inq-sp-3 i{
        font-size: 12px !important;
        // background: #333;
        color: #fff;
        // padding: 3px;
        // border-radius: 25px;
        // width: 18px;
        // height: 18px;
      
      }
      .inq-sp-1 i:hover,.inq-sp-3 i:hover{
        background: $pallet2;
        color: #fff;
        cursor: pointer;   
      }
      
      .add-cart1{
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        color: #ffffff;
        border-radius: 5px;
        background-color: $footer-bg !important;
        .inq-cart-bw-st{
          border-radius: 5px;
          border: 1px solid $footer-bg !important;
          background-color: $footer-bg !important;
        }
      }
      
      i.fa{
        background: none;
        // padding: 0px 3px!important;
        padding: 0px 0px;
      }
      
      .add-cart2{
        width: 50%;
        color: #ffffff;
        border-radius: 5px;
        background-color: $footer-bg !important;
        .inq-cart-bw-st{
          border-radius: 5px;
          border: 1px solid $footer-bg !important;
          background-color: $footer-bg !important;
        }
      }
.aligncheckout_button{
  width: 100%;

}