@charset "UTF-8";
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../../../..//assets/fonts/earthyworthy/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "proxima-nova";
  src: url("../../../fonts/earthyworthy/Proxima Nova Font.otf") format("opentype");
}
@media (min-width: 1300px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 93% !important;
  }
}
/*@media (min-width: 992px) {
 .navbar-toggler {
  display: none;
 }
 .btn.close-btn {
  display: none;
 }

}*/
@media (min-width: 991px) {
  .trers-grid-image img {
    max-width: 150px;
  }
  .best_topContact {
    margin-top: 20px;
  }
}
@media (max-width: 1199px) {
  footer .footer-links {
    display: block;
  }
  .free-shipping-section ul {
    flex-flow: wrap;
  }
  .free-shipping-section ul li {
    margin-bottom: 15px;
  }
  body {
    padding-top: 0px;
  }
  .header .flex-container .header-row-one {
    display: block;
  }
  .header .flex-container .header-row-one .account-section {
    position: absolute;
    top: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
    left: 0;
    z-index: 99;
    background: #fff;
  }
  .header .flex-container .header-row-one .account-section .account {
    justify-content: flex-end;
    margin-top: 10px;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
}
@media (max-width: 991px) {
  .product-details-wrapper .slider-nav {
    bottom: 6px;
  }
  .product-details-wrapper .details-wrapper .product-all-details .row .col-left {
    flex: auto;
  }
  .product-details-wrapper .details-wrapper .product-all-details .product-addtional-details {
    padding-bottom: 15px;
  }
  .product-details-wrapper .details-wrapper .product-all-details .product-addtional-details:before {
    width: 100%;
  }
  .banner-content .content h2, .banner-content .content h1 {
    font-size: 30px;
  }
  /**Product**/
  .product-box-inner .product-image {
    max-width: 150px;
  }
  .product-box-inner .product-detail .price-wrapper {
    position: inherit;
    transform: none;
  }
  .product-box-inner .product-detail .add-to {
    position: inherit;
  }
  .product-box-inner .verified {
    top: 10px;
  }
  .best-products .row-fluid {
    display: block;
  }
  .best-products .row-fluid .tyers-grid {
    justify-content: space-between;
  }
  .best-products .row-fluid .tyers-grid li {
    display: inline-block;
    margin: 15px 0px 15px 0px;
    text-align: center;
  }
  .best-products .column.single-image {
    margin-top: 15px;
  }
}
@media (min-width: 640px) {
  .best_view_cart {
    width: 24% !important;
    left: 0px !important;
  }
  .best_after-cart {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 640px) {
  .header-section4 {
    margin-top: 100px !important;
  }
  .best_cart_button {
    width: 45% !important;
  }
  .best_view_cart {
    width: 40% !important;
    left: 0px !important;
  }
  .best_after-cart {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .mob-filter {
    z-index: 200;
    background-color: #ffffff;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .filter-button {
    display: block !important;
    position: fixed;
    bottom: 4%;
    left: 45%;
    width: 50%;
    height: 8%;
    border-radius: 50px;
    border: 2px solid #3c57a6;
    font-size: 18px;
    font-weight: 600;
    -webkit-box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 0px 33px -10px rgba(0, 0, 0, 0.59);
  }
  .details-wrapper {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .details-wrapper .slider-nav {
    bottom: 6px;
    max-width: 240px;
    min-width: 230px;
  }
  .banner-content .content h2, .banner-content .content h1 {
    font-size: 20px;
  }
  .aside-group {
    display: block;
  }
  /**Title**/
  .content-title .title-box .title:after {
    display: none;
  }
  .header .flex-container {
    display: block;
  }
  .header .flex-container .logo {
    text-align: center;
    margin: 0px auto;
  }
  .header .flex-container .header-row-one .search-bar-group .search-bar {
    margin-top: 10px;
    margin-right: -4rem;
    width: 100% !important;
  }
  .content-1-section {
    position: static;
    border-radius: 0px;
  }
  .homepage-banner-slider .owl-theme .owl-dots {
    bottom: 0;
  }
  .best-products .column .tyers-grid li {
    display: block;
    text-align: center;
  }
}
@media (max-width: 640px) {
  .checkout-btn {
    min-width: fit-content;
    padding-left: 0px !important;
    height: 30px !important;
    position: relative;
    top: 0px;
    width: 150px !important;
    margin-top: 16%;
  }
  .collection-wrapper .col-grid-box {
    padding: 0px 10px 0px 10px;
  }
  footer .footer-links {
    display: grid !important;
  }
  footer .footer-col {
    justify-content: center !important;
    padding: 5px;
    margin: 5px;
    min-width: 136%;
  }
  .banner-content .content h2, .banner-content .content h1 {
    font-size: 18px;
  }
  .header .flex-container .header-row-one .search-bar {
    margin-left: 0px;
  }
  .header .flex-container .header-row-one .account-section {
    position: fixed !important;
    z-index: 2 !important;
    padding-right: 0px;
  }
  .header .flex-container .header-row-one .account-section .account {
    margin-right: 10px !important;
  }
  .header .flex-container .header-row-one .account-section .dropdown-toggle {
    font-size: 10px !important;
  }
  .header .flex-container .header-row-one .account-section .fa-user {
    font-size: 10px !important;
  }
  .product-box-inner {
    display: block;
    padding: 10px 0px 10px 0;
  }
  .product-box-inner .img-wrapper {
    padding-bottom: 10px;
  }
  .product-box-inner .img-wrapper .product-image {
    max-width: 100%;
    width: 100%;
  }
  .product-box-inner .product-detail {
    padding-left: 0px;
  }
  .product-box-inner .verified {
    top: 10px;
    transform: none;
  }
  /*Pagenation**/
  .product-pagination .row {
    display: block;
  }
  .product-pagination .row > div {
    margin-bottom: 10px;
    text-align: center;
  }
  .product-pagination .row > div p {
    margin-bottom: 0px;
  }
  .product-pagination .row > div .pagination {
    justify-content: center;
  }
  .mobile-filter {
    display: block !important;
  }
  .desktop-filter {
    display: none !important;
  }
  .mob-categories {
    opacity: 1;
    padding: 10px;
    font-family: "proxima-regular", "sans-serif" !important;
    position: fixed;
    background-color: white;
    z-index: 100;
    width: 100%;
    height: 100%;
    border-right: 50px solid rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    top: 0;
    left: 0;
    background-clip: padding-box;
  }
  .touch-out {
    background-color: transparent;
    position: fixed;
    z-index: 999;
    width: 60px;
    right: 0px;
    height: 300%;
    top: 0px;
    overflow: hidden;
  }
}
/*** Range **/
@media (max-width: 991px) and (min-width: 640px) {
  .product-name {
    font-size: 18px;
  }
  .price {
    font-size: 18px;
  }
  .product-price .tax-includes {
    font-size: 13px;
  }
  .verified {
    font-size: 13px;
  }
  .add-to .btn-solid {
    font-size: 15px;
  }
}
@media (max-width: 1920px) {
  .shipping1 {
    display: flex !important;
  }
  .shipping2 {
    display: none !important;
  }
  .video1 {
    display: block !important;
  }
  .video2 {
    display: none !important;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .shipping1 {
    display: none !important;
  }
  .shipping2 {
    display: flex !important;
  }
  .video1 {
    display: none !important;
  }
  .video2 {
    display: block !important;
  }
}
::-ms-input-placeholder {
  color: #808080 !important;
  opacity: 0.5 !important;
}

body {
  background-color: #F8F9FA;
}

a,
a:hover {
  color: #4b4b4b;
  text-decoration: none;
}

ul {
  padding-left: 0px;
  margin: 0;
}

li {
  list-style: none;
}

h4.sm-bold {
  font-size: 14px !important;
}

.pb-50 {
  padding-bottom: 50px;
}

ul.two-column {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
ul.two-column li {
  font-size: 14px;
}
ul.two-column li .list-label {
  color: #9B9898;
  margin-right: 15px;
  min-width: 105px;
}
ul.two-column li .list-value {
  color: #E31E25;
}
ul.three-column {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}
ul.three-column li {
  font-size: 14px;
}
ul.three-column li .list-label {
  color: #9B9898;
  min-width: 105px;
}
ul.three-column li .list-value {
  color: #E31E25;
}

/** Title **/
.content-title {
  text-align: center;
  padding: 60px 0px 30px 0px;
}
.content-title .title-box .title {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  font-size: 1.8rem !important;
  color: #FBCB0C;
}
.content-title .title-box .sub-title {
  color: #a7a7a7;
  font-size: 1.3rem !important;
}

.best_gifts {
  background-color: white;
  height: 12vw;
  padding: 2vw;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.gifts_img {
  width: 20vw;
  height: 10vw;
  padding: 1vw;
  margin-left: 2vw;
  margin-right: 77px;
  margin-top: 1px;
}

.row-fluid {
  display: flex;
  flex-flow: wrap;
}

.sub-heading {
  text-transform: uppercase;
  font-size: 17px;
  margin-bottom: 20px;
  cursor: pointer;
}

/**Product box one **/
.product-box-one .product-detail {
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 2px solid #eceaea;
}
.product-box-one .product-detail .product-name {
  padding-right: 30px;
}
.product-box-one .product-detail .product-option .wishlist .add-to-wishlist {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 22px;
}

/**product**/
.product-name {
  font-size: 18px;
}

h6.product-name {
  font-size: 19px;
}

/***Listing**/
.collection-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
.collection-wrapper .collection-content {
  background-color: #fff;
}
.collection-wrapper .collection-top {
  padding-top: 15px;
  padding-bottom: 15px;
}
.collection-wrapper .collection-filter {
  background-color: #fff;
}
.collection-wrapper .collection-filter .filter-top {
  border-bottom: 1px solid #dddddd;
}
.collection-wrapper .collection-filter .filter-top .filter-clear {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.collection-wrapper .collection-filter .layerd-navigation {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block {
  margin-bottom: 10px;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content {
  padding-left: 30px;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options {
  margin: 8px 0px 8px 0px;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options input {
  margin-right: 10px;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options label {
  margin-bottom: 0px;
  font-size: 15px;
}
.collection-wrapper .collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options label .count {
  color: #afabab;
  font-size: 14px;
}
.collection-wrapper .col-grid-box {
  width: 100%;
  background: #fff;
  padding: 0px 10px 0px 10px;
}
.collection-wrapper .sortby .input-group-text {
  background-color: #fff;
  border-radius: 0;
  border-right: 0;
}
.collection-wrapper .sortby .sortby-options {
  border-left: 0px;
  border-radius: 0;
}

/** Product Details**/
.product-details-wrapper .details-top {
  padding: 15px 0px 15px 0px;
}
.product-details-wrapper .slider-nav {
  max-width: 400px;
  min-width: 300px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.product-details-wrapper .slider-nav .owl-thumb {
  background-color: #000;
  cursor: pointer;
}
.product-details-wrapper .slider-nav .owl-thumb img {
  opacity: 0.5;
}
.product-details-wrapper .slider-nav .owl-thumb:hover {
  background-color: transparent;
  border: 2px solid #f7f1f1;
}
.product-details-wrapper .slider-nav .owl-thumb:hover img {
  opacity: 1;
}
.product-details-wrapper .slider-nav .owl-thumb.active {
  border: 2px solid #f7f1f1;
  background-color: transparent;
}
.product-details-wrapper .slider-nav .owl-thumb.active img {
  opacity: 1;
}
.product-details-wrapper .product-all-details .product-name {
  font-size: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details {
  background-color: #fff;
  padding: 0px 15px 15px 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .price {
  font-size: 24px;
}
.product-details-wrapper .details-wrapper .product-all-details .row {
  padding: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-left {
  flex: 1;
  padding-right: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right {
  min-width: 250px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .add-to-cart {
  margin-bottom: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option {
  margin-top: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option h6 .fa {
  font-size: 22px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option .input-group input {
  border-right: 0px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option .input-group .input-group-text {
  background-color: #fff;
  border-left: 0;
  color: #E31E25;
}
.product-details-wrapper .details-wrapper .product-all-details .description {
  padding-bottom: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .product-addtional-details {
  position: relative;
  padding-top: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .product-addtional-details::before {
  content: "";
  position: absolute;
  width: calc(100% - 198px);
  height: 2px;
  background: #707070;
  opacity: 0.2;
  box-shadow: 0;
  top: -10px;
}

.ng5-slider .ng5-slider-pointer {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: #fff !important;
  z-index: 3;
  border-radius: 16px !important;
  box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
  -webkit-box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: 2px 3px 9px 2px rgba(0, 0, 0, 0.56);
}
.ng5-slider .ng5-slider-span:focus {
  outline: 0;
}
.ng5-slider .ng5-slider-pointer:after {
  display: none !important;
}
.ng5-slider .ng5-slider-selection {
  z-index: 2;
  background: #E31E25 !important;
  border-radius: 2px;
}

/** Pages **/
.homepage-banner-slider .owl-theme .owl-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
}
.homepage-banner-slider .owl-theme .owl-next,
.homepage-banner-slider .owl-theme .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #F8F9FA !important;
  border: 0px !important;
}
.homepage-banner-slider .owl-theme .owl-next .fa,
.homepage-banner-slider .owl-theme .owl-prev .fa {
  font-size: 30px;
}
.homepage-banner-slider .owl-theme .owl-next {
  right: 0;
  background: #F8F9FA;
  width: 20px;
}
.homepage-banner-slider .owl-theme .owl-prev {
  left: 0;
  background: #F8F9FA;
}

.rounder-corner-nav.top-nav-right .owl-theme .owl-nav {
  position: absolute;
  top: -70px;
  right: 0;
}
.rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav {
  margin-top: 0px;
}
.rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-prev,
.rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-next {
  background: #fff;
  border: 0px;
}
.rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-prev .fa,
.rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-next .fa {
  color: #000;
}
.rounder-corner-nav .owl-theme .owl-nav {
  margin-top: 0px;
}
.rounder-corner-nav .owl-theme .owl-nav .owl-prev,
.rounder-corner-nav .owl-theme .owl-nav .owl-next {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 2px solid #b7b6b5;
}
.rounder-corner-nav .owl-theme .owl-nav .owl-prev .fa,
.rounder-corner-nav .owl-theme .owl-nav .owl-next .fa {
  color: #b7b6b5;
  font-size: 26px;
}

.rotate-dot .owl-theme .owl-dots {
  transform: translateX(-50%);
}
.rotate-dot .owl-theme .owl-dots [class*=owl-] {
  width: 25px;
  height: 25px;
  transform: rotate(130deg);
  margin: 0px 6px;
  border: 1px solid transparent;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-].active {
  border: 1px solid #E31E25;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-].active span {
  background-color: #E31E25;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-] span {
  width: 12px;
  height: 12px;
  border-radius: 0;
  margin: 5px 5px;
}

.banner_bestgifts {
  position: relative;
  margin-top: 40px;
}

.featured-collections .tab-image {
  min-height: 300px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.featured-collections .tab-image .background-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  min-height: 300px;
}
.featured-collections .tab-image .background-image:hover {
  transform: scale(1.2);
}
.featured-collections .tab-image .bg-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 25px;
}
.featured-collections .tab-image .bg-content p {
  font-size: 15px;
}

.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
}
.best-products .title {
  text-align: center;
  padding-bottom: 20px;
}
.best-products .column {
  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* Standard syntax */
}
.best-products .column.grid-image {
  -webkit-flex: 2; /* Safari */
  -ms-flex: 2; /* IE 10 */
  flex: 2; /* Standard syntax */
}
.best-products .column.single-image {
  border: 1px solid #c5c5c5;
  margin-bottom: 20px;
  text-align: center;
}
.best-products .column.single-image .trers-single-image img {
  min-width: 300px;
}
.best-products .column.single-image h4 {
  font-size: 20px;
  text-transform: uppercase;
}
.best-products .column .tyers-grid {
  display: flex;
  flex-flow: wrap;
  margin: 0;
}
.best-products .column .tyers-grid li {
  width: calc(50% - 20px);
  border: 1px solid #c5c5c5;
  margin: 0px 20px 20px 0px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.best-products .column .tyers-grid li .trers-grid-image img {
  padding: 10px;
}
.best-products .column .tyers-grid li a {
  cursor: pointer;
}
.best-products .column .tyers-grid li:hover {
  border-color: var(--theme-deafult);
}
.best-products .column .tyers-grid li h4 {
  font-size: 20px;
  text-transform: uppercase;
}

.mobile-cart {
  display: none !important;
}

.mobile-only {
  display: none !important;
}

.home-featured-collections-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .featured-collections .tab-image {
    height: 100px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .featured-collections .tab-image .background-image {
    position: relative !important;
    min-height: 100px;
    width: 120%;
    margin-left: -10%;
    background-repeat: no-repeat;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  .featured-collections .tab-image .background-image:hover {
    transform: scale(1.2);
  }
  .featured-collections .tab-image .bg-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 25px;
  }
  .featured-collections .tab-image .bg-content p {
    font-size: 15px;
  }
  .rounder-corner-nav.top-nav-right .owl-theme .owl-nav {
    position: absolute;
    top: -70px;
    right: 0;
  }
  .rounder-corner-nav.top-nav-bg-white .owl-theme {
    background-color: transparent !important;
  }
  .rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav {
    margin-top: 0px;
    background-color: transparent !important;
  }
  .rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-prev,
  .rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-next {
    background: transparent !important;
    border: 0px;
  }
  .rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-prev .fa,
  .rounder-corner-nav.top-nav-bg-white .owl-theme .owl-nav .owl-next .fa {
    color: #000;
  }
  .rounder-corner-nav .owl-theme .owl-nav {
    margin-top: 0px;
    z-index: -100;
    position: absolute;
    top: 45%;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    top: 45%;
  }
  .rounder-corner-nav .owl-theme .owl-nav .owl-next {
    position: relative;
    margin-left: 85%;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none !important;
    background-color: transparent !important;
  }
  .rounder-corner-nav .owl-theme .owl-nav .owl-next .fa {
    color: black;
    font-size: 40px;
  }
  .rounder-corner-nav .owl-theme .owl-nav .owl-prev {
    position: relative;
    margin-left: -3%;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none !important;
    background-color: transparent !important;
  }
  .rounder-corner-nav .owl-theme .owl-nav .owl-prev .fa {
    color: black;
    font-size: 40px;
  }
  .mobile-cart {
    display: flex !important;
    float: right;
  }
  .desktop-carousel {
    display: none !important;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
  .mobile-options {
    position: absolute !important;
    margin-top: -62px !important;
    margin: 0;
    margin-left: 160px;
    left: 0;
    border: none !important;
  }
  .mob-eye {
    margin-left: 40px;
  }
  .mob-second {
    margin-top: -31px;
  }
  .forgot-link {
    margin-top: -26px !important;
  }
  .forgot-link-checkout {
    margin-top: -45px !important;
  }
  .back-btn {
    width: 35% !important;
    margin-left: 65%;
  }
  .mob-logo {
    transform: scale(0.75);
    top: 8px !important;
    left: 25%;
    position: fixed;
    z-index: 3;
  }
  .home-featured-collections-title {
    font-size: 1rem;
  }
}
@media (max-width: 1920px) {
  .bestgifts-arrival {
    justify-content: center;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .bestgifts-arrival {
    justify-content: left;
  }
}
@media (max-width: 1920px) {
  .bestgifts-banner {
    height: 45vh;
    border: 0px solid white;
    width: 100%;
    background-size: cover;
    color: white;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .bestgifts-banner {
    height: 85px;
    border: 0px solid white;
    width: 100% !important;
    background-size: cover;
    color: white;
  }
  .gifts_img {
    width: 15vw;
    height: 12vw;
    padding: 1vw;
    margin: -9px 73px 6px 96px;
  }
  .new_log {
    margin-left: 0px !important;
  }
  .banner_bestgifts {
    position: relative !important;
    margin-top: 13px !important;
  }
  .name-form {
    margin-top: 7px !important;
  }
  .special-checkout {
    display: flex;
    justify-content: center;
  }
}
.sort {
  margin-left: 80%;
  font-size: 13px;
}

.listProduct1 {
  cursor: pointer;
  margin: 15px 20px !important;
}

@media screen and (max-width: 600px) {
  .listProduct1 {
    cursor: pointer;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: -8px !important;
  }
  .best-pagenation {
    padding-top: 38px;
  }
  .sort {
    margin-left: 50%;
    position: relative;
    top: -54px;
    font-size: 12px;
  }
}
.header_bestgift {
  position: relative;
  margin-top: 40px;
  z-index: 5;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.content-title {
  text-align: center !important;
  padding: 0px 0px 30px 0px !important;
}

.img_height {
  position: relative;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .img_height {
    position: relative;
    margin-top: 20px;
  }
  .profile_name {
    position: relative;
    margin-top: 3%;
  }
}
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: red;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3c57a6;
  --secondary: #ed98d2;
  --success: rgb(34, 34, 34);
  --info: #3c57a6;
  --warning: #ebaa05;
  --danger: #ef1d3c;
  --light: #f5f5f5;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3c57a6;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #283a6e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c8d0e6;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-primary:hover {
  background-color: #b6c1de;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b6c1de;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fae2f2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f6c9e8;
}

.table-hover .table-secondary:hover {
  background-color: #f6cce8;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f6cce8;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1c1c1;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8c8c8c;
}

.table-hover .table-success:hover {
  background-color: #b4b4b4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4b4b4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8d0e6;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-info:hover {
  background-color: #b6c1de;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b6c1de;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e7b9;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5d37d;
}

.table-hover .table-warning:hover {
  background-color: #f7dfa2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7dfa2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc0c8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7899a;
}

.table-hover .table-danger:hover {
  background-color: #f9a8b3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9a8b3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgb(34, 34, 34);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(34, 34, 34, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: rgb(34, 34, 34);
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3c3c3c;
  background-color: #3c3c3c;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef1d3c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(239, 29, 60, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef1d3c;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef1d3c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef1d3c;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f24d65;
  background-color: #f24d65;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef1d3c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef1d3c;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e56dbf;
  border-color: #e462ba;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:hover {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #090909;
  border-color: #020202;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #b98604;
  border-color: #ad7d04;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #cb0e2a;
  border-color: #bf0e28;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: gainsboro;
  border-color: #d5d5d5;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-secondary {
  color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ed98d2;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.btn-outline-success {
  color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: rgb(34, 34, 34);
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-info {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-warning {
  color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ebaa05;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.btn-outline-danger {
  color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef1d3c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3c57a6;
  text-decoration: none;
}
.btn-link:hover {
  color: #283a6e;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #3c57a6;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8c9fd5;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #b2bee3;
  border-color: #b2bee3;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8c9fd5;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #3c57a6;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3c57a6;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #283a6e;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #ed98d2;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e56dbf;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #090909;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ebaa05;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #b98604;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #ef1d3c;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #cb0e2a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f5f5f5;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: gainsboro;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-primary hr {
  border-top-color: #b6c1de;
}
.alert-primary .alert-link {
  color: #111931;
}

.alert-secondary {
  color: #7b4f6d;
  background-color: #fbeaf6;
  border-color: #fae2f2;
}
.alert-secondary hr {
  border-top-color: #f6cce8;
}
.alert-secondary .alert-link {
  color: #5c3b51;
}

.alert-success {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1;
}
.alert-success hr {
  border-top-color: #b4b4b4;
}
.alert-success .alert-link {
  color: black;
}

.alert-info {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-info hr {
  border-top-color: #b6c1de;
}
.alert-info .alert-link {
  color: #111931;
}

.alert-warning {
  color: #7a5803;
  background-color: #fbeecd;
  border-color: #f9e7b9;
}
.alert-warning hr {
  border-top-color: #f7dfa2;
}
.alert-warning .alert-link {
  color: #483402;
}

.alert-danger {
  color: #7c0f1f;
  background-color: #fcd2d8;
  border-color: #fbc0c8;
}
.alert-danger hr {
  border-top-color: #f9a8b3;
}
.alert-danger .alert-link {
  color: #4f0914;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #3c57a6;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-secondary {
  color: #7b4f6d;
  background-color: #fae2f2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7b4f6d;
  background-color: #f6cce8;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7b4f6d;
  border-color: #7b4f6d;
}

.list-group-item-success {
  color: #121212;
  background-color: #c1c1c1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #121212;
  background-color: #b4b4b4;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #121212;
  border-color: #121212;
}

.list-group-item-info {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-warning {
  color: #7a5803;
  background-color: #f9e7b9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a5803;
  background-color: #f7dfa2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a5803;
  border-color: #7a5803;
}

.list-group-item-danger {
  color: #7c0f1f;
  background-color: #fbc0c8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c0f1f;
  background-color: #f9a8b3;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7c0f1f;
  border-color: #7c0f1f;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3c57a6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e4381 !important;
}

.bg-secondary {
  background-color: #ed98d2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e56dbf !important;
}

.bg-success {
  background-color: rgb(34, 34, 34) !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #090909 !important;
}

.bg-info {
  background-color: #3c57a6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2e4381 !important;
}

.bg-warning {
  background-color: #ebaa05 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b98604 !important;
}

.bg-danger {
  background-color: #ef1d3c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cb0e2a !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3c57a6 !important;
}

.border-secondary {
  border-color: #ed98d2 !important;
}

.border-success {
  border-color: rgb(34, 34, 34) !important;
}

.border-info {
  border-color: #3c57a6 !important;
}

.border-warning {
  border-color: #ebaa05 !important;
}

.border-danger {
  border-color: #ef1d3c !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #3c57a6 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #283a6e !important;
}

.text-secondary {
  color: #ed98d2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #e257b6 !important;
}

.text-success {
  color: rgb(34, 34, 34) !important;
}

a.text-success:hover, a.text-success:focus {
  color: black !important;
}

.text-info {
  color: #3c57a6 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #283a6e !important;
}

.text-warning {
  color: #ebaa05 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #a07403 !important;
}

.text-danger {
  color: #ef1d3c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30d25 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfcfcf !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: red;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3c57a6;
  --secondary: #ed98d2;
  --success: rgb(34, 34, 34);
  --info: #3c57a6;
  --warning: #ebaa05;
  --danger: #ef1d3c;
  --light: #f5f5f5;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3c57a6;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #283a6e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c8d0e6;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-primary:hover {
  background-color: #b6c1de;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b6c1de;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fae2f2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f6c9e8;
}

.table-hover .table-secondary:hover {
  background-color: #f6cce8;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f6cce8;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1c1c1;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8c8c8c;
}

.table-hover .table-success:hover {
  background-color: #b4b4b4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4b4b4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8d0e6;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-info:hover {
  background-color: #b6c1de;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b6c1de;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e7b9;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5d37d;
}

.table-hover .table-warning:hover {
  background-color: #f7dfa2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7dfa2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc0c8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7899a;
}

.table-hover .table-danger:hover {
  background-color: #f9a8b3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9a8b3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgb(34, 34, 34);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(34, 34, 34, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: rgb(34, 34, 34);
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3c3c3c;
  background-color: #3c3c3c;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef1d3c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(239, 29, 60, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef1d3c;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef1d3c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef1d3c;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f24d65;
  background-color: #f24d65;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef1d3c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef1d3c;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e56dbf;
  border-color: #e462ba;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:hover {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #090909;
  border-color: #020202;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #b98604;
  border-color: #ad7d04;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #cb0e2a;
  border-color: #bf0e28;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: gainsboro;
  border-color: #d5d5d5;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-secondary {
  color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ed98d2;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.btn-outline-success {
  color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: rgb(34, 34, 34);
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-info {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-warning {
  color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ebaa05;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.btn-outline-danger {
  color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef1d3c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3c57a6;
  text-decoration: none;
}
.btn-link:hover {
  color: #283a6e;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #3c57a6;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8c9fd5;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #b2bee3;
  border-color: #b2bee3;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8c9fd5;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #3c57a6;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3c57a6;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #283a6e;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #ed98d2;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e56dbf;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #090909;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ebaa05;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #b98604;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #ef1d3c;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #cb0e2a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f5f5f5;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: gainsboro;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-primary hr {
  border-top-color: #b6c1de;
}
.alert-primary .alert-link {
  color: #111931;
}

.alert-secondary {
  color: #7b4f6d;
  background-color: #fbeaf6;
  border-color: #fae2f2;
}
.alert-secondary hr {
  border-top-color: #f6cce8;
}
.alert-secondary .alert-link {
  color: #5c3b51;
}

.alert-success {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1;
}
.alert-success hr {
  border-top-color: #b4b4b4;
}
.alert-success .alert-link {
  color: black;
}

.alert-info {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-info hr {
  border-top-color: #b6c1de;
}
.alert-info .alert-link {
  color: #111931;
}

.alert-warning {
  color: #7a5803;
  background-color: #fbeecd;
  border-color: #f9e7b9;
}
.alert-warning hr {
  border-top-color: #f7dfa2;
}
.alert-warning .alert-link {
  color: #483402;
}

.alert-danger {
  color: #7c0f1f;
  background-color: #fcd2d8;
  border-color: #fbc0c8;
}
.alert-danger hr {
  border-top-color: #f9a8b3;
}
.alert-danger .alert-link {
  color: #4f0914;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #3c57a6;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-secondary {
  color: #7b4f6d;
  background-color: #fae2f2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7b4f6d;
  background-color: #f6cce8;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7b4f6d;
  border-color: #7b4f6d;
}

.list-group-item-success {
  color: #121212;
  background-color: #c1c1c1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #121212;
  background-color: #b4b4b4;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #121212;
  border-color: #121212;
}

.list-group-item-info {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-warning {
  color: #7a5803;
  background-color: #f9e7b9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a5803;
  background-color: #f7dfa2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a5803;
  border-color: #7a5803;
}

.list-group-item-danger {
  color: #7c0f1f;
  background-color: #fbc0c8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c0f1f;
  background-color: #f9a8b3;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7c0f1f;
  border-color: #7c0f1f;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3c57a6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e4381 !important;
}

.bg-secondary {
  background-color: #ed98d2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e56dbf !important;
}

.bg-success {
  background-color: rgb(34, 34, 34) !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #090909 !important;
}

.bg-info {
  background-color: #3c57a6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2e4381 !important;
}

.bg-warning {
  background-color: #ebaa05 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b98604 !important;
}

.bg-danger {
  background-color: #ef1d3c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cb0e2a !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3c57a6 !important;
}

.border-secondary {
  border-color: #ed98d2 !important;
}

.border-success {
  border-color: rgb(34, 34, 34) !important;
}

.border-info {
  border-color: #3c57a6 !important;
}

.border-warning {
  border-color: #ebaa05 !important;
}

.border-danger {
  border-color: #ef1d3c !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #3c57a6 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #283a6e !important;
}

.text-secondary {
  color: #ed98d2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #e257b6 !important;
}

.text-success {
  color: rgb(34, 34, 34) !important;
}

a.text-success:hover, a.text-success:focus {
  color: black !important;
}

.text-info {
  color: #3c57a6 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #283a6e !important;
}

.text-warning {
  color: #ebaa05 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #a07403 !important;
}

.text-danger {
  color: #ef1d3c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30d25 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfcfcf !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.btn-primary {
  background: #3c57a6;
  border-color: #3c57a6;
  border-radius: 20px !important;
}

.btn-secondary {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  border-radius: 20px !important;
  color: rgb(192, 6, 6) !important;
}

.custom-pointer {
  cursor: pointer;
}

.none-style {
  text-decoration: none !important;
}

dp-date-picker.dp-material .dp-picker-input {
  font-size: 1rem !important;
  outline: none !important;
  border: none !important;
}

.contact-active.active {
  color: #be2222 !important;
}

.contact-active:hover {
  color: #3c57a6 !important;
}

.outer-banner {
  display: grid;
  grid-template: 1fr/1fr;
  place-items: center;
}

.outer-banner > * {
  grid-column: 1/1;
  grid-row: 1/1;
}

.outer-banner .below-banner {
  z-index: 1;
  background-color: #f9f9f9;
  width: 100%;
}

.outer-banner .top-banner {
  z-index: 2;
  width: 100%;
}

.banner-img2 {
  width: 90%;
  height: 400px;
  margin-left: -45%;
  border: 1px solid #ccc;
}

.carousel-indicators > li {
  border-radius: 50% !important;
  height: 15px !important;
  width: 15px !important;
  margin: 10px !important;
}

table {
  width: 100%;
}

.table td,
.table th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: none;
}

.inq-table-st {
  border-collapse: collapse;
  background: #ffffff;
  margin-bottom: 0 !important;
}

.inq-table-layout-st {
  overflow: auto;
  margin-bottom: 4px;
  transition: all 0.3s ease-in-out;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: transparent !important;
}

li {
  list-style: none;
}

.inner-html-list li {
  list-style: circle;
}

p {
  margin-bottom: 0rem !important;
}

a {
  color: #3c57a6 !important;
}

.inq-primary {
  color: #3c57a6;
}

.inq-secondary {
  color: #3c57a6 !important;
  font-weight: 500;
}

.inq-bg-primary {
  background: #3c57a6;
}

.contact-section {
  max-width: 230px !important;
}

.custom-point {
  cursor: pointer;
  font-size: 14px !important;
}

.custom-point1 {
  cursor: pointer;
  font-size: 14px !important;
  color: #375673 !important;
  font-weight: 450;
}

h3, h4 {
  text-transform: none !important;
}

@media (max-width: 767.98px) {
  h3 {
    font-size: 1rem;
  }
}

.shadow {
  -moz-box-shadow: inset 0 0 10px #9e9797;
  -webkit-box-shadow: inset 0 0 10px #9e9797;
  box-shadow: inset 0 0 10px #9e9797;
}
@media (max-width: 767.98px) {
  .shadow {
    font-size: 0.7rem;
  }
}

.visit-store {
  background: #3c57a6;
  padding: 25px 10px 25px 10px;
}
.visit-store ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visit-store ul li {
  margin-right: 20px;
}
.visit-store ul li .btn {
  border: 1px solid #cccccc;
}
.visit-store ul li h3 {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

footer {
  background-color: #fff;
}
footer.container-fluid.footer, footer.footer.container-sm, footer.footer.container-md, footer.footer.container-lg, footer.footer.container-xl {
  border-bottom: 1px solid #ddd;
}
footer .footer-links {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}
footer .footer-links .footer-col {
  max-width: 200px;
}
footer .footer-links .footer-col.address i {
  color: #3c57a6;
  margin-right: 5px;
}
footer .footer-links .footer-col h4 {
  text-transform: uppercase;
  font-size: 16px;
}
footer .footer-links .footer-col p, footer .footer-links .footer-col a {
  font-size: 14px;
}
footer .footer-links .footer-col ul li {
  margin-bottom: 3px;
}
footer .footer-row-two {
  padding: 30px 15px 10px 10px;
}
footer .footer-row-two ul {
  display: flex;
  justify-content: center;
}
footer .footer-row-two ul li {
  margin: 5px 10px 5px 10px;
}
footer .footer-row-two ul li i {
  color: #3c57a6;
  font-size: 22px;
}
footer .footer-row-two1 {
  padding: 0px 178px 15px 0px;
}
footer .footer-row-two1 ul {
  display: flex;
  justify-content: center;
}
footer .footer-row-two1 ul li {
  margin: 5px 10px 5px 10px;
}
footer .footer-row-two1 ul li i {
  color: #ffffff;
  font-size: 22px;
}
footer .copy {
  text-align: center;
  font-size: 14px;
}

/*** Nav **/
.navbar-row {
  box-shadow: 0 3px 4px -5px #333;
}

@media screen and (max-width: 600px) {
  .visit-store ul li h3 {
    width: 120px;
  }
}
.wrapper > .wrapper {
  margin-left: 0px;
}

/*=====================
    27.Menu css start
==========================*/
header .main-navbar {
  text-align: center;
}
header .main-navbar .nav-menu {
  display: inline-block;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  position: relative;
}
header .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .main-navbar .nav-menu > li > a {
  display: block;
  padding-right: 24px;
  color: black;
  font-size: 13px !important;
  font-weight: 550 !important;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  position: relative;
}
header .main-navbar .nav-menu > li > a .sub-arrow {
  /* position: absolute;
   top: 50%;
   margin-top: -17px;
   left: auto;
   right: 15px;
   width: 34px;
   height: 34px;
   overflow: hidden;
   font: bold 16px/34px monospace !important;
   text-align: center;
   text-shadow: none;
   border-radius: 4px;*/
  margin-left: 5px;
}
header .main-navbar .nav-menu > li > a .sub-arrow:before {
  content: "\f107";
  font-family: "FontAwesome";
  position: absolute;
}
header .main-navbar .nav-menu > li > a .lable-nav {
  position: absolute;
  background-color: #3c57a6;
  padding: 2px 7px;
  color: #fff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3;
}
header .main-navbar .nav-menu > li > a .lable-nav:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #3c57a6;
  top: 15px;
  left: 5px;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red {
  background-color: #3c57a6;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red:before {
  border-top: 5px solid #3c57a6;
}
header .main-navbar .nav-menu > li .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 15px 0px 15px 0px;
}
header .main-navbar .nav-menu > li .nav-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .nav-submenu li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 13px !important;
  font-weight: 450;
  text-transform: capitalize;
  padding: 5px 35px;
  display: block;
  line-height: 1.9;
}
header .main-navbar .nav-menu > li .nav-submenu li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: #3c57a6;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
  content: "\f105";
  position: absolute;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .main-navbar .nav-menu > li .mega-menu-container {
  position: absolute;
  left: 0;
  z-index: 4;
  padding: 30px;
  width: 100%;
}
header .main-navbar .nav-menu > li .mega-menu-container .wrapper {
  margin-left: 0px !important;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
  width: 20%;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
  text-transform: capitalize;
  font-size: 15px;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a {
  text-transform: capitalize;
  font-size: 15px;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  border: 0 !important;
  background: transparent;
  color: #1a1919;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: 400;
  text-transform: capitalize;
  padding: 3px 0;
  display: block;
  line-height: 1.9;
  position: relative;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: #3c57a6;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li:hover > a {
  color: #3c57a6;
}
header .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}
header.header-gym .main-navbar .nav-menu > li > a {
  color: white;
  padding-top: 22px;
  padding-bottom: 22px;
}
header.header-tools .main-navbar .nav-menu > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}
header.header-tools .main-navbar .nav-menu > li > a .lable-nav {
  top: 0;
}
header .sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #fff;
  transition: all 0.5s ease;
}
header .sidenav .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
  cursor: pointer;
}
header .sidenav nav {
  background-color: #fff;
  z-index: 9;
  position: relative;
  height: 100vh;
  top: 0;
}
header .sidenav.openSide {
  left: 0;
  transition: all 0.5s ease;
}
header .sidenav.openSide .sidebar-overlay {
  opacity: 0.8;
  visibility: visible;
}
header .sidenav .sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
header .sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: 50px;
  margin-top: 50px;
  color: gray;
}
header .sidenav .sidebar-menu {
  background-color: #ffffff;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
}
header .sidenav .sidebar-menu li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  float: none;
}
header .sidenav .sidebar-menu li a {
  padding: 10px 50px 20px 40px;
  position: relative;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}
header .sidenav .sidebar-menu li ul {
  position: absolute;
  width: 200px;
  left: 100%;
  top: 0;
  padding: 15px 0px;
  display: none;
  background-color: #fff;
}
header .sidenav .sidebar-menu li ul li a {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li ul li a:active, header .sidenav .sidebar-menu li ul li a:focus, header .sidenav .sidebar-menu li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li .mega-menu {
  position: absolute;
  left: 100%;
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .sidenav .sidebar-menu li .mega-menu li ul {
  position: relative;
  width: 100%;
  left: inherit;
}
header .sidenav .pixelstrap li a {
  padding: 10px 50px 20px 40px;
}
header .sidenav .pixelstrap li ul li a {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li .clothing-menu li a {
  padding: 5px 0 !important;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus {
  font-size: 14px;
}
header .sidenav .pixelstrap li .clothing-menu li a:hover {
  font-size: 14px;
  padding: 5px 0;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
  padding: 5px 0 !important;
}
header .layout3-menu .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}
header .layout3-menu .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .layout3-menu .main-navbar .nav-menu > li > a {
  text-transform: capitalize;
  padding-right: 34px;
}
header .layout3-menu .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}

.toggle-nav {
  position: fixed !important;
  cursor: pointer;
  left: 20px !important;
  top: 20px !important;
  z-index: 99;
}
.toggle-nav i {
  font-size: 24px;
  color: #3c57a6;
}

.mega-menu-banner:hover:before {
  display: none;
}

.new-tag {
  padding-left: 3px;
  color: #3c57a6;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.up-text {
  position: relative;
}
.up-text span {
  position: absolute;
  top: -5px;
  right: -10px;
  color: #3c57a6;
  font-size: 12px;
  font-weight: 700;
}

.mega-box .link-section .demo ul li a {
  position: relative;
}
.mega-box .link-section .demo ul li a:hover, .mega-box .link-section .demo ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}
.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: #3c57a6;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}
.mega-box .link-section .demo .title {
  border-bottom: 2px solid #3c57a6 !important;
}

.rtl .link-section {
  float: right;
}
.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 1200px) {
  .responsive-btn {
    display: none;
  }
  header .main-navbar .nav-menu {
    display: flex;
    justify-content: center;
  }
  header .main-navbar .nav-menu > li.nosub:hover .nav-submenu {
    display: none;
  }
  header .main-navbar .nav-menu > li a {
    padding: 15px 17px 15px 17px;
  }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 200px;
    display: none;
    box-shadow: 0 0px 3px -1px #555;
    background: #fff;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a {
    color: #1a1919;
    font-size: 13px !important;
    font-weight: 450 !important;
    text-transform: capitalize;
    display: block;
    line-height: 1.2;
    position: relative;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:checked .nav-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:checked .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
    background: #111;
    box-shadow: 0 0px 3px -1px #555;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .wrapper {
    margin-left: 0px !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .menu-title h5:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .menu-title a:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li:hover .nav-submenu {
    display: block;
  }
  header .main-navbar .nav-menu > li:hover .mega-menu-container {
    display: block;
  }
  header .main-navbar .nav-menu > li:hover .mega-menu-container .wrapper {
    margin-left: 0px !important;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: auto;
    right: 7px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "\f105";
    font-family: "FontAwesome";
    position: absolute;
  }
  header .sidenav .sidebar-menu li > ul {
    display: none;
  }
  header .sidenav .sidebar-menu li > ul li a {
    padding: 5px 35px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li > ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: #3c57a6;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a .sub-arrow {
    margin-top: -17px;
  }
  header .sidenav .sidebar-menu li:hover > ul {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    position: absolute;
    left: 100%;
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 #ebebeb;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section h5 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 15px;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul {
    position: relative;
    width: 100%;
    left: inherit;
    display: block;
    padding: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: #3c57a6;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .mega-menu-banner {
    padding: 0;
  }
  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px;
  }
  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus {
    font-size: 14px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:hover {
    font-size: 14px;
    padding: 5px 0;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
    padding: 5px 0 !important;
  }
  .toggle-nav {
    display: none;
  }
  .mobile-back {
    display: none !important;
  }
  .main-nav-center > nav {
    text-align: center;
  }
  .main-nav-center .sm {
    display: inline-block;
    vertical-align: middle;
  }
  .main-nav-center .sm > li .lable-nav {
    top: 3px;
  }
  .main-nav-center .sm > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .layout3-menu .pixelstrap a {
    text-transform: capitalize;
    padding-right: 34px;
  }
  .layout3-menu .pixelstrap .sub-arrow {
    right: 12px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    right: unset;
    left: -200px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
    right: unset;
    left: -200px;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .wrapper {
    margin-left: 0px !important;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
    right: 0;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover::before {
    right: 0;
  }
  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }
  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }
  .rtl .layout3-menu .light-font-menu li > a, .rtl .layout3-menu .light-font-menu li > a:hover, .rtl .layout3-menu .light-font-menu li > a:active {
    padding-right: 0;
    padding-left: 28px;
  }
  .rtl .layout3-menu .light-font-menu .sub-arrow {
    left: -5px;
    right: auto;
  }
  .rtl .sidenav .sidebar-back i {
    float: left;
  }
  .rtl header.header-5 .top-header .header-dropdown > li:last-child {
    padding: 15px 25px;
    padding-left: 0;
  }
}
@media (min-width: 1300px) {
  header .main-navbar .nav-menu > li a {
    padding: 15px;
  }
}
@media (min-width: 1600px) {
  header .main-navbar .nav-menu > li a.level-1 {
    padding: 15px 30px;
  }
}
@media (max-width: 1440px) {
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }
  .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
    margin-right: 0 !important;
  }
}
@media (max-width: 1367px) {
  .nav-menu a {
    font-size: 14px !important;
  }
}
@media (max-width: 1199px) {
  header .main-navbar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    left: -300px;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
  }
  header .main-navbar .nav-menu.opennav {
    left: 0;
  }
  header .main-navbar .nav-menu .back-btn {
    display: block;
    width: 100%;
  }
  header .main-navbar .nav-menu .back-btn .mobile-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  header .main-navbar .nav-menu > li {
    display: block;
    float: none;
    width: 100%;
  }
  header .main-navbar .nav-menu > li.close-submenu a .sub-arrow:before {
    content: "-";
  }
  header .main-navbar .nav-menu > li a {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }
  header .main-navbar .nav-menu > li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li a .sub-arrow:before {
    content: "\f107";
    font-family: "FontAwesome";
    position: relative;
  }
  header .main-navbar .nav-menu > li a .lable-nav {
    left: 15px;
    top: -8px;
  }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 100%;
    padding: 0;
    position: relative;
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li.close-submenu a .sub-arrow:before {
    content: "-";
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu.opensubchild {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
    padding: 0 30px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .wrapper {
    margin-left: 0px !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
    margin-bottom: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow {
    font: bold 16px/34px monospace !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a {
    margin-bottom: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a .sub-arrow {
    font: bold 16px/34px monospace !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content.opensubmegamenu {
    display: block;
    padding: 0 15px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    line-height: 1.7;
  }
  header.header-gym .main-navbar .nav-menu > li > a {
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .sidenav .sidebar-menu li a {
    padding: 10px 25px;
    font-weight: 700;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .sidenav .sidebar-menu li ul {
    position: relative;
    left: 0;
    width: 100%;
    display: none;
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li ul.opensub1 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul.opensub2 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li a {
    padding: 5px 45px;
  }
  header .sidenav .sidebar-menu li ul li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul li ul.opensub3 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul {
    display: none;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul.opensub4 {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    width: 100% !important;
    padding: 0 30px !important;
    position: relative;
    display: none;
    left: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu.opensidesubmenu {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section h5 {
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul {
    display: block;
    padding: 0;
    background: #fff;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
    padding: 5px 0px 5px 10px !important;
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner img {
    padding: 20px 0 0;
  }
  .header-2 .main-navbar .nav-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px;
  }
  .mega-box.col {
    flex-basis: unset;
  }
  .sidenav {
    overflow-y: auto;
  }
  .main-menu.border-section {
    border: none;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    font-size: 18px;
    padding-left: 5px;
    text-transform: uppercase;
  }
  .main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 175px;
    top: -105px;
  }
  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    top: -1px;
  }
  .rtl .mega-box .link-section .demo ul li a span img {
    left: -70px;
  }
  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: 0;
  }
  .rtl .pixelstrap li .lable-nav {
    left: unset;
    right: 15px;
  }
  .rtl .pixelstrap li .lable-nav:before {
    left: unset;
    right: 5px;
  }
}
@media (max-width: 991px) {
  .top-header .header-contact {
    display: none;
  }
}
@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-search i,
  .main-menu .menu-right .icon-nav .mobile-cart i,
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-setting .close-circle i {
    display: block;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    display: inline-block;
  }
}
@media (max-width: 577px) {
  .top-header {
    height: 0;
  }
  .main-nav-center .toggle-nav {
    right: 15px;
    top: -85px;
  }
  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    z-index: 9;
    box-shadow: 0 2px 5px 0 rgb(168, 168, 168);
  }
  .header-5 .top-header .header-dropdown .mobile-wishlist img,
  .header-5 .top-header .header-dropdown .mobile-account img {
    filter: brightness(5);
  }
  .header-5 .main-menu .brand-logo {
    margin: 0;
    left: 0;
    transform: unset;
  }
  .top-header .header-dropdown .mobile-account,
  .top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 32%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }
  .top-header .header-dropdown .mobile-account i,
  .top-header .header-dropdown .mobile-wishlist i {
    font-size: 22px;
  }
  .top-header .header-dropdown .mobile-account i:before,
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e602";
  }
  .top-header .header-dropdown .mobile-account .onhover-show-div,
  .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    top: unset;
    bottom: 42px;
    right: 0;
  }
  .top-header .header-dropdown .mobile-wishlist {
    right: 64%;
  }
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e634";
  }
  .main-menu .menu-left.category-nav-right .navbar {
    padding-left: 0 !important;
  }
  .main-menu .menu-left .navbar {
    padding: 30px 45px 30px 0 !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting,
  .main-menu .menu-right .icon-nav .mobile-cart,
  .main-menu .menu-right .icon-nav .mobile-search {
    position: fixed;
    bottom: 20px;
    right: 16%;
    font-size: 0;
    padding: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .main-menu .menu-right .icon-nav .mobile-search .show-div {
    top: -58px;
    left: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    top: unset;
    bottom: 42px;
    left: -118px !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: inline-block;
    font-size: 22px;
  }
  .main-menu .menu-right .icon-nav .mobile-setting > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .setting {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }
  .main-menu .menu-right .icon-nav .mobile-cart {
    right: 48%;
  }
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
    font-size: 14px;
  }
  .main-menu .menu-right .icon-nav .mobile-cart i {
    display: inline-block;
    font-size: 22px;
  }
  .main-menu .menu-right .icon-nav .mobile-cart > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search {
    right: 80%;
    z-index: 1;
  }
  .main-menu .menu-right .icon-nav .mobile-search i {
    display: inline-block;
    font-size: 22px;
  }
  .main-menu .menu-right .icon-nav .mobile-search img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
    margin-bottom: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    font-size: 14px;
  }
  .brand-logo {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .rtl .main-nav-center .toggle-nav {
    right: unset;
    left: 15px;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 16%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div {
    left: unset;
    right: 0;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
    left: unset !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart {
    right: unset;
    left: 48%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 80%;
  }
  .rtl .main-menu .menu-left .navbar {
    padding: 30px 0 30px 25px !important;
  }
  .rtl .top-header .header-dropdown .mobile-account,
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 32%;
  }
  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
  .rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    right: 0;
  }
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 64%;
  }
  .rtl .layout3-menu .brand-logo {
    left: unset;
  }
  .nav-submenu li a {
    padding: 0px 35px !important;
    line-height: 1.6 !important;
  }
}
@media (max-width: 480px) {
  .brand-logo img {
    height: 25px;
  }
}
@media (max-width: 640px) {
  .level-2 {
    padding: 15px 30px;
    color: #2554c7 !important;
  }
  .loginicon {
    color: #2554c7 !important;
  }
  .loginbut {
    color: #2554c7 !important;
  }
}
@media (min-width: 640px) {
  .level-2 {
    padding: 15px 30px;
    color: white !important;
  }
  .loginicon {
    text-decoration: none !important;
    color: white !important;
  }
  .loginbut {
    font-size: 25px;
    color: white !important;
  }
}
.account li a .lnr position:relative .count {
  position: absolute;
}

ul.account li a span.lnr {
  position: relative;
  font-size: 18px;
}

ul.account li a span .count {
  position: absolute;
  background: #FBCB0C;
  width: 20px;
  height: 20px;
  border-radius: 9px;
  top: -5px;
  right: 40px;
}

span.count-number {
  color: #fff;
  position: absolute;
  left: 0.2rem;
  font-size: 14px;
  font-weight: 700;
  top: 0.2rem;
}

.menu-title {
  font-weight: 500 !important;
}

.sub-items {
  z-index: 10000;
  display: block;
}

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  width: 100%;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 98%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .autocomplete-items {
    max-height: 300px;
  }
}

.autocomplete-items li {
  font-size: 13px !important;
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}

.autocomplete-items li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.autocomplete-items3 {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  margin-left: 18px;
  width: 91%;
  border-top: none;
  z-index: 99;
  font-size: 13px !important;
  /*position the autocomplete items to be the same width as the container:*/
  top: 98%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .autocomplete-items3 {
    max-height: 300px;
    margin-left: 130px;
  }
}
@media (max-width: 575.98px) {
  .autocomplete-items3 {
    max-height: 300px;
    margin-left: 0px !important;
    width: 69%;
  }
}

.autocomplete-items1 {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  margin-left: 160px;
  width: inherit;
  border-top: none;
  z-index: 99;
  font-size: 13px !important;
  /*position the autocomplete items to be the same width as the container:*/
  top: 98%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .autocomplete-items1 {
    max-height: 300px;
    margin-left: 130px;
  }
}
@media (max-width: 575.98px) {
  .autocomplete-items1 {
    max-height: 300px;
    margin-left: 0px !important;
    width: 69%;
  }
}

.autocomplete-items1 li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px !important;
}

.autocomplete-items1 li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-items3 li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px !important;
}

.autocomplete-items3 li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active1 {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.autocomplete-items2 {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  margin-left: 0px;
  width: 90%;
  border-top: none;
  z-index: 99;
  font-size: 13px !important;
  /*position the autocomplete items to be the same width as the container:*/
  top: 98%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .autocomplete-items2 {
    max-height: 300px;
    margin-left: 130px;
  }
}
@media (max-width: 575.98px) {
  .autocomplete-items2 {
    max-height: 300px;
    margin-left: 110px;
  }
}

.autocomplete-items2 li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px !important;
}

.autocomplete-items2 li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.input-group {
  line-height: 1rem !important;
}

.search-input {
  margin-left: 80px;
  margin-right: 50px;
  font-size: 14px !important;
}
@media (max-width: 767.98px) {
  .search-input {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.search-input-aiema {
  margin-left: 80px;
  margin-right: 50px;
  font-size: 14px !important;
  width: 90%;
}
@media (max-width: 767.98px) {
  .search-input-aiema {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.search-drop {
  line-height: 1rem !important;
  background-color: #3c57a6 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-width: 2px;
  border-color: #E1E7EC;
  border-right: none;
  font-size: 14px !important;
  width: 160px;
  color: #fff !important;
}
@media (max-width: 767.98px) {
  .search-drop {
    width: 130px;
  }
}
@media (max-width: 575.98px) {
  .search-drop {
    width: 110px;
    display: none !important;
    font-size: 12px !important;
  }
}

.search-form-input {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .search-form-input {
    max-width: 60%;
  }
  .search-input-aiema {
    width: 120% !important;
  }
}
.logout-cutom {
  color: #007bff;
}

.form-control:focus {
  border-color: #E1E7EC !important;
}

.dropdown-item {
  font-size: 13px !important;
}

.titleproper {
  text-transform: lowercase;
}

.titleproper:first-line {
  text-transform: capitalize;
}

@media (min-width: 576px) {
  .sticky-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
  }
}

.header {
  background: #fff;
  border-bottom: 2px solid #ddd;
}
.header .logo {
  max-width: 200px;
}
.header .flex-container {
  align-items: center;
  display: flex !important;
  padding: 15px 2px 15px 2px;
}
.header .flex-container .header-row-one {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header .flex-container .header-row-one .account-section {
  position: relative;
}
.header .flex-container .header-row-one .account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .flex-container .header-row-one .account li {
  padding: 6px 10px;
}
.header .flex-container .header-row-one .account li.sell {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.header .flex-container .header-row-one .account li.loginuser .myaccount {
  display: flex;
  align-items: center;
}
.header .flex-container .header-row-one .account li.loginuser .myaccount .menu-title {
  font-size: 14px;
}
.header .flex-container .header-row-one .account li.language .form-control {
  border: none;
  height: 100%;
}
.header .flex-container .header-row-one .account li.language .form-control:focus {
  border-color: transparent;
  border: none;
  box-shadow: none;
}
.header .flex-container .header-row-one .account .profile-links li {
  padding: 0px;
  border-bottom: 1px solid black;
}
.header .flex-container .header-row-one .account .profile-links li a {
  font-size: 14px;
  color: #404B59;
  padding: 6px 15px;
  display: block;
}
.header .flex-container .header-row-one .account .profile-links li a i {
  margin-right: 10px;
  font-size: 17px;
}
.header .flex-container .header-row-one .search-bar-group {
  flex: 1;
}
.header .flex-container .header-row-one .search-bar-group .search-bar {
  margin-left: 10px !important;
}
.header .flex-container .header-row-one .search-bar-group .search-bar .input-group input {
  background: #3c57a6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-width: 2px;
  border-color: #E1E7EC;
  border-left: 1px solid #ccc !important;
}
.header .flex-container .header-row-one .search-bar-group .search-bar .input-group input:hover {
  border-color: #3c57a6;
}
.header .flex-container .header-row-one .search-bar-group .search-bar .input-group p {
  margin: 0px 30px 0px 0px;
  font-size: 12px;
  color: #808080;
  float: left;
}
.header .flex-container .header-row-one .search-bar-group .search-bar .input-group p span {
  font-size: 14px;
  color: #000000;
}
.header .flex-container .header-row-one .search-bar-group .search-bar .input-group .input-group-btn .btn-solid {
  color: #fff;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: 0px;
  background-color: #3c57a6;
}

.linenraj-header .logo {
  max-width: 200px;
}
.linenraj-header .flex-container {
  align-items: center;
  display: flex !important;
  padding: 15px 2px 15px 2px;
}
.linenraj-header .flex-container .header-row-one {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.linenraj-header .flex-container .header-row-one .account-section {
  position: relative;
}
.linenraj-header .flex-container .header-row-one .account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linenraj-header .flex-container .header-row-one .account li {
  padding: 6px 10px;
}
.linenraj-header .flex-container .header-row-one .account li.sell {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.linenraj-header .flex-container .header-row-one .account li.loginuser .myaccount {
  display: flex;
  align-items: center;
}
.linenraj-header .flex-container .header-row-one .account li.loginuser .myaccount .menu-title {
  font-size: 14px;
}
.linenraj-header .flex-container .header-row-one .account li.language .form-control {
  border: none;
  height: 100%;
}
.linenraj-header .flex-container .header-row-one .account li.language .form-control:focus {
  border-color: transparent;
  border: none;
  box-shadow: none;
}
.linenraj-header .flex-container .header-row-one .account .profile-links li {
  padding: 0px;
  border-bottom: 1px solid black;
}
.linenraj-header .flex-container .header-row-one .account .profile-links li a {
  font-size: 14px;
  color: #404B59;
  padding: 6px 15px;
  display: block;
}
.linenraj-header .flex-container .header-row-one .account .profile-links li a i {
  margin-right: 10px;
  font-size: 17px;
}
.linenraj-header .flex-container .header-row-one .search-bar-group {
  flex: 1;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar {
  margin-left: 10px !important;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar .input-group input {
  background: #3c57a6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-width: 2px;
  border-color: #E1E7EC;
  border-left: 1px solid #ccc !important;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar .input-group input:hover {
  border-color: #3c57a6;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar .input-group p {
  margin: 0px 30px 0px 0px;
  font-size: 12px;
  color: #808080;
  float: left;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar .input-group p span {
  font-size: 14px;
  color: #000000;
}
.linenraj-header .flex-container .header-row-one .search-bar-group .search-bar .input-group .input-group-btn .btn-solid {
  color: #fff;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: 0px;
  background-color: #3c57a6;
}

@media (max-width: 1920px) {
  .bestgift-menu {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .bestgift-menu {
    flex-direction: column;
    box-sizing: border-box;
  }
}
/*=====================
    27.Menu css start
==========================*/
header .main-navbar {
  text-align: center;
}
header .main-navbar .nav-menu {
  display: inline-block;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  position: relative;
}
header .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .main-navbar .nav-menu > li > a {
  display: block;
  font-size: 14px !important;
  padding-right: 24px;
  color: #404B59;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  position: relative;
  font-family: "Inter-SemiBold";
}
header .main-navbar .nav-menu > li > a .sub-arrow {
  /* position: absolute;
   top: 50%;
   margin-top: -17px;
   left: auto;
   right: 15px;
   width: 34px;
   height: 34px;
   overflow: hidden;
   font: bold 16px/34px monospace !important;
   text-align: center;
   text-shadow: none;
   border-radius: 4px;*/
  margin-left: 5px;
}
header .main-navbar .nav-menu > li > a .sub-arrow:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
}
header .main-navbar .nav-menu > li > a .lable-nav {
  position: absolute;
  background-color: var(--theme-deafult);
  padding: 2px 7px;
  color: #ffffff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3;
}
header .main-navbar .nav-menu > li > a .lable-nav:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-deafult);
  top: 15px;
  left: 5px;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red {
  background-color: #ff4c3b;
}
header .main-navbar .nav-menu > li > a .lable-nav.lable-red:before {
  border-top: 5px solid #ff4c3b;
}
header .main-navbar .nav-menu > li .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  box-shadow: 0 0 1px 0 #ebebeb;
  padding: 15px 0px 15px 0px;
}
header .main-navbar .nav-menu > li .nav-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .nav-submenu li a {
  border: 0 !important;
  background: transparent;
  color: #222222;
  border-radius: 0 !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 5px 35px;
  display: block;
  line-height: 1.9;
}
header .main-navbar .nav-menu > li .nav-submenu li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-deafult);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}
header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .main-navbar .nav-menu > li .mega-menu-container {
  position: absolute;
  left: 0px !important;
  z-index: 4;
  padding: 30px;
  width: 100%;
}
header .main-navbar .nav-menu > li .mega-menu-container .container {
  width: 100% !important;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
  width: 20%;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
  text-transform: capitalize;
  font-size: 13px;
  font-family: "Inter-SemiBold";
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a {
  text-transform: capitalize;
  font-size: 13px;
  font-family: "Inter-SemiBold";
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
  border: 0 !important;
  background: transparent;
  color: #1a1919;
  border-radius: 0 !important;
  font-size: 15px !important;
  font-weight: 400;
  text-transform: capitalize;
  padding: 3px 0;
  display: block;
  line-height: 1.9;
  position: relative;
  font-family: "Inter-Regular";
}
header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
  position: absolute;
  bottom: 0;
  content: "";
  height: 2px;
  width: 0;
  background-color: var(--theme-deafult);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
header .main-navbar .nav-menu > li:hover > a {
  color: var(--theme-deafult);
}
header .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}
header.header-gym .main-navbar .nav-menu > li > a {
  color: white;
  padding-top: 22px;
  padding-bottom: 22px;
}
header.header-tools .main-navbar .nav-menu > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}
header.header-tools .main-navbar .nav-menu > li > a .lable-nav {
  top: 0;
}
header .sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}
header .sidenav .sidebar-back {
  padding: 20px;
  font-size: 18px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  cursor: pointer;
}
header .sidenav nav {
  background-color: #ffffff;
  z-index: 9;
  position: relative;
  height: 100vh;
  top: 0;
}
header .sidenav.openSide {
  left: 0;
  transition: all 0.5s ease;
}
header .sidenav.openSide .sidebar-overlay {
  opacity: 0.8;
  visibility: visible;
}
header .sidenav .sidebar-overlay {
  visibility: hidden;
  opacity: 0;
  background-color: #212331;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
header .sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  margin-left: 50px;
  margin-top: 50px;
  color: #938d8c;
}
header .sidenav .sidebar-menu {
  background-color: #ffffff;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
}
header .sidenav .sidebar-menu li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  float: none;
}
header .sidenav .sidebar-menu li a {
  padding: 10px 50px 20px 40px;
  position: relative;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}
header .sidenav .sidebar-menu li ul {
  position: absolute;
  width: 200px;
  left: 100%;
  top: 0;
  padding: 15px 0px;
  display: none;
  background-color: #fff;
}
header .sidenav .sidebar-menu li ul li a {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li ul li a:active, header .sidenav .sidebar-menu li ul li a:focus, header .sidenav .sidebar-menu li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .sidebar-menu li .mega-menu {
  position: absolute;
  left: 100%;
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #ebebeb;
}
header .sidenav .sidebar-menu li .mega-menu li ul {
  position: relative;
  width: 100%;
  left: inherit;
}
header .sidenav .pixelstrap li a {
  padding: 10px 50px 20px 40px;
}
header .sidenav .pixelstrap li ul li a {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
  padding: 5px 35px;
}
header .sidenav .pixelstrap li .clothing-menu li a {
  padding: 5px 0 !important;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus {
  font-size: 14px;
}
header .sidenav .pixelstrap li .clothing-menu li a:hover {
  font-size: 14px;
  padding: 5px 0;
}
header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
  padding: 5px 0 !important;
}
header .layout3-menu .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
}
header .layout3-menu .main-navbar .nav-menu > li {
  position: relative;
  float: left;
  display: block;
}
header .layout3-menu .main-navbar .nav-menu > li > a {
  text-transform: capitalize;
  padding-right: 34px;
}
header .layout3-menu .main-navbar .nav-menu > li.mega-menu {
  position: unset;
}

.toggle-nav {
  position: absolute;
  cursor: pointer;
  left: 15px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
}
.toggle-nav i {
  font-size: 24px;
  color: var(--theme-deafult);
}

.mega-menu-banner:hover:before {
  display: none;
}

.new-tag {
  padding-left: 3px;
  color: var(--theme-deafult);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.up-text {
  position: relative;
}
.up-text span {
  position: absolute;
  top: -5px;
  right: -10px;
  color: var(--theme-deafult);
  font-size: 12px;
  font-weight: 700;
}

.mega-box .link-section .demo ul li a {
  position: relative;
}
.mega-box .link-section .demo ul li a:hover, .mega-box .link-section .demo ul li a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}
.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: var(--theme-deafult);
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}
.mega-box .link-section .demo .title {
  border-bottom: 2px solid red !important;
}

.rtl .link-section {
  float: right;
}
.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 1200px) {
  .responsive-btn {
    display: none;
  }
  header .main-navbar .nav-menu {
    display: flex;
    justify-content: center;
  }
  header .main-navbar .nav-menu > li.nosub:hover .nav-submenu {
    display: none;
  }
  header .main-navbar .nav-menu > li a {
    padding: 15px 17px 15px 17px;
  }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 200px;
    display: none;
    box-shadow: 0 0px 3px -1px #555;
    background: #F8F9FA;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a {
    color: #1a1919;
    font-size: 13px !important;
    font-weight: 400;
    text-transform: capitalize;
    display: block;
    line-height: 1.9;
    position: relative;
    font-family: "Inter-Regular";
  }
  header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:checked .nav-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu li:checked .nav-sub-childmenu .nav-sub-sub-childmenu {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
    background: #F8F9FA;
    box-shadow: 0 0px 3px -1px #555;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .menu-title h5:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .menu-title a:after {
    content: "\f0da";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .main-navbar .nav-menu > li:hover .nav-submenu {
    display: block;
  }
  header .main-navbar .nav-menu > li:hover .mega-menu-container {
    display: block;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: auto;
    right: 7px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
  }
  header .sidenav .sidebar-menu li > ul {
    display: none;
  }
  header .sidenav .sidebar-menu li > ul li a {
    padding: 5px 35px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li > ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--theme-deafult);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li > ul li a .sub-arrow {
    margin-top: -17px;
  }
  header .sidenav .sidebar-menu li:hover > ul {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    position: absolute;
    left: 100%;
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 #ebebeb;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section h5 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
    margin-top: 15px;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul {
    position: relative;
    width: 100%;
    left: inherit;
    display: block;
    padding: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:before {
    position: absolute;
    bottom: 0;
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--theme-deafult);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  header .sidenav .sidebar-menu li .mega-menu li .mega-menu-banner {
    padding: 0;
  }
  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px;
  }
  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 35px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus {
    font-size: 14px;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:hover {
    font-size: 14px;
    padding: 5px 0;
  }
  header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
    padding: 5px 0 !important;
  }
  .toggle-nav {
    display: none;
  }
  .mobile-back {
    display: none !important;
  }
  .main-nav-center > nav {
    text-align: center;
  }
  .main-nav-center .sm {
    display: inline-block;
    vertical-align: middle;
  }
  .main-nav-center .sm > li .lable-nav {
    top: 3px;
  }
  .main-nav-center .sm > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .layout3-menu .pixelstrap a {
    text-transform: capitalize;
    padding-right: 34px;
  }
  .layout3-menu .pixelstrap .sub-arrow {
    right: 12px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li a:hover:before {
    right: 35px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    right: unset;
    left: -200px;
  }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav-sub-sub-childmenu {
    right: unset;
    left: -200px;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
    right: 0;
  }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover::before {
    right: 0;
  }
  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }
  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }
  .rtl .layout3-menu .light-font-menu li > a, .rtl .layout3-menu .light-font-menu li > a:hover, .rtl .layout3-menu .light-font-menu li > a:active {
    padding-right: 0;
    padding-left: 28px;
  }
  .rtl .layout3-menu .light-font-menu .sub-arrow {
    left: -5px;
    right: auto;
  }
  .rtl .sidenav .sidebar-back i {
    float: left;
  }
  .rtl header.header-5 .top-header .header-dropdown > li:last-child {
    padding: 15px 25px;
    padding-left: 0;
  }
}
@media (min-width: 1300px) {
  header .main-navbar .nav-menu > li a {
    padding: 15px;
  }
}
@media (min-width: 1600px) {
  header .main-navbar .nav-menu > li a.level-1 {
    padding: 15px 30px;
  }
}
@media (max-width: 1440px) {
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }
  .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
    margin-right: 0 !important;
  }
}
@media (max-width: 1367px) {
  .nav-menu a {
    font-size: 14px !important;
  }
}
@media (max-width: 1199px) {
  header .main-navbar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    left: -300px;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
  }
  header .main-navbar .nav-menu.opennav {
    left: 0;
  }
  header .main-navbar .nav-menu .back-btn {
    display: block;
    width: 100%;
  }
  header .main-navbar .nav-menu .back-btn .mobile-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  header .main-navbar .nav-menu > li {
    display: block;
    float: none;
    width: 100%;
  }
  header .main-navbar .nav-menu > li.close-submenu a .sub-arrow:before {
    content: "-";
  }
  header .main-navbar .nav-menu > li a {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }
  header .main-navbar .nav-menu > li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .main-navbar .nav-menu > li a .lable-nav {
    left: 15px;
    top: -8px;
  }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 100%;
    padding: 0;
    position: relative;
    display: none;
  }
  header .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li.close-submenu a .sub-arrow:before {
    content: "-";
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow {
    right: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu {
    display: none;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu.opensubchild {
    display: block;
  }
  header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu .nav--sub-sub-childmenu li a {
    padding: 5px 35px 5px 50px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none;
    padding: 0 30px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container.opensubmenu {
    display: block;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
    width: 100%;
    padding: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
    margin-bottom: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow {
    font: bold 16px/34px monospace !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a {
    margin-bottom: 0;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a .sub-arrow {
    font: bold 16px/34px monospace !important;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title a .sub-arrow:before {
    content: "+";
    position: absolute;
    right: -16px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
    display: none;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content.opensubmegamenu {
    display: block;
    padding: 0 15px;
  }
  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    line-height: 1.7;
  }
  header.header-gym .main-navbar .nav-menu > li > a {
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .sidenav .sidebar-menu li a {
    padding: 10px 25px;
    font-weight: 700;
  }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 15px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
  }
  header .sidenav .sidebar-menu li a .sub-arrow:before {
    content: "+";
    font-family: inherit;
    position: relative;
  }
  header .sidenav .sidebar-menu li ul {
    position: relative;
    left: 0;
    width: 100%;
    display: none;
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li ul.opensub1 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul.opensub2 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li a {
    padding: 5px 45px;
  }
  header .sidenav .sidebar-menu li ul li ul li ul {
    display: none;
    background: rgba(255, 85, 69, 0.04);
  }
  header .sidenav .sidebar-menu li ul li ul li ul.opensub3 {
    display: block;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul {
    display: none;
  }
  header .sidenav .sidebar-menu li ul li ul li ul li ul.opensub4 {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu {
    width: 100% !important;
    padding: 0 30px !important;
    position: relative;
    display: none;
    left: 0;
  }
  header .sidenav .sidebar-menu li .mega-menu.opensidesubmenu {
    display: block;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section h5 {
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul {
    display: block;
    padding: 0;
    background: #fff;
  }
  header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
    padding: 5px 0px 5px 10px !important;
    font-size: 12px;
    font-weight: 400;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner {
    padding: 5px 0;
  }
  header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner img {
    padding: 20px 0 0;
  }
  .header-2 .main-navbar .nav-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px;
  }
  .mega-box.col {
    flex-basis: unset;
  }
  .sidenav {
    overflow-y: auto;
  }
  .main-menu.border-section {
    border: none;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px;
  }
  .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
    font-size: 18px;
    padding-left: 5px;
    text-transform: uppercase;
  }
  .main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 175px;
    top: -105px;
  }
  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    top: -1px;
  }
  .rtl .mega-box .link-section .demo ul li a span img {
    left: -70px;
  }
  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: 0;
  }
  .rtl .pixelstrap li .lable-nav {
    left: unset;
    right: 15px;
  }
  .rtl .pixelstrap li .lable-nav:before {
    left: unset;
    right: 5px;
  }
}
@media (max-width: 991px) {
  .top-header .header-contact {
    display: none;
  }
}
@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-search i,
  .main-menu .menu-right .icon-nav .mobile-cart i,
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-setting .close-circle i {
    display: block;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    display: inline-block;
  }
}
@media (max-width: 577px) {
  .top-header {
    height: 0;
  }
  .main-nav-center .toggle-nav {
    right: 15px;
    top: -85px;
  }
  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    background-color: #333333;
    width: 100vw;
    z-index: 9;
    box-shadow: 0 2px 5px 0 rgb(168, 168, 168);
  }
  .header-5 .top-header .header-dropdown .mobile-wishlist img,
  .header-5 .top-header .header-dropdown .mobile-account img {
    filter: brightness(5);
  }
  .header-5 .main-menu .brand-logo {
    margin: 0;
    left: 0;
    transform: unset;
  }
  .top-header .header-dropdown .mobile-account,
  .top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 32%;
    font-size: 0;
    padding: 0;
    z-index: 9;
  }
  .top-header .header-dropdown .mobile-account i,
  .top-header .header-dropdown .mobile-wishlist i {
    font-size: 22px;
    font-family: themify;
    color: #999999;
  }
  .top-header .header-dropdown .mobile-account i:before,
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e602";
  }
  .top-header .header-dropdown .mobile-account .onhover-show-div,
  .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    top: unset;
    bottom: 42px;
    right: 0;
  }
  .top-header .header-dropdown .mobile-account:hover i,
  .top-header .header-dropdown .mobile-wishlist:hover i {
    color: #999999;
  }
  .top-header .header-dropdown .mobile-wishlist {
    right: 64%;
  }
  .top-header .header-dropdown .mobile-wishlist i {
    font-family: themify;
    color: #999999 !important;
  }
  .top-header .header-dropdown .mobile-wishlist i:before {
    content: "\e634";
  }
  .main-menu .menu-left.category-nav-right .navbar {
    padding-left: 0 !important;
  }
  .main-menu .menu-left .navbar {
    padding: 30px 45px 30px 0 !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting,
  .main-menu .menu-right .icon-nav .mobile-cart,
  .main-menu .menu-right .icon-nav .mobile-search {
    position: fixed;
    bottom: 20px;
    right: 16%;
    font-size: 0;
    padding: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .main-menu .menu-right .icon-nav .mobile-search .show-div {
    top: -58px;
    left: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    top: unset;
    bottom: 42px;
    left: -118px !important;
  }
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-setting > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-setting .setting {
    top: unset;
    bottom: 42px;
    left: unset !important;
  }
  .main-menu .menu-right .icon-nav .mobile-cart {
    right: 48%;
  }
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
    font-size: 14px;
  }
  .main-menu .menu-right .icon-nav .mobile-cart i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-cart > div img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search {
    right: 80%;
    z-index: 1;
  }
  .main-menu .menu-right .icon-nav .mobile-search i {
    display: inline-block;
    font-size: 22px;
    color: #999999;
  }
  .main-menu .menu-right .icon-nav .mobile-search img {
    display: none;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
    margin-bottom: 0;
  }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    font-size: 14px;
  }
  .brand-logo {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .rtl .main-nav-center .toggle-nav {
    right: unset;
    left: 15px;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 16%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div {
    left: unset;
    right: 0;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
    left: unset !important;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart {
    right: unset;
    left: 48%;
  }
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 80%;
  }
  .rtl .main-menu .menu-left .navbar {
    padding: 30px 0 30px 25px !important;
  }
  .rtl .top-header .header-dropdown .mobile-account,
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 32%;
  }
  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
  .rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
    right: 0;
  }
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 64%;
  }
  .rtl .layout3-menu .brand-logo {
    left: unset;
  }
}
@media (max-width: 480px) {
  .brand-logo img {
    height: 25px;
  }
}
/* INTER-REGULAR-regular - latin */ /*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.header-cart .min-cart-product-wrapper .min-cart-group {
  display: flex;
  border-bottom: 1px solid #e6e5e5;
}
.header-cart .min-cart-product-wrapper .min-cart-group .img-wrapper {
  max-width: 80px;
  display: flex;
}
.header-cart .min-cart-product-wrapper .min-cart-group .img-wrapper .btn-close span {
  font-size: 14px;
}
.header-cart .min-cart-product-wrapper .min-cart-group .product-detail {
  margin-right: 5px;
}
.header-cart .min-cart-product-wrapper .min-cart-group .product-detail .product-name {
  font-size: 14px;
}
.header-cart .min-cart-product-wrapper .cart-summary .summary-details ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.header-cart .min-cart-product-wrapper .cart-summary .summary-details ul li .btn {
  font-size: 14px;
}

.account-popup .popup-content {
  overflow-y: auto;
  height: fit-content;
  max-height: 370px;
  padding: 15px;
  min-width: 300px;
}
.account-popup .popup-content .new-register {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.account-popup .popup-content .new-register a {
  color: #fff;
}

.popup-content {
  position: absolute;
  top: 100%;
  width: 100%;
  max-width: 280px;
  width: 300px;
  right: 0;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 3px 5px 0;
  transition-delay: 0.3s;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  -o-transform: translate3d(0, 50px, 0);
  -ms-transform: translate3d(0, 50px, 0);
  transition: transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -moz-transition: -moz-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -webkit-transition: -webkit-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -ms-transition: -ms-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  visibility: hidden;
  z-index: 1001;
  padding: 20px 0;
  background: #fff;
}
.popup-content.profile-link {
  padding: 0px;
}

.account-popup:hover .popup-content {
  display: block;
  margin: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  visibility: visible;
}

.isempty:empty {
  display: none;
}

@media screen and (max-width: 600px) {
  .account-popup:hover .popup-content {
    display: none !important;
  }
}
.account-popup1 .popup-content1 {
  overflow-y: auto;
  height: fit-content;
  max-height: 370px;
  padding: 15px;
  width: 100%;
}
.account-popup1 .popup-content1 .new-register {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.account-popup1 .popup-content1 .new-register a {
  color: #fff;
}
.account-popup1 .popup-content-cle {
  height: fit-content;
  max-height: 370px;
  padding: 15px;
  width: 100%;
}
.account-popup1 .popup-content-cle .new-register {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.account-popup1 .popup-content-cle .new-register a {
  color: #fff;
}
.account-popup1 .popup-content-Taz {
  height: fit-content;
  padding: 15px;
  width: 80%;
}
.account-popup1 .popup-content-Taz .new-register {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.account-popup1 .popup-content-Taz .new-register a {
  color: #fff;
}
.account-popup1 .popup-content2 {
  overflow-y: auto;
  height: 175px;
  max-height: 370px;
  padding: 15px;
  width: 100%;
}
.account-popup1 .popup-content2 .new-register {
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}
.account-popup1 .popup-content2 .new-register a {
  color: #fff;
}

.popup-content1 {
  position: absolute;
  top: 100%;
  width: 100%;
  min-width: 100%;
  right: 0;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 3px 5px 0;
  box-shadow: 2px 10px 7px 0px rgba(84, 84, 84, 0.27);
  transition-delay: 0.3s;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  -o-transform: translate3d(0, 50px, 0);
  -ms-transform: translate3d(0, 50px, 0);
  transition: transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -moz-transition: -moz-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -webkit-transition: -webkit-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -ms-transition: -ms-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  visibility: hidden;
  z-index: 1001;
  padding: 20px 0;
  background: #fff;
}
.popup-content1.profile-link {
  padding: 0px;
}

.popup-content-cle {
  position: absolute;
  top: 100%;
  width: 100%;
  min-width: 100%;
  right: 0;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 3px 5px 0;
  box-shadow: 2px 10px 7px 0px rgba(84, 84, 84, 0.27);
  transition-delay: 0.3s;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  -o-transform: translate3d(0, 50px, 0);
  -ms-transform: translate3d(0, 50px, 0);
  transition: transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -moz-transition: -moz-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -webkit-transition: -webkit-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -ms-transition: -ms-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  visibility: hidden;
  z-index: 1001;
  padding: 20px 0;
  background: #fff;
}
.popup-content-cle.profile-link {
  padding: 0px;
}

.popup-content-Taz {
  position: absolute;
  width: 80%;
  min-width: 30%;
  top: 24%;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 3px 5px 0;
  box-shadow: 2px 10px 7px 0px rgba(84, 84, 84, 0.27);
  transition-delay: 0.3s;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  -o-transform: translate3d(0, 50px, 0);
  -ms-transform: translate3d(0, 50px, 0);
  transition: transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -moz-transition: -moz-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -webkit-transition: -webkit-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -ms-transition: -ms-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  visibility: hidden;
  z-index: 1001;
  padding: 20px 0;
  background: #fff;
}
.popup-content-Taz.profile-link {
  padding: 0px;
}

.popup-content2 {
  position: absolute;
  top: 100%;
  width: 100%;
  min-width: 100%;
  right: 0;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 3px 5px 0;
  box-shadow: 2px 10px 7px 0px rgba(84, 84, 84, 0.27);
  transition-delay: 0.3s;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  -o-transform: translate3d(0, 50px, 0);
  -ms-transform: translate3d(0, 50px, 0);
  transition: transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -moz-transition: -moz-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -webkit-transition: -webkit-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  -ms-transition: -ms-transform 0.4s ease 0s, opacity 0.4s ease 0s, visibility 0.4s ease 0s;
  visibility: hidden;
  z-index: 1001;
  padding: 20px 0;
  background: #fff;
}
.popup-content2.profile-link {
  padding: 0px;
}

.account-popup1:hover .popup-content1 {
  display: block;
  margin: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  visibility: visible;
}
.account-popup1:hover .popup-content-cle {
  display: block;
  margin: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  visibility: visible;
}
.account-popup1:hover .popup-content-Taz {
  display: block;
  margin: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  visibility: visible;
}
.account-popup1:hover .popup-content2 {
  display: block;
  margin: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  visibility: visible;
}

.close {
  float: right;
  font-size: medium;
  color: #3c57a6;
}

.close:hover {
  cursor: pointer;
}

.txt:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .header-cart {
    transform: scale(0.8);
  }
}
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-decoration: none;
}

input[type=radio],
input[type=checkbox] {
  cursor: pointer;
}

.inq-secs-mt-5 {
  margin-top: 5rem !important;
}

.inq-fw-st {
  font-weight: 500;
}

.jumbotron {
  padding: 2rem 2rem !important;
}

.table td,
.table th {
  padding: 0.75rem 1rem !important;
}

a:hover {
  text-decoration: none !important;
}

.inq-row {
  display: flex;
  flex-wrap: wrap;
}

.inq-htop-st {
  background: #3c57a6;
  justify-content: center;
  position: relative;
}
.inq-htop-st p {
  padding: 0.5rem 0;
  margin-bottom: 0rem;
  color: #ffffff;
  font-weight: 400;
}
.inq-htop-st a {
  color: #ffffff !important;
  border-bottom: 2px solid;
}
.inq-htop-st .fa {
  position: absolute;
  right: 122px;
  top: 10px;
  color: #ffffff;
}

.inq-Caro-st .inq-sli-btn-st p {
  margin-bottom: 10px !important;
  font-size: 25px;
  font-weight: 600;
}
.inq-Caro-st .inq-sli-btn-st p span {
  font-size: 25px;
  color: rgb(72, 149, 159);
}

.inq-sli-btn-st h3 {
  font-size: 38px;
  line-height: 40px;
  font-weight: 700;
}

.inq-hmid1-st {
  float: left;
}
.inq-hmid1-st .nav-tabs {
  border-bottom: none;
}
.inq-hmid1-st .nav-tabs .nav-link {
  color: inherit;
  border: none;
  padding: 0.2rem 0rem;
  font-size: 12px;
  font-weight: 500;
}
.inq-hmid1-st .nav-tabs .nav-link:hover {
  color: inherit !important;
  border-color: none;
}
.inq-hmid1-st .dropdown-toggle::after {
  margin-left: 0.355em;
  vertical-align: 0.1em;
}

.inq-hmid2-st {
  float: right;
  float: right;
  padding-top: 0rem;
}
.inq-hmid2-st a {
  font-size: 12px;
  font-weight: 500;
}

.inq-sec-h-st {
  padding-top: 1.5rem;
}
.inq-sec-h-st .inq-logo-st img {
  max-width: 85%;
  cursor: pointer;
}
.inq-sec-h-st .inq-sec-h-fld-st {
  position: relative;
}
.inq-sec-h-st .inq-sec-h-fld-st .form-control {
  height: calc(2em + 0.75rem + 2px);
  border-radius: 1.5rem;
  position: relative;
  margin-top: 0.4rem;
}
.inq-sec-h-st .inq-sec-h-fld-st span {
  position: absolute;
  right: 17px;
  top: 10px;
}
.inq-sec-h-st .inq-sec-h-fld-st span .fa {
  font-size: 18px;
  color: #777;
  cursor: pointer;
}
.inq-sec-h-st .inq-rev-st .col-md-4 {
  padding-right: 0px;
}
.inq-sec-h-st .inq-rev-st .col-md-8 {
  padding-left: 10px;
}
.inq-sec-h-st .inq-rev-st .col-md-8 .fa-star {
  color: #f7e09c;
  font-size: 12px;
}
.inq-sec-h-st .inq-rev-st .col-md-8 .checked {
  color: #fdb915;
}
.inq-sec-h-st .inq-rev-st .col-md-8 .inq-rev-txt-st h4 {
  margin-bottom: -0.5rem;
}
.inq-sec-h-st .inq-rimg-st img {
  max-width: 80%;
  position: relative;
}
.inq-sec-h-st .inq-rimg-st span {
  position: absolute;
  top: 11px;
  right: 18px;
  font-size: 12px;
}
.inq-sec-h-st .inq-limg-st img {
  max-width: 85%;
}
.inq-sec-h-st .inq-cimg-st img {
  max-width: 75%;
  position: relative;
}
.inq-sec-h-st .inq-cimg-st span {
  position: absolute;
  right: 30px;
}
.inq-sec-h-st .inq-ltext-st {
  line-height: 1.3;
  padding-left: 0px;
}
.inq-sec-h-st .inq-ltext-st p {
  font-size: 13px;
  text-transform: uppercase;
}
.inq-sec-h-st .inq-ltext-st p:first-child {
  font-weight: 500;
}

.inq-menu-st .navbar {
  padding: 0rem 1rem;
}
.inq-menu-st .navbar ul li {
  list-style: none;
  color: #ddd;
  text-transform: uppercase;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.inq-menu-st .navbar ul li.active {
  border-bottom: 2px solid #be2222;
}
.inq-menu-st .navbar ul li a {
  position: relative;
  font-weight: 500;
}
.inq-menu-st .navbar ul li a::after {
  content: "";
  position: absolute;
  bottom: 2px;
  top: 0;
  left: 0;
  right: 0px;
}

.inq-hr-st {
  margin-bottom: 0rem !important;
}

.inq-hr1-st {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.inq-Caro-st {
  width: 100%;
  background-position: top center;
  background-size: cover;
  position: relative;
  margin-bottom: 3rem;
}
.inq-Caro-st::before {
  content: "";
  background: #e9f0e8;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.inq-Caro-st .inq-sli-btn-st {
  position: absolute;
  bottom: 20%;
  left: 24%;
  top: 30%;
}

.inq-home-about-st img {
  width: 30%;
  margin-bottom: 1.5rem;
}
.inq-home-about-st h5 {
  font-size: 1rem !important;
  font-weight: bolder;
  position: relative;
}

.inq-home-ser-st img {
  width: 100%;
}

.inq-testi-st h5 {
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: row;
}
.inq-testi-st h5::before, .inq-testi-st h5::after {
  content: "";
  flex: 1 1;
  margin: auto 10px;
  border-bottom: 2px solid #000;
}

.inq-food-ch-st {
  padding-left: 17px;
  padding-right: 17px;
}

.inq-slider-st {
  width: 100%;
  height: 45vh;
  background-size: cover;
  position: relative;
  margin-bottom: 3rem;
}
.inq-slider-st::before {
  content: "";
  background: rgba(0, 0, 0, 0.7490196078);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.inq-slider-st .inq-slider-inner-st {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.inq-slider-st .inq-slider-inner-st h1,
.inq-slider-st .inq-slider-inner-st div {
  color: #fff;
}

.inq-menu-left-st {
  border-right: 1px solid #cdcdcd;
  padding-right: 2rem;
  height: 100%;
}
.inq-menu-left-st ul li {
  text-align: right;
  line-height: 2;
  font-weight: 400;
  cursor: pointer;
}
.inq-menu-left-st ul li:hover {
  color: rgb(34, 34, 34);
  font-weight: 500;
}
.inq-menu-left-st ul li.active {
  color: rgb(34, 34, 34);
  font-weight: 500;
  position: relative;
  font-size: 18px;
}
.inq-menu-left-st ul li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: -30px;
  border-right: 3px solid rgb(34, 34, 34);
}

.inq-menu-left1-st {
  border-right: 1px solid #cdcdcd;
  padding-right: 2rem;
  height: 100%;
}
.inq-menu-left1-st ul li {
  line-height: 2;
  font-weight: 400;
  margin-left: 35px;
  cursor: pointer;
}
.inq-menu-left1-st ul li:hover {
  color: rgb(34, 34, 34);
  font-weight: 500;
}
.inq-menu-left1-st ul li.active {
  color: rgb(34, 34, 34);
  font-weight: 500;
  position: relative;
  font-size: 18px;
}
.inq-menu-left1-st ul li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: -30px;
  border-right: 3px solid rgb(34, 34, 34);
}

.fa-style i.fa {
  font-size: 9px !important;
  background: rgb(192, 6, 6);
  color: #ffffff;
  padding: 3px;
  border-radius: 25px;
  width: 14px;
  line-height: 10px;
}

.fa-style i.fa:hover {
  background: #3c57a6;
  color: #ffffff;
  cursor: pointer;
}

.inq-menu-right-st .inq-title-st p {
  color: #777;
  position: relative;
}
.inq-menu-right-st .inq-title-st p::after {
  content: "";
  position: absolute;
  background-color: #777;
  width: 32px;
  height: 2px;
  top: 25px;
  left: 1px;
}
.inq-menu-right-st .inq-ft-st img {
  width: 15px;
}
.inq-menu-right-st .inq-fo-des-in-st {
  padding-left: 0px;
}
.inq-menu-right-st .inq-dis-sec-st p:last-child {
  font-size: 13px;
  color: #6c757d;
}
.inq-menu-right-st .inq-fo-im-st {
  margin-top: 1rem;
}
.inq-menu-right-st .inq-fo-im-st img {
  max-width: 100%;
  height: 100px;
  border-radius: 10px;
}
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st {
  display: flex;
  flex-basis: auto;
  background: #efefef;
  padding: 0.1rem 0.5rem;
  border: 1px solid #eee;
  bottom: -9px;
  position: relative;
  min-width: 100px;
}
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-1,
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-2,
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-3 {
  text-align: center;
}
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-1 {
  flex: 1;
  color: #000;
}
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-2 {
  flex: 2;
  color: #3c57a6;
}
.inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st .inq-sp-3 {
  flex: 1;
  color: #3c57a6;
}
.inq-menu-right-st .inq-pro-hr-st {
  width: 83%;
  border: 0;
  border-bottom: 1px solid #cdcdcd;
  margin-top: 3rem;
  margin-bottom: -1rem;
}
.inq-menu-right-st .fa-rupee {
  font-size: 12.5px;
}

.inq-pro-col-le-st {
  padding-left: 0px !important;
}

.inq-news-st {
  background: #ede8ca;
  min-height: 200px;
  padding-top: 3rem;
  margin-top: 2rem;
}
.inq-news-st .inq-news-fld-st {
  position: relative;
}
.inq-news-st .inq-news-fld-st .form-control {
  height: calc(2em + 0.75rem + 2px);
  border-radius: 1.5rem;
  position: relative;
  margin-top: 0.4rem;
}
.inq-news-st .inq-news-fld-st span {
  position: absolute;
  right: 1px;
  top: 0px;
  background: #3c57a6;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 1.5rem;
}
.inq-news-st .inq-news-fld-st span .fa {
  font-size: 18px;
  color: #777;
}

.inq-foot-bg-st {
  background: rgb(34, 34, 34);
  color: #ffffff;
  padding: 2rem 0rem 0rem 0rem;
  margin-top: -5rem;
  border-radius: 20px;
}
.inq-foot-bg-st li {
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 6px;
}
.inq-foot-bg-st .inq-visi-st {
  visibility: hidden;
}
.inq-foot-bg-st .inq-foot-logo-st img {
  max-width: 100%;
}
.inq-foot-bg-st .inq-foot-links-st ul {
  display: flex;
  flex-wrap: wrap;
}
.inq-foot-bg-st .inq-foot-links-st ul li {
  flex: 1;
  flex-basis: auto;
}
.inq-foot-bg-st .inq-foot-pd-st {
  padding: 11px;
}

.inq-footql-st {
  background: #3c57a6;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 1rem;
  padding-bottom: 0.2rem;
  margin-bottom: 1.5rem;
}
.inq-footql-st p,
.inq-footql-st li {
  font-size: 13px;
}

.inq-btn-st {
  padding: 0.375rem 1.5rem !important;
}

.inq-cart-ul-st {
  border-bottom: none !important;
}
.inq-cart-ul-st li.nav-item {
  font-size: 14px;
  text-align: center;
  cursor: default;
  min-width: 150px;
  background-color: #f2f2f2;
  transition: background-color 0.2s ease;
  color: #fff !important;
  position: relative;
  padding: 0px 0px 0px 24px;
  margin: 0 4px;
}
.inq-cart-ul-st li.nav-item.current {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.current::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next1 {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next1::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next2 {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next2::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next3 {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next3::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next4 {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next4::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item.next5 {
  background: rgb(34, 34, 34);
  color: #fff !important;
}
.inq-cart-ul-st li.nav-item.next5::after {
  border-left: 18px solid rgb(34, 34, 34);
}
.inq-cart-ul-st li.nav-item::before, .inq-cart-ul-st li.nav-item::after {
  content: " ";
  position: absolute;
  top: 1px;
  right: -18px;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 18px solid #f2f2f2;
  z-index: 2;
  transition: border-color 0.2s ease;
}
.inq-cart-ul-st li.nav-item::before {
  right: auto;
  left: 0;
  border-left: 20px solid #fff;
  z-index: 0;
}
.inq-cart-ul-st li.nav-item a.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
  background: none;
  border: none !important;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  padding: 0.6rem 1rem;
}
.inq-cart-ul-st li.nav-item a.nav-link:hover {
  border-color: none;
}

.inq-order-sec-st {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.inq-pl0-st {
  padding-right: 5px !important;
}

.inq-pr0-st {
  padding-left: 5px !important;
}

.inq-order-sec2-st {
  padding-left: 30px !important;
}

.inq-order-inner-p-st p {
  padding-bottom: 10px;
  border-bottom: 1px solid #777;
}

.inq-cart-ord-ti-st {
  margin-left: -15px;
}

.inq-cart-ul-st li.nav-item.current a.nav-link {
  color: #fff !important;
}

.inq-cart-ul-st li.nav-item.next a.nav-link {
  color: #fff !important;
}

.inq-cart-dinfo-st {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.inq-cart-dinfo-st h6 {
  background: #8c945b;
  color: #fff;
  padding: 0.5rem 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: 0px !important;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st {
  background: #ede8ca;
  padding: 1rem !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .inq-pribg-st,
.inq-cart-dinfo-st .inq-cart-ddinfo-st .inq-secbg-st {
  padding: 0.2rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  color: #fff;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .inq-pribg-st {
  background: #805425;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .inq-secbg-st {
  background: #f2f2f2;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .form-group {
  margin-bottom: 0.5rem !important;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .form-group label {
  margin-bottom: 0.2rem !important;
  font-weight: 500;
  display: block;
  font-size: 13px;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .form-group small {
  font-size: 77% !important;
}
.inq-cart-dinfo-st .inq-cart-ddinfo-st .form-group .form-control {
  background: #f1efda !important;
  color: #333 !important;
}

.inq-cart-fitems-st .table th {
  border-top: none !important;
}
.inq-cart-fitems-st .inq-fimg-st {
  background: #f2f2f2;
  padding: 0.5rem;
}
.inq-cart-fitems-st .inq-fimg-st img {
  width: 100%;
}
.inq-cart-fitems-st .inq-fimg-mt1-st {
  margin-top: 1.4rem;
}
.inq-cart-fitems-st .inq-fimg-mt2-st {
  margin-top: 2.6rem;
}
.inq-cart-fitems-st .inq-amt-st.l {
  font-weight: 700;
}

.inq-news-fld-st {
  position: relative;
}
.inq-news-fld-st .form-control {
  border-radius: 1.5rem;
  position: relative;
  margin-top: 0.4rem;
  font-weight: 500;
}
.inq-news-fld-st .ind-input-abs {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
}
.inq-news-fld-st span {
  color: #fff;
  padding: 9px 30px;
  border-radius: 1.5rem;
}
.inq-news-fld-st span .fa {
  font-size: 18px;
  color: #777;
}

.inq-cart-pay-st .form-check-inline {
  background: #e8ecef;
  padding: 0.4rem 0.8rem;
}

.inq-upshell-prd-st {
  background: #c5c5c5;
  text-align: center;
  padding: 1rem;
}
.inq-upshell-prd-st .inq-upshell-prdimg-st {
  background: #e8e8e8;
  border-radius: 15px;
  padding: 2.5rem 0rem;
  height: 20rem;
}
.inq-upshell-prd-st .inq-upshell-prdimg-st img {
  max-width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.inq-upshell-prd-st .inq-upshell-wimg-st {
  background: #ede8ca;
}

.inq-pro-rating-st {
  width: 22px;
  margin-top: -4px;
}

.inq-onl-del-st {
  position: fixed;
  right: 0px;
  top: 50%;
  cursor: pointer;
  z-index: 10;
}

.inner-star-icon {
  color: #828281;
}

.mayan-inner-star-icon {
  color: #ffffff;
}

@media (min-width: 1200px) {
  .inq-sec-h-st .inq-head-col-1 {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
  }
  .inq-sec-h-st .inq-head-col-3 {
    flex: 0 0 16%;
    max-width: 16%;
  }
  .inq-sec-h-st .inq-head-col-4 {
    flex: 0 0 15.666667%;
    max-width: 15.666667%;
  }
  .inq-sec-h-st .inq-head-col-5 {
    flex: 0 0 14.333333%;
    max-width: 14.333333%;
  }
  .inq-sec-h-st .inq-head-col-5 .col-md-7 {
    line-height: 1.3;
  }
  .inq-sec-h-st .inq-head-col-5 .col-md-7 .inq-re-sr {
    font-size: 16px;
    font-weight: 500;
  }
  .inq-sec-h-st .inq-head-col-5 span {
    font-size: 13px;
  }
  .inq-sec-h-st .inq-head-col-6 {
    flex: 0 0 7.333333%;
    max-width: 7.333333%;
  }
  .inq-cart-bw-st {
    margin: -25px 15px !important;
  }
}
@media screen and (max-width: 600px) {
  .jumbotron {
    padding: 0 !important;
    padding-top: 10px !important;
    background-color: #ffffff !important;
  }
  .mobile-text {
    font-size: 20px !important;
  }
  .float-right-mob {
    float: right;
  }
  html,
  body {
    overflow-x: hidden;
  }
  .inq-sec-h-st {
    padding-top: 0.5rem;
  }
  .inq-sec-h-st .inq-logo-st img {
    max-width: 85%;
  }
  .inq-sec-h-st .inq-hlogo-st {
    flex: 0 0 36.333%;
    max-width: 36.333%;
    order: 1;
  }
  .inq-sec-h-st .col-md-4.inq-head-col-2 {
    order: 4;
    margin-top: 1rem;
  }
  .inq-sec-h-st .col-md-3.inq-head-col-3 {
    flex: 0 0 36.333%;
    max-width: 36.333%;
    text-align: center;
    order: 2;
  }
  .inq-sec-h-st .col-md-1.inq-head-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
    order: 5;
    margin-top: 1rem;
  }
  .inq-sec-h-st .col-md-1.inq-head-col-5 .col-md-5 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .inq-sec-h-st .col-md-1.inq-head-col-5 .col-md-7 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .inq-sec-h-st .col-md-1.inq-head-col-5 .inq-re-sr {
    display: block;
  }
  .inq-sec-h-st .inq-prof-st {
    flex: 0 0 50%;
    max-width: 50%;
    order: 6;
    margin-top: 1rem;
  }
  .inq-sec-h-st .inq-prof-st .col-md-4 {
    flex: 0 0 40%;
    max-width: 40%;
    order: 2;
    text-align: center;
    padding-right: 0px;
  }
  .inq-sec-h-st .inq-prof-st .col-md-8 {
    flex: 0 0 60%;
    max-width: 60%;
    order: 1;
    padding-right: 0px;
    position: relative;
    right: -20px;
    text-align: end;
    top: 3px;
  }
  .inq-sec-h-st .col-md-1.inq-head-col-6 {
    order: 2;
    flex: 0 0 27.333%;
    max-width: 27.333%;
  }
  .inq-sec-h-st .inq-cimg-st img {
    max-width: 50%;
  }
  .inq-sec-h-st .inq-cimg-st span {
    right: 25px;
    font-size: 13px;
  }
  .inq-sec-h-st .inq-limg-st img {
    max-width: 50%;
    text-align: center !important;
  }
  .inq-sec-h-st .inq-rimg-st img {
    max-width: 85%;
    text-align: center !important;
  }
  .inq-sec-h-st .inq-rimg-st span {
    top: 7px;
    right: 14px;
  }
  .inq-sec-h-st .inq-rev-st {
    justify-content: center !important;
  }
  .inq-sec-h-st .inq-rev-st .col-md-4 {
    flex: 0 0 45%;
    max-width: 45%;
    order: 1;
  }
  .inq-sec-h-st .inq-rev-st .col-md-8 {
    flex: 0 0 55%;
    max-width: 55%;
    order: 2;
    padding-left: 6px;
    padding-right: 6px;
  }
  .inq-sec-h-st .inq-rev-st .col-md-8 .fa-star {
    font-size: 13px;
  }
  .inq-sec-h-st .inq-rev-st .col-md-8 .inq-rev-txt-st h4 {
    font-size: 1rem;
  }
  span.pl-1 {
    display: none;
  }
  .inq-menu-st .navbar {
    justify-content: space-around;
  }
  .inq-menu-right-st .inq-fo-im-st {
    text-align: center;
  }
  .inq-menu-right-st .inq-fo-im-st img {
    width: 50%;
  }
  .inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st {
    margin: -25px 120px;
  }
  .inq-menu-right-st .inq-pro-hr-st {
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: -1rem;
  }
  .inq-news-st {
    background: #ede8ca;
    min-height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .text-uppercase {
    text-align: center;
  }
  .inq-foot-bg-st {
    margin-top: 0rem;
    border-radius: 0px;
  }
  footer .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .inq-foot-logo-st,
  .inq-foot-pd-st {
    text-align: center;
  }
  .inq-mt-st {
    margin-bottom: 4rem;
  }
  .inq-menu-left-st {
    border-right: none;
    padding-right: 0rem;
    overflow: scroll;
    margin-bottom: 1rem;
  }
  .inq-menu-left-st ul li {
    padding-left: 1rem;
    text-align: left;
  }
  .inq-hr1-st {
    margin-bottom: 0.5rem;
  }
  figure {
    margin: 0 0 0rem;
  }
  .inq-htop-st {
    margin-top: 20px;
  }
  .inq-htop-st .fa {
    right: 12px;
    top: 10px;
  }
  .inq-prof-st .row {
    justify-content: center;
  }
  .inq-footql-st {
    margin-bottom: 0rem;
  }
  .inq-order-inner-p-st {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .inq-cart-ord-ti-st {
    margin-left: 0px;
  }
  .inq-cart-dinfo-st {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .inq-upshell-prd-st {
    margin-bottom: 1rem;
  }
  .inq-upshell-prd-st .inq-upshell-prdimg-st {
    height: 25rem;
  }
  .inq-pro-col-le-st,
  .inq-fo-des-in-st {
    padding-left: 15px !important;
  }
  .inq-com-pd-st {
    padding-left: 15px;
    padding-right: 15px;
  }
  .inq-mch-con-btn-st {
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
  }
  .inq-upshell-wimg-st p {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }
  .inq-cart-ul-st li.nav-item {
    margin-bottom: 1rem;
  }
  .inq-order-sec2-st {
    padding-left: 15px !important;
  }
  .inq-cart-pay-st .form-check-inline {
    width: 46%;
    margin-bottom: 1rem;
  }
  .inq-mc-ordn-st {
    margin-top: 2rem !important;
  }
  .inq-mc-ordn1-st {
    margin-top: 1rem !important;
  }
  .inq-Caro-st {
    height: 24vh;
  }
  .inq-Caro-st .w-100 {
    height: 24vh;
  }
  .inq-Caro-st .inq-sli-btn-st {
    left: 23%;
    bottom: 9px;
  }
  .inq-Caro-st button.btn.btn-primary.inq-btn-st {
    padding: 2px 5px !important;
    font-size: 6px;
    margin-top: 13px;
  }
  .inq-pr0-st {
    padding-left: 15px !important;
  }
  .inq-pl0-st {
    padding-right: 15px !important;
  }
}
.inq-hmid1-st .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
}

.inq-hmid1-st .nav-tabs .dropdown-menu {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
}

.inq-hmid1-st .nav-tabs .dropdown-menu a.dropdown-item {
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 500;
}

@media screen and (max-width: 767px) and (min-width: 481px) {
  .inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st {
    width: 150px !important;
  }
  .inq-Caro-st .inq-sli-btn-st {
    position: absolute;
    bottom: 8% !important;
    left: 16% !important;
  }
}
@media screen and (max-width: 767px) {
  .owl-theme .owl-nav [class*=owl-] {
    border-radius: 30px !important;
    padding: 8px 31px !important;
    border: transparent;
  }
}
.owl-theme .owl-dots .owl-dot {
  display: none !important;
}

.owl-theme .owl-nav [class*=owl-] {
  border-radius: 30px !important;
  padding: 8px 13px;
  border: 1px solid #b7b6b5;
}

@media screen and (max-width: 1024px) {
  .inq-Caro-st .inq-sli-btn-st {
    position: absolute;
    bottom: 20%;
    left: 24%;
    top: 10%;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .inq-sli-btn-st h3 {
    margin: 0;
    font-size: 26px;
    line-height: 33px;
    font-weight: 700;
  }
  .inq-Caro-st .inq-sli-btn-st p {
    font-size: 20px;
  }
  .inq-Caro-st .inq-sli-btn-st p span {
    font-size: 20px;
    color: #3c57a6;
  }
  .mob-btn {
    transform: scale(0.7);
    margin-left: 0px !important;
  }
  .mob-btn-posi {
    position: relative;
    left: 45%;
    top: -2.3rem;
  }
  .mob-btn-posi-bh {
    position: relative;
    left: 10%;
  }
}
@media (max-width: 391px) {
  .mob-btn-posi-bh {
    left: 60%;
    top: -2.3rem;
  }
}
@media screen and (max-width: 500px) {
  .inq-Caro-st .w-100 {
    height: auto;
  }
  .inq-sli-btn-st h3 {
    margin: 0;
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
  }
  .inq-Caro-st .inq-sli-btn-st p {
    font-size: 15px;
  }
  .inq-Caro-st .inq-sli-btn-st p span {
    font-size: 15px;
  }
  .inq-Caro-st .inq-sli-btn-st {
    left: 14%;
  }
  .inq-Caro-st button.btn.btn-primary.inq-btn-st {
    padding: 2px 5px !important;
    font-size: 6px;
    margin-top: -18px;
  }
  .inq-Caro-st {
    height: auto;
  }
}
main {
  background-color: #ffffff;
}

.footer-murugesan {
  border: 1px solid #d2232a !important;
  color: #d2232a !important;
  background-color: #a1a1a1 !important;
}

.footer-mayan {
  border: 1px solid #3c707c !important;
  color: #3c707c !important;
  background-color: #C2C1BD !important;
}

ngb-rating {
  color: goldenrod;
}

.menu-text {
  color: #3c57a6 !important;
  background-color: transparent !important;
}

.global-search::-ms-clear {
  display: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.inq-sec-h-fld-st {
  width: 100%;
  margin-left: 5%;
}

.inq-sec-h-fld-st span {
  position: absolute;
  right: 6%;
  top: 6px;
}

.inq-sec-h-fld-st span .fa {
  font-size: 18px;
  color: #777;
  cursor: pointer;
}

.inq-sec-h-st .inq-sec-h-fld-st {
  position: relative;
}

.inq-sec-h-fld-st .form-control {
  height: calc(2em + 0.75rem + 2px);
  border-radius: 1.5rem;
  position: relative;
  margin-top: 0.4rem;
  width: 100%;
}

.inq-sec-h-st .inq-sec-h-fld-st span {
  position: absolute;
  right: 17px;
  top: 10px;
}

.aiema-logo {
  margin-left: 40px;
  object-fit: contain;
}

.cle-logo {
  margin-left: 1%;
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .flex-container {
    margin-left: 0px;
  }
  .aiema-logo {
    transform: scale(0.75);
    top: 0px !important;
    left: 40%;
    position: fixed;
    margin-left: 0px;
    z-index: 3;
    object-fit: contain;
  }
  .cle-logo {
    transform: scale(0.75);
    top: 0px !important;
    left: 40%;
    position: fixed;
    margin-left: 0px;
    z-index: 3;
    object-fit: contain;
  }
  .logo-image {
    max-height: 50px;
  }
  .logo-karupatti {
    max-height: 50px;
    margin-left: 1px !important;
  }
  .logo-imagekub {
    height: 40px;
    margin-top: -10px;
  }
  #secondpart {
    margin-left: -11% !important;
    padding-top: 20px;
  }
  .inner-cart p {
    left: 28px !important;
  }
  #navigtion {
    margin-top: 35px !important;
  }
  #reward-prt {
    padding: 8px 0px 0px 10px !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 577px) {
  .inner-review p {
    left: 16% !important;
  }
  #secondpart {
    margin-left: -6% !important;
    padding-top: 25px;
  }
  #navigtion {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .inner-review p {
    left: 21% !important;
  }
  #secondpart {
    margin-left: -6% !important;
    padding-top: 20px;
  }
}
.flex-container-large {
  display: flex;
  justify-content: space-around;
}

.dropdown-toggle .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
  display: inline-block;
  vertical-align: middle;
}

.inner-review img {
  position: relative;
  max-width: 74%;
  padding-top: 7px;
}

.inner-review p {
  left: 18%;
  position: absolute;
  top: 17%;
}

.inner-review {
  position: relative;
}

.checked {
  color: #fdb915;
}

.unchecked {
  color: #f7e09c;
  font-size: 12px;
}

.line {
  line-height: 0.2;
}

.inner-chef img {
  max-height: 82%;
}

.log-prt p:first-child {
  font-weight: 500;
}

.log-prt {
  line-height: 1.3;
  font-size: 13px;
}

.log-prt p {
  font-size: 14px;
  color: #828281;
}

.inner-cart {
  position: relative;
}

.inner-cart p {
  position: absolute;
  left: 24px;
}

.inner-cart img {
  position: absolute;
  max-height: 85%;
}

.line[_ngcontent-mnf-c20] h4 {
  margin-bottom: 0;
}

.show-subtotal {
  margin: auto;
  padding-left: 45px;
  font-size: small;
}

.ng-select .ng-select-container {
  border-radius: 30px !important;
}

.cart-link:hover {
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  width: 65%;
  border-top: none;
  z-index: 99;
  font-size: 13px !important;
  /*position the autocomplete items to be the same width as the container:*/
  top: 80%;
  left: 150px;
  right: 0;
  max-height: 400px;
  overflow: auto;
  border-radius: 15px;
}

.autocomplete-items li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px !important;
}

.autocomplete-items li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: #3c57a6 !important;
  color: #ffffff;
}

.titleproper {
  text-transform: lowercase;
}

.titleproper:first-line {
  text-transform: capitalize;
}

.cart-link:hover {
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #ffffff;
  padding-top: 2px;
}

.logo-header-image {
  object-fit: contain;
  width: 125px;
  height: 80px;
  margin-left: 40px;
}

.header-avatar {
  color: #494949 !important;
  cursor: pointer;
}

.signin-signup {
  color: #494949 !important;
  padding-top: -5px;
  padding-left: 2px;
  cursor: pointer;
}

.counting {
  color: #3c57a6 !important;
  position: absolute;
  background: #DC6464;
  width: 27px;
  height: 20px;
  border-radius: 9px;
  top: -1%;
  margin-top: 7px;
}

.counting1 {
  color: #3c57a6 !important;
  position: absolute;
  background: #DC6464;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  top: -13%;
  margin-left: -5%;
}

#murugesan .nav-link {
  color: #be7c12 !important;
  font-weight: bold !important;
}
#murugesan .nav-link:active {
  text-decoration: #D4242C;
}

.topcategory-img {
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge #D4242C;
  width: 130px;
  height: 130px;
  padding: 10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.mayantopcategory-img {
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge #3c57a6;
  width: 150px;
  height: 150px;
  padding: 10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
  object-fit: cover;
}

.mayanLargetopcategory-img {
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge #3c57a6;
  width: 200px;
  height: 200px;
  padding: 10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.mayantopcategory1-img {
  display: block;
  position: relative;
  margin: 0 auto 5px;
  overflow: hidden;
  width: 15px;
  height: 90px;
  padding: 10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.topcategory-text {
  margin: 0;
  text-transform: uppercase;
  color: #222;
  background-color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.8px;
  position: relative;
  z-index: 1;
  white-space: normal;
}

.topcategory-img:hover {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  width: 135px;
  height: 135px;
}

.mayantopcategory-img:hover {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  width: 205px;
  height: 205px;
}

.top-image-cover {
  padding-right: 35px;
}

.categoryList:hover {
  color: #fbcb0c;
}

.image-container-top {
  margin: 50px 0px !important;
}

.welcom-text {
  color: #be7c12;
  font-size: 2rem;
  font-weight: 800;
  padding-bottom: 15px;
}

.mayanwelcom-text {
  color: #3c57a6;
  font-size: 2rem;
  font-weight: 800;
  padding-bottom: 15px;
}

.card-hidden {
  display: none !important;
  height: 0px !important;
  width: 0px !important;
}

@media screen and (max-width: 600px) {
  .mayanwelcom-text {
    font-size: 1.5rem !important;
  }
  .l-5px {
    left: -5px !important;
    transform: translate3d(-111px, 27px, 0px) !important;
  }
}
.inq-home-about-st h5::after {
  content: "";
  display: block;
  height: 5px;
  background: #3c57a6;
  width: 50px;
  position: absolute;
  top: 2.75em;
  left: 0;
  right: 0;
  transition: transform ease-in-out 250ms;
  margin: 0 auto;
}

.home-page .card {
  border: none;
}

.partners_img {
  border: 1px solid hsl(0deg, 0%, 90%);
  height: 100%;
  width: 100%;
  transition: all 0.3s ease;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .inq-title-st {
    margin: 0 0 0 13px;
  }
}
@media screen and (max-width: 480px) {
  #innerpartFront {
    padding: 1rem 0rem !important;
  }
}
@media screen and (min-width: 1900px) {
  #innerpart {
    height: 350px !important;
  }
}
.carouselStyling {
  top: 50%;
  left: 50%;
  min-width: 100%;
  height: 100%;
}

.cardBox {
  float: left;
  font-size: 1.2em;
  margin: 1% 0 0 1%;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 89%;
}

.cardBox:hover .card {
  transform: rotateY(180deg);
}

.cardBox:hover .back {
  z-index: 99;
}
.cardBox:hover .front {
  z-index: 1;
  display: none;
}

.cardBox:not(:hover) .back {
  z-index: 0;
}
.cardBox:not(:hover) .front {
  z-index: 99;
}

@media screen and (min-width: 1200px) {
  .card {
    height: 420px;
  }
  .cardBox {
    width: 89%;
  }
  #innerpartFront {
    padding: 2.5rem 0rem;
  }
}
.card {
  background: transparent !important;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
  border: none;
}

.havencard {
  background: transparent !important;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
}

.cardtext {
  background: transparent !important;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.cardtext1 {
  background: transparent !important;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

#innerpart {
  background-color: #EDE8CA;
  border-radius: 30px;
  padding: 4rem 0rem;
}
#innerpart .inq-cart-bw-st {
  border: none !important;
  background-color: transparent !important;
}
#innerpart .inq-cart-bw-st i {
  background-color: transparent;
}

#innerpartFront {
  background-color: #E8E8E8;
  border-radius: 30px;
  padding: 2.5rem 0rem;
}

#innerpartFront img {
  max-width: 100%;
  padding: 0.5rem;
}

.front p {
  color: #323232 !important;
}

.front h5 {
  color: #323232 !important;
  font-size: 17.5px;
}

.checkout {
  color: #FFFCF8;
  font-size: 13px;
  position: absolute;
  padding: 11px 22px;
  text-transform: uppercase;
  text-decoration: none;
  top: 28%;
  left: 28%;
  transform: translate(-28%, -28%);
  border-radius: 28px;
  background: #805245;
  border: 1px solid #805245;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin: 0;
}

.faq-padding {
  padding: 30px;
  padding-left: 18%;
}

.sizechart-padding {
  padding: 40px 80px;
}

@media screen and (max-width: 786px) {
  .faq-padding {
    padding: 30px;
    padding-left: 30px;
    width: 100%;
  }
  .cardtext1 {
    width: 100%;
  }
  .sizechart-padding {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 480px) {
  .checkout {
    padding: 6px 11px;
    font-size: 10px;
    position: relative;
    bottom: 3px;
    left: 11%;
  }
}
.checkout a {
  text-decoration: none;
  color: #FFFCF8;
}

.card .front {
  box-sizing: border-box;
  color: white;
  display: block;
  height: 100%;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.havencard .front {
  box-sizing: border-box;
  color: white;
  display: block;
  height: 100%;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  #innerpart {
    height: 350px !important;
  }
  #innerpartFront {
    height: 350px !important;
  }
}
.card .back {
  box-sizing: border-box;
  color: white;
  display: block;
  font-size: 1.2em;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  transform: perspective(200px) rotateY(180deg);
}

.havencard .back {
  box-sizing: border-box;
  color: white;
  display: block;
  font-size: 1.2em;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  transform: perspective(200px) rotateY(180deg);
}

.card .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.havencard .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.card .back button {
  background: #3c57a6 !important;
  border: 1px solid #3c57a6 !important;
  border-radius: 20px !important;
  color: white;
  padding: 0.375rem 1.5rem !important;
}

.havencard .back button {
  background: #3c57a6 !important;
  border: 1px solid #3c57a6 !important;
  border-radius: 20px !important;
  color: white;
  padding: 0.375rem 1.5rem !important;
}

/* .card .back a:hover {
  background: #fff;
  color: #333;
  text-shadow: 0 0 1px #333;
} */
.textblack {
  color: #333 !important;
  font-size: 14px;
  line-height: 1.7;
  padding-left: 6px;
  padding-right: 6px;
}

.textblack1 {
  font-size: 18px;
  color: #333 !important;
}

.textbrrown {
  color: #3c57a6;
  font-weight: bold;
}

.cardBox:nth-child(2) .card {
  -webkit-animation: giro 1.5s 1;
  animation: giro 1.5s 1;
}

.cardBox:nth-child(3) .card {
  -webkit-animation: giro 2s 1;
  animation: giro 2s 1;
}

.cardBox:nth-child(4) .card {
  -webkit-animation: giro 2.5s 1;
  animation: giro 2.5s 1;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 768px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 90%;
  }
  .card {
    height: 385px;
  }
  .havencard {
    height: 385px;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
}
@media screen and (max-width: 1199px) and (min-width: 769px) {
  .card {
    height: 385px;
  }
  .havencard {
    height: 385px;
  }
  #innerpart {
    padding: 10px;
  }
}
@media screen and (max-width: 480px) {
  .cardBox {
    width: 94.5%;
  }
  .card {
    height: 345px;
  }
  .havencard {
    height: 345px;
  }
}
.innerpart {
  padding: 30px;
}

.bag {
  left: 17%;
}

.static-image {
  right: 0%;
  margin-top: 75px;
}

.rounder-corner-nav .owl-theme .owl-nav {
  margin-top: 0px;
}

.rounder-corner-nav .owl-theme .owl-nav {
  margin-top: 0px;
  margin-left: 2%;
}

.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.rounder-corner-nav .owl-theme .owl-nav .owl-prev, .rounder-corner-nav .owl-theme .owl-nav .owl-next {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  border: 2px solid #b7b6b5;
}

.inq-sp-1 i {
  font-size: 12px !important;
  background: #333;
  color: #fff;
  padding: 3px;
  border-radius: 25px;
  width: 18px;
  height: 18px;
}

.inq-sp-3 i {
  font-size: 12px !important;
  background: #333;
  color: #fff;
  padding: 3px;
  border-radius: 25px;
  width: 18px;
  height: 18px;
}

.inq-sp-1 i:hover, .inq-sp-3 i:hover {
  background: #3c57a6;
  color: #fff;
  cursor: pointer;
}

.quantity-btn {
  background-color: #3c57a6;
  color: #ffffff;
  margin-left: 20%;
  width: 60%;
  border-radius: 10px;
}
.quantity-btn i {
  background: #3c57a6;
  color: #ffffff;
}
.quantity-btn .inq-sp-1 i:hover, .quantity-btn .inq-sp-3 i:hover {
  background: #3c57a6;
  color: #fff;
  cursor: pointer;
}

.tabs-vim {
  position: relative;
  margin: 3rem 0;
  background: white;
  display: flex;
  justify-content: center;
  height: 2.75rem;
}

.tabs-vim::before,
.tabs-vim::after {
  content: "";
  display: table;
}

.tabs-vim::after {
  clear: both;
}

.tab-vim {
  float: left;
}

.tab-switch-vim {
  display: none;
}

.tab-label-vim {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: white;
  border-right: 0.125rem solid black;
  color: black;
  cursor: pointer;
  top: 0;
  transition: all 0.25s;
}

.tab-label-vim:hover {
  top: -0.25rem;
  transition: top 0.25s;
  color: #b73341;
}

.tab-content-vim {
  position: absolute;
  z-index: unset;
  left: 0;
  padding: 1.618rem;
  background: #fff;
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
  transition: all 0.35s;
}

.tab-switch-vim:checked + .tab-label-vim {
  color: #ed98d2;
  border-bottom: 0;
  transition: all 0.35s;
  z-index: unset;
  top: -0.0625rem;
}

.tab-switch-vim:checked + label + .tab-content-vim {
  z-index: unset;
  opacity: 1;
  transition: all 0.35s;
  width: 100%;
}

.product-card {
  display: none;
}

.tams-card {
  display: none;
}

.online-image {
  width: 0px;
  height: 0px;
}

.online-image1 {
  width: 190px;
  height: 190px;
}

.murugesan-card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  min-height: 353px !important;
  max-height: 353px !important;
  border-radius: 5px;
  z-index: 99 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition: min-height 0.4s;
  transition-timing-function: ease;
}

.murugesan-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  min-height: 358px !important;
  max-height: 358px !important;
}
.murugesan-card:hover .murugasen-list-image {
  min-height: 205px !important;
  max-height: 205px !important;
  width: 100% !important;
}

.murugesan-card::before {
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  content: "";
  background: #e9343d;
  height: 28px;
  width: 28px;
  transform: rotate(45deg);
  z-index: -1 !important;
}

.murugesan-card::after {
  position: absolute;
  content: attr(data-label);
  top: 16px;
  right: -14px;
  padding: 3px;
  width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  background: #e9343d;
  color: white;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.murugesan-card-title {
  font-size: 17px;
  color: rgb(34, 34, 34);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
}

.murugesan-card-title1 {
  font-size: 17px;
  color: rgb(34, 34, 34);
  font-weight: 400;
}

.murugesan-description {
  padding: 10px 10px;
  padding-bottom: 0px !important;
}

.murugasen-list-image {
  min-height: 200px !important;
  max-height: 200px !important;
  width: 100% !important;
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.murugesan-price {
  padding-top: 3px;
  font-size: 25px;
  color: #000;
  font-weight: 500;
}

.murugesan-addcart {
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  border: none;
  background-color: #e9343d;
  color: white;
}

.murugesan-card1 {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  min-height: 353px !important;
  max-height: 353px !important;
  border-radius: 5px;
  z-index: 99 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition: min-height 0.4s;
  transition-timing-function: ease;
}

.murugesan-card1:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  min-height: 358px !important;
  max-height: 358px !important;
}
.murugesan-card1:hover .murugasen-list-image {
  min-height: 205px !important;
  max-height: 205px !important;
  width: 100% !important;
}

.img-with-text {
  text-align: center;
  width: 100%;
}

.img-with-text img {
  display: block;
  margin: 0 auto;
  position: relative;
}

.testimonial {
  padding: 80px 0;
}

.sec-heading {
  margin-bottom: 60px;
}

.sec-heading h6 {
  font-family: allura;
  font-weight: 900;
  font-size: 80px;
}

.single-box {
  border: 3px solid #e6f0fa;
  padding: 50px 30px 40px;
  border-radius: 20px;
  background: #fff;
}

.img-area {
  margin: 45px 0 20px;
}

.single-box img {
  max-width: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.single-box h4 {
  font-weight: 600;
  margin: 0;
  font-family: "Allura", cursive;
  font-size: 35px;
}

.single-box {
  overflow: hidden;
}

.single-box .img-area {
  width: 30%;
  float: left;
  margin: 0;
}

.single-box .content {
  width: 70%;
  float: left;
  padding-left: 10px;
}

.single-box p {
  margin: 10px 0 25px;
  line-height: 2.3;
}

.testi-carousel-three .single-box {
  border: 0;
  padding: 0 50px;
}

.testi-carousel .owl-dots, .clients-carousel .owl-dots, .testi-carousel-three .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  text-align: center;
  width: 100%;
}

.testi-carousel .owl-dot, .clients-carousel .owl-dot, .testi-carousel-three .owl-dot {
  width: 16px;
  height: 16px;
  background-color: #ddd;
  display: inline-block;
  margin: 0 6px;
  text-align: center;
  border-radius: 50%;
}

.testi-carousel .owl-dot.active, .clients-carousel .owl-dot.active, .testi-carousel-three .owl-dot.active {
  background-color: blueviolet;
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .testimonial {
    padding: 70px 0 130px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 359px) {
  .testimonial {
    padding: 70px 0 130px;
  }
  .single-box {
    padding: 50px 0 40px;
  }
  .single-box .img-area {
    width: 100%;
    float: none;
  }
  .single-box .content {
    width: 100%;
    float: none;
  }
}
@media (max-width: 1920px) {
  .bestgifts-arrival {
    justify-content: center;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .mayan_ftre_name {
    margin-left: 0px;
  }
  .bestgifts-arrival {
    justify-content: center;
  }
}
.home-card {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.text-centre {
  border: 1px solid #3c57a6;
}

.card-heading2 {
  height: 50px;
  background-color: #1e1e73;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  font-size: 14px !important;
}

.card-heading {
  height: 50px;
  background-color: #1e1e73;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  font-size: 14px !important;
}

.home-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.home-container3 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.home-container7 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.home-container4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.home-container5 {
  display: grid;
  height: 300px;
  grid-template-columns: repeat(6, 1fr);
}

.home-container2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 5%;
}

.home-containeravon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 5%;
}

@media screen and (max-width: 600px) {
  .resp_bt {
    width: 50%;
  }
  .home-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-container3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-container7 {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-container4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-container5 {
    margin-left: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
  .home-container2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-containeravon {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-heading {
    font-size: 12px !important;
  }
  .card-heading2 {
    font-size: 12px !important;
  }
}
.isempty:empty {
  display: none;
}

.isempty:empty {
  display: none;
}

@media only screen and (min-width: 1300px) {
  .owl-theme .owl-nav {
    position: relative;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
  }
}
.display_flex {
  display: flex;
}

.inq-foot-links-st .pointer {
  cursor: pointer;
}
.inq-foot-links-st .pointer:hover {
  font-weight: bold;
}
.inq-foot-links-st a {
  color: #ffffff !important;
}

.poweredbylogo {
  position: relative;
  top: 58px;
  width: 9%;
  float: right;
  margin-top: -4%;
  margin-right: 5%;
  object-fit: contain;
}

.pwrdby {
  position: relative;
  top: 79px;
  right: 100px;
  width: 9%;
  float: right;
  margin-top: -4%;
  margin-right: 5%;
  object-fit: contain;
}

.poweredbylogo1 {
  position: relative;
  top: 58px;
  width: 9%;
  float: right;
  margin-top: -4%;
  margin-right: 5%;
  object-fit: contain;
}

.pwrdby1 {
  position: relative;
  top: 65px;
  right: 100px;
  width: 9%;
  float: right;
  margin-top: -4%;
  margin-right: 4%;
  object-fit: contain;
}

.pwrdby2 {
  position: relative;
  top: 70px;
  right: 100px;
  width: 9%;
  float: right;
  margin-top: -4%;
  margin-right: 5%;
  object-fit: contain;
}

@media (min-width: 1901px) {
  .pwrdby1 {
    position: relative;
    top: 73px;
    right: 100px;
    width: 8%;
    float: right;
    margin-top: -3.5%;
    margin-right: 5%;
    object-fit: contain;
  }
  .poweredbylogo1 {
    position: relative;
    top: 77px;
    width: 7%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;
  }
}
@media screen and (max-width: 600px) {
  .poweredbylogo {
    position: relative;
    height: 70;
    top: 100px;
    right: 90px;
    min-width: 22%;
    min-height: 20%;
    width: 16%;
    margin-top: -13%;
    margin-right: 0%;
  }
  .pwrdby {
    position: relative;
    top: 99px;
    white-space: nowrap;
    right: 196px;
    width: 16%;
    margin-top: -13%;
    margin-right: 0%;
  }
  .poweredbylogo1 {
    position: relative;
    height: 70;
    /* top: 90px; */
    right: 52px;
    min-width: 22%;
    min-height: 20%;
    width: 28%;
    margin-top: -28%;
    margin-right: 0%;
  }
  .pwrdby1 {
    position: relative;
    top: 49px;
    white-space: nowrap;
    right: 196px;
    width: 16%;
    margin-top: -10%;
    margin-right: 0%;
    font-size: 14px;
  }
  .pwrdby2 {
    position: relative;
    top: 45px;
    white-space: nowrap;
    right: 196px;
    width: 16%;
    margin-top: -13%;
    margin-right: 0%;
  }
}
.faq-page .accordion {
  background-color: #eee;
  color: #333333;
  border: 1px solid #777;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}
.faq-page .active, .faq-page .accordion:hover {
  background-color: #ccc;
}
.faq-page .accordion:after {
  content: "+";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.faq-page .active:after {
  content: "−";
}
.faq-page .panel {
  padding: 0 18px;
  background-color: #ffffff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.faq-page form.example {
  margin: 0 auto;
  max-width: 600px;
  padding: 4px;
}
.faq-page form.example input[type=text] {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  padding: 10px;
  border: 1px solid #777;
  float: left;
  width: 80%;
  background: #ffffff;
  border-radius: 10px;
}
.faq-page form.example::after {
  content: "";
  clear: both;
  display: table;
}
.faq-page form.example1 {
  margin: 0 auto;
  max-width: 700px;
  padding: 4px;
  height: 300px;
  min-width: 700px;
}
.faq-page form.example1 input[type=text] {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  padding: 10px;
  border: 1px solid #777;
  float: left;
  width: 80%;
  background: #ffffff;
  border-radius: 10px;
}
.faq-page form.example1::after {
  content: "";
  clear: both;
  display: table;
}
@media (min-width: 1300px) {
  .faq-page .container {
    max-width: 1000px;
  }
}

.paddingTB60 {
  padding: 30px 0px 10px 0px;
}

.gray-bg {
  background: #F1F1F1 !important;
}

.about-title h1 {
  color: #535353;
  font-size: 45px;
  font-weight: 600;
}

.about-title h3 {
  color: #535353;
  font-size: 23px;
  margin-bottom: 24px;
}

.about-title p {
  color: #7a7a7a;
  line-height: 1.8;
  margin: 0 0 15px;
}

.about-paddingB {
  padding-bottom: 12px;
}

.about-img {
  padding-left: 57px;
}

.aboutus-container {
  background-color: #ffffff;
}

.aboutus-title {
  color: #CC5500 !important;
}

.my-pagination ::ng-deep .ngx-pagination .current {
  background: rgb(34, 34, 34);
  margin-top: 14px;
}

#veg-img-prt {
  margin-right: -8px !important;
}

.inq-sp-1 i {
  font-size: 12px !important;
  background: #333;
  color: #fff;
  padding: 3px;
  border-radius: 25px;
  width: 18px;
  height: 18px;
}

.product-lisitng .inq-cart-bw-st {
  width: fit-content;
}
.product-lisitng .inq-sp-2 {
  padding: 0px 18px;
}

#productRow {
  padding-top: 20px !important;
}

#totalItems {
  font-size: 14px;
  margin-top: 10px;
}

#productListHead {
  margin-bottom: 2px !important;
}

.clelistProduct {
  cursor: pointer;
  margin: 15px 10px;
}

.breadCrumbListing {
  margin-bottom: 5px !important;
}

@media screen and (max-width: 600px) {
  .breadCrumbListing {
    padding-left: 10px;
  }
}
@media screen and (min-width: 640px) {
  .left_off_container {
    padding-left: 0px !important;
    margin-left: 0px !important;
    max-width: 100% !important;
  }
}
.listProduct {
  cursor: pointer;
  margin: 15px 10px !important;
}

.listProductkbook {
  cursor: pointer;
  margin: 0px 0% !important;
}

.clelistProduct {
  cursor: pointer;
  margin: 0px 0px !important;
}

.inq-sp-3 i {
  font-size: 12px !important;
  background: #333;
  color: #fff;
  padding: 3px;
  border-radius: 25px;
  width: 18px;
  height: 18px;
}

.inq-sp-1 i:hover, .inq-sp-3 i:hover {
  background: #3c57a6;
  color: #fff;
  cursor: pointer;
}

.wishlist-heart {
  float: right;
  color: #375673;
}

#img-list {
  cursor: pointer;
  object-fit: contain;
  display: block;
  margin: auto;
}

.mayan_hide {
  display: none;
}

#mydiv:hover + .mayan_hide {
  display: block !important;
  width: 98%;
  height: 11%;
  border-radius: 10px;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1254901961);
}

#img-list-vimpro {
  cursor: pointer;
  object-fit: cover;
}

.img-fluid1 {
  width: 350px;
  height: 375px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.productTitleList {
  transform: translate3d(0px, 0px, 0px);
}

.product-image {
  width: 250px;
  max-width: 100%;
  height: 200px;
  max-height: 100%;
  overflow: hidden;
}

.product-image-shubhcards {
  width: 300px;
  max-width: 100%;
  height: 200px;
  max-height: 100%;
  overflow: hidden;
}

#priceListing {
  color: #212529 !important;
}

.add-cart {
  border-radius: 5px;
  background-color: #ffffff !important;
}

.review-productList {
  color: #373b3f !important;
  font-size: 11px !important;
}

.rating {
  color: #373b3f !important;
  font-size: 11px !important;
}

#pagination-productList {
  font-size: 14px;
  float: right;
}

.add-cart1 {
  color: #ffffff;
  border-radius: 5px;
}
.add-cart1 .inq-cart-bw-st {
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: rgb(34, 34, 34) !important;
}

.inq-cart-bw-st {
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: #ffffff !important;
}

.add-cart:hover {
  cursor: pointer;
}

.home:hover {
  cursor: pointer;
}

@media (min-width: 576px) {
  .img-fluid {
    height: 250px;
  }
}
@media (max-width: 767.98px) {
  .img-fluid {
    height: 230px;
  }
}

.mobile-cart {
  display: none !important;
}

.mobile-only {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .mobile-cart {
    display: flex !important;
    float: right;
  }
  .listProduct {
    cursor: pointer;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .listProductkbook {
    cursor: pointer;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .desktop-carousel {
    display: none !important;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
  .mobile-options {
    position: absolute !important;
    margin-top: -62px !important;
    margin: 0;
    margin-left: 160px;
    left: 0;
    border: none !important;
  }
  .mob-eye {
    margin-left: 40px;
  }
  .mob-second {
    margin-top: -31px;
  }
  .forgot-link {
    margin-top: -26px !important;
  }
  .scale-9 {
    transform: scale(0.9);
  }
  .scale-8 {
    transform: scale(0.8);
  }
  .earthy-card {
    margin: 15px !important;
  }
}
.product-listing-card {
  background-color: #f3f2f8;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product-list-cards {
  background-color: #f3f2f8;
  border: none;
  border-radius: 10px;
  width: 100%;
  min-height: 400px !important;
  max-height: 600px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}

.tams-product-list-cards {
  background-color: white;
  border: none;
  border-radius: 10px;
  width: 90%;
  min-height: 356px !important;
  max-height: 600px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}

.product-list-cards1 {
  border: none;
  border-radius: 10px;
  min-width: 300px !important;
  max-width: 300px !important;
  min-height: 400px !important;
  max-height: 600px !important;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
  padding-top: 30px;
}

.product-list-cards-deera {
  border: none;
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
  min-height: 560px;
  max-height: 500px;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
  padding-top: 30px;
}

.product-list-cards-avonseals {
  border: none;
  border-radius: 10px;
  min-width: 80%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
}

.product-list-cards-vistas {
  border: none;
  border-radius: 10px;
  min-width: 80%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
}

.product-list-cards-cle {
  border: none;
  border-radius: 10px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
  margin-left: -20px;
}

.product-list-cards-avonseals1 {
  margin-left: 10%;
  min-height: 90%;
  min-width: 75%;
  max-width: 85%;
  margin-left: 10%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2), 0 4px 14px rgba(0, 0, 0, 0.1882352941);
  max-height: 100%;
}

@media (max-width: 1920px) {
  .product-list-cardskub {
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
  .bestgifts-list-cards {
    border: none;
    border-radius: 10px;
    min-width: auto !important;
    max-width: auto !important;
    min-height: 400px !important;
    max-height: 600px !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19);
    padding-top: 30px;
  }
  .bestgifts-list-cards1 {
    justify-content: space-between !important;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .product-list-cardskub {
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
  .bestgifts-list-cards {
    border: none;
    border-radius: 0px;
    min-width: 156px !important;
    max-width: 156px !important;
    min-height: 260px !important;
    max-height: 260px !important;
    box-shadow: none;
    padding-top: 30px;
  }
  .bestgifts-list-cards1 {
    justify-content: center !important;
  }
}
.cartButton_bb {
  font-size: small;
  font-weight: bold;
  height: 30px;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  border: 3px !important;
  color: white !important;
  background-color: #e7762d;
  border-radius: 20px;
}

.enqButton {
  font-size: small;
  font-weight: bold;
  height: 30px;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  background-color: grey;
}

.inner-star-icon {
  font-size: 15px !important;
  color: orange !important;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .mob_bt_prc {
    display: flex !important;
    flex-wrap: unset !important;
  }
  .mayan_dimen {
    height: 24% !important;
    border: 1px;
  }
  .cartButton_bb {
    font-size: 10px;
    font-weight: bold;
    width: 85%;
    height: 25px;
    margin-left: 5%;
    margin-top: 2px;
    background-color: #e7762d !important;
    border: 2px;
    color: white !important;
    position: relative;
    top: -1px;
  }
  .enqButton {
    font-size: 10px;
    font-weight: bold;
    width: 85%;
    height: 25px;
    margin-left: 5%;
    margin-top: 2px;
    background-color: transparent !important;
    border: 2px solid #3c707c !important;
    color: #091214 !important;
    background-color: grey;
  }
  .product-list-cards1 {
    border: none;
    border-radius: 10px;
    min-width: 100px !important;
    max-width: 200px !important;
    min-height: 315px !important;
    max-height: 300px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    padding-top: 30px;
  }
  .product-list-cards-deera {
    min-width: 0px !important;
    max-width: 100% !important;
    min-height: 240px !important;
    max-height: 220px !important;
    padding: 0px;
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .product-list-cards-avonseals {
    min-width: 0px !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 120px !important;
    padding: 0px;
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .product-list-cards-vistas {
    margin-left: 18px;
    min-width: 0px !important;
    max-width: 105% !important;
    min-height: 0px !important;
    max-height: 145px !important;
    padding: 0px;
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .product-list-cards-cle {
    min-width: 0px !important;
    max-width: 90% !important;
    min-height: 0px !important;
    max-height: 120px !important;
    padding: 0px;
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    margin-left: 23px;
  }
  .product-list-cards-avonseals1 {
    min-width: 0px !important;
    max-width: 85% !important;
    min-height: 0px !important;
    max-height: 133px !important;
    padding: 0px;
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    transform: translate(0px, -30px);
  }
  .product-list-price11 {
    font-size: 12px !important;
    color: #e7762d !important;
    font-weight: 600 !important;
  }
  .product-list-image1 {
    min-height: 150px !important;
    max-height: 150px !important;
    width: 85% !important;
    border-radius: 5px !important;
    object-fit: contain;
  }
  .inner-star-icon {
    font-size: 11px !important;
    color: orange !important;
  }
  .fa fa-shopping-cart {
    font-size: 11px !important;
  }
}
.seller {
  width: 150px;
  height: 30px;
  background-color: #2554C7;
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.mayan_dimen {
  width: 98%;
  height: 11%;
  border: 1px;
}

.homemayan-product-list-cards {
  background-color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
  min-height: 357px !important;
  max-height: 419px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}

.mayan-product-list-cards {
  background-color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
  min-height: 280px !important;
  max-height: 467px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}

.mayan_img_center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .fe_mayan_card {
    margin-left: 17px;
  }
  .mayan-product-list-cards {
    background-color: white;
    border: none;
    border-radius: 10px;
    width: 94%;
    min-height: 180px !important;
    max-height: 190px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    display: flex !important;
  }
  .homemayan-product-list-cards {
    background-color: white;
    border: none;
    border-radius: 10px;
    width: 90%;
    min-height: 155px !important;
    max-height: 190px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    display: flex !important;
  }
  .mayan_img_center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    min-width: 148px;
  }
}
.btn-default {
  background-color: red;
}

.text-for-listing {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: -3px;
  top: -5px;
  font-size: 1rem;
  text-align: center;
  color: #777;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
}

.info-class {
  visibility: visible;
}

.product-info-list {
  visibility: hidden;
  font-weight: bold;
}

.product-listing-container {
  margin: 10px 20px !important;
}

.product-list-card {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border: none !important;
  height: auto !important;
}

.product-list-image {
  min-height: 230px !important;
  max-height: 230px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
}

.product-list-description {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}

.product-list-tile {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.product-list-tile-mayan {
  font-size: 13px;
  color: #3c707c !important;
  font-weight: 600 !important;
}

.card_top {
  margin-top: -10px;
}

.product-list-tile-homemayan {
  font-size: 13px;
  color: #3c707c !important;
  font-weight: 600 !important;
}

.product-list-tile-karupatti {
  font-size: 16px !important;
  color: #3c2a26 !important;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 10vw;
}

@media screen and (max-width: 600px) {
  .product-list-tile-mayan {
    font-size: 11px !important;
    color: black !important;
    font-weight: 600 !important;
  }
  .product-list-tile-homemayan {
    height: 30px;
    font-size: 13px !important;
    color: black !important;
    font-weight: 600 !important;
  }
  .product-list-tile-karupatti {
    font-size: 14px !important;
    color: #3c2a26 !important;
    font-weight: 600 !important;
    width: 27vw !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .mayan-product-list-price {
    font-size: 13px !important;
    color: #3c707c !important;
    font-weight: 600 !important;
  }
  .mayan-product-list-priceout {
    font-size: 13px !important;
    color: #3c707c !important;
    font-weight: 600 !important;
  }
  .karupatti-product-list-price {
    font-size: 16px !important;
    color: #3c2a26 !important;
    font-weight: 600 !important;
  }
  .unchecked {
    color: #3c707c !important;
    font-size: 12px;
  }
  .unchecked1 {
    color: #ee761a !important;
    font-size: 12px;
  }
}
.product-list-price {
  font-size: 16px !important;
  color: #d54604 !important;
  font-weight: 600 !important;
}

.mayan-product-list-price {
  font-size: 14px;
  color: black;
  font-size: 13px;
  color: #3c2a26;
  font-weight: 600 !important;
  position: relative;
  top: 10px;
}

.mayan-product-list-priceout {
  font-size: 14px;
  color: black;
  font-size: 13px;
  color: #3c2a26;
  font-weight: 600 !important;
  position: relative;
}

.karupatti-product-list-price {
  font-size: 16px;
  color: #3c707c;
  font-weight: 600 !important;
  position: relative;
  top: 10px;
}

.product-weight-card {
  padding: 2px 10px !important;
  border: 1px solid #3c57a6 !important;
  margin: 3px !important;
  border-radius: 50%;
  color: #3c57a6 !important;
  cursor: pointer !important;
}

.product-weight-card:hover {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-list-cart {
  font-size: 13px !important;
  color: #3c57a6 !important;
  font-weight: bold !important;
  cursor: pointer;
}

.natural-main-text {
  color: #3c57a6 !important;
  font-family: "Segoe UI" !important;
  font-size: 19px !important;
  font-style: italic !important;
  padding-top: 15px !important;
}
@keyframes glow {
  from {
    text-shadow: 0 0 20px #32cd32;
  }
  to {
    text-shadow: 0 0 30px #32cd32, 0 0 10px #32cd32;
  }
}

.tams-user-icon {
  border: 1px solid #777 !important;
  color: #777 !important;
  padding: 5px 9px !important;
  border-radius: 50%;
  margin-right: 5px !important;
}
.tams-user-icon i.fa {
  padding: 0px !important;
}

.relatedproducts {
  padding: 40px 0px 40px 0px;
}
.relatedproducts .title {
  padding: 15px 0px 30px 0px;
  font-size: 14px;
}

.zipcode input {
  box-sizing: border-box;
  border: 2px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.zipcode input:focus {
  border: 2px solid #3c57a6;
}

#prodName {
  font-size: 1.6rem;
  color: #3c57a6;
}

#breadCrumbProductDetails {
  font-size: 0.8rem;
  color: rgb(80, 79, 79);
}

.normalSize {
  font-size: 14px;
}

.titleProduct {
  margin-bottom: 2px !important;
  font-size: 1.8rem;
}

.img-fluid-productDetail {
  height: 450px;
  width: 600px !important;
  padding: 0px 80px 0px 80px;
  padding-bottom: 2%;
}

#brokenImage {
  display: none;
}

.titleCase-product {
  margin-bottom: 15px !important;
}

.breadcrumb-productTitle {
  margin-bottom: 5px !important;
}

.priceRange {
  margin-top: 10px !important;
  color: rgb(32, 32, 32);
}

.cartButton {
  background-color: #BDC62B !important;
  border: none !important;
}

.cartButton_bb1 {
  background-color: #BDC62B !important;
  border: none !important;
}

.mayancartButton {
  background-color: #ffffff !important;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .mayancartButton {
    background-color: #ffffff !important;
    position: relative;
    border: 3px solid #3c707c !important;
    top: 20 !important;
    width: 55px;
  }
}
.shubhcardscartButton {
  background-color: #6b6b6b !important;
  border: 1px solid #6b6b6b !important;
  color: white !important;
}

.note {
  font-size: 0.8rem;
  color: #777;
}

.addSize {
  font-weight: bold;
}

.product-banner {
  margin-left: 25%;
  margin-right: 25%;
}

#product_breadcrumbs {
  padding-top: 8px;
  padding-left: 25px;
  font-size: 14px;
}

.img-fluid-product-thumb {
  height: 50px;
}

.product-video {
  width: 612px;
  height: 350px;
}

.wishlist-button {
  border: none !important;
}

.owl-prev {
  padding-right: 25%;
  padding-left: 25%;
}

.slider-nav {
  width: 100px;
  position: absolute;
  bottom: 15px !important;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.slider-nav .owl-thumb {
  background-color: #000;
  cursor: pointer;
}
.slider-nav .owl-thumb img {
  opacity: 0.5;
}
.slider-nav .owl-thumb:hover {
  background-color: transparent;
  border: 2px solid #ed98d2;
}
.slider-nav .owl-thumb:hover img {
  opacity: 1;
}
.slider-nav .owl-thumb.active {
  border: 2px solid #ed98d2;
  background-color: transparent;
}
.slider-nav .owl-thumb.active img {
  opacity: 1;
}

.product-details-wrapper .details-wrapper .product-all-details .row .col-right .add-to-cart {
  margin-bottom: 30px;
  font-size: 14px;
}

.slider-nav .owl-thumb.active {
  border: 4px solid #3c57a6 !important;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .product-banner {
    margin-left: 0%;
    margin-right: 0%;
  }
}
ul.one-column {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
}
ul.one-column li {
  font-size: 14px;
}
ul.one-column li .list-label {
  color: #000;
  margin-right: 15px;
  min-width: 105px;
}
ul.one-column li .list-value {
  color: #3c57a6;
}
ul.two-column {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
ul.two-column li {
  font-size: 14px;
}
ul.two-column li .list-label {
  color: #777;
  margin-right: 15px;
  min-width: 105px;
}
ul.two-column li .list-value {
  color: red;
}
ul.three-column {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}
ul.three-column li {
  font-size: 14px;
}
ul.three-column li .list-label {
  color: #777;
  min-width: 105px;
}
ul.three-column li .list-value {
  color: red;
}

.product-details-wrapper .details-top {
  padding: 15px 0px 15px 0px;
}
.product-details-wrapper .slider-nav {
  max-width: 400px;
  min-width: 300px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.product-details-wrapper .slider-nav .owl-thumb {
  background-color: #000;
  cursor: pointer;
}
.product-details-wrapper .slider-nav .owl-thumb img {
  opacity: 0.5;
}
.product-details-wrapper .slider-nav .owl-thumb:hover {
  background-color: transparent;
  border: 2px solid #ed98d2;
}
.product-details-wrapper .slider-nav .owl-thumb:hover img {
  opacity: 1;
}
.product-details-wrapper .slider-nav .owl-thumb.active {
  border: 2px solid #ed98d2;
  background-color: transparent;
}
.product-details-wrapper .slider-nav .owl-thumb.active img {
  opacity: 1;
}
.product-details-wrapper .product-all-details .product-name {
  font-size: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details {
  background-color: #ffffff;
  padding: 0px 15px 15px 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .price {
  font-size: 28px;
}
.product-details-wrapper .details-wrapper .product-all-details .row {
  padding: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-left {
  flex: 1;
  padding-right: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right {
  min-width: 250px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .add-to-cart {
  margin-bottom: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option {
  margin-top: 30px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option h6 .fa {
  font-size: 22px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option .input-group input {
  border-right: 0px;
}
.product-details-wrapper .details-wrapper .product-all-details .row .col-right .delivery-option .input-group .input-group-text {
  background-color: #ffffff;
  border-left: 0;
  color: blue;
}
.product-details-wrapper .details-wrapper .product-all-details .row .bullet-points ul li {
  list-style: circle !important;
}
.product-details-wrapper .details-wrapper .product-all-details .description {
  padding-bottom: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .product-addtional-details {
  position: relative;
  padding-top: 15px;
}
.product-details-wrapper .details-wrapper .product-all-details .product-addtional-details::before {
  content: "";
  position: absolute;
  width: calc(100% - 198px);
  height: 2px;
  background: #707070;
  opacity: 0.2;
  box-shadow: 0;
  top: -10px;
}

.product-image-container {
  height: 100px;
  width: 180px;
  border: 1px dotted #3c57a6;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.overlay {
  position: absolute;
  right: -10px;
  top: -10px;
}

.sizeText {
  font-weight: bold;
  text-transform: uppercase !important;
}

.image-preview-product.active {
  border: 2px solid #3c57a6 !important;
}

.sizebox {
  border-style: none !important;
  background-color: lightgrey !important;
  color: #000 !important;
  margin-bottom: 3px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
}

@media screen and (max-width: 600px) {
  .sizebox {
    border-style: none !important;
    background-color: lightgrey !important;
    color: #000 !important;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    width: 50px;
  }
  .sizeText {
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase !important;
    color: black;
  }
}
.sizebox.active {
  border-style: none !important;
  color: #ffffff !important;
  margin-bottom: 3px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
  background-color: #3c57a6 !important;
  font-weight: bold;
}

.price {
  font-size: xx-large;
  font-weight: bold;
}

.viewCart {
  background-color: #3c57a6;
  color: #ffffff !important;
  border: none !important;
}

.bombayViewCart {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  border: none !important;
  width: 65%;
  margin-top: 2%;
  margin-left: -4% !important;
}

.linenViewCart {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  border: none !important;
  width: 65%;
  margin-top: 14%;
  margin-left: -4% !important;
}

.bombay-input {
  width: 50%;
  text-align: center;
}

.bombay-input-details {
  width: 25%;
  text-align: center;
}

.bombayViewCart-details {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  border: none !important;
  margin-top: -57%;
  left: 8%;
  position: relative;
}

.bombayViewCart-details1 {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  border: none !important;
  margin-top: -34%;
  left: 8%;
  position: relative;
}

.DeeraviewCart {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
  border: none !important;
}

@media screen and (min-width: 1600px) {
  .dropdown2-container {
    width: 30% !important;
  }
}
.dropdown2-container {
  position: relative;
  left: -1%;
  width: 40%;
  height: 50px;
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
  .dropdown2-container {
    position: relative;
    left: -1%;
    width: 49%;
    height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .viewCart {
    width: 100%;
    position: relative;
    margin-left: 0px !important;
    margin-left: 0 !important;
  }
  .dropdown2-container {
    width: 100% !important;
  }
  .bombayViewCart {
    width: 80% !important;
    position: relative;
    left: -1%;
    margin-top: -1%;
    transform: scale(0.8);
  }
  .bombay-input {
    width: 35%;
  }
  .bombay-input-details {
    width: 40%;
  }
  .bombayViewCart-details {
    position: relative;
    left: 5%;
    transform: scale(0.8);
    top: -24px;
    width: 110%;
  }
  .bombayViewCart-details1 {
    position: relative;
    left: 5%;
    transform: scale(0.8);
    top: -11px;
    width: 130%;
  }
  .DetailsviewCart {
    width: 50% !important;
    position: relative;
  }
  .DeeraviewCart {
    position: relative;
  }
}
.mayanviewCart {
  background-color: #000000 !important;
  color: #ffffff !important;
  border: none !important;
  position: relative;
  bottom: -10px;
}

@media screen and (max-width: 600px) {
  .mayanviewCart {
    background-color: #000000 !important;
    color: #ffffff !important;
    border: none !important;
    position: relative;
    top: 24px !important;
    white-space: nowrap;
  }
}
.rotate-dot .owl-theme .owl-dots {
  transform: translateX(-50%);
}
.rotate-dot .owl-theme .owl-dots [class*=owl-] {
  width: 25px;
  height: 25px;
  transform: rotate(130deg);
  margin: 0px 6px;
  border: 1px solid transparent;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-].active {
  border: 1px solid blue;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-].active span {
  background-color: blue;
}
.rotate-dot .owl-theme .owl-dots [class*=owl-] span {
  width: 12px;
  height: 12px;
  border-radius: 0;
  margin: 5px 5px;
}

#size {
  padding-left: 50px;
}

.productDetailImage {
  background: #fff;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.product-box-inner {
  display: flex;
  padding: 20px;
  border-bottom: 2px solid #dddddd;
  position: relative;
}
.product-box-inner .product-detail {
  padding-left: 30px;
  position: relative;
}
.product-box-inner .product-detail .sku {
  font-size: 16px;
  color: rgba(162, 160, 160, 0.8666666667);
}
.product-box-inner .product-detail .add-to {
  position: absolute;
  bottom: 0px;
}
.product-box-inner .product-detail .price-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.product-box-inner .product-detail .price-wrapper .price {
  font-size: 25px;
}
.product-box-inner .verified {
  position: absolute;
  top: 70px;
  right: 178px;
  color: rgba(162, 160, 160, 0.8666666667);
}
.product-box-inner .verified .isverified {
  position: relative;
}
.product-box-inner .verified .isverified:before {
  content: "";
  width: 15px;
  height: 15px;
  background-size: 100%;
  display: inline-block;
  position: absolute;
  left: -20px;
  top: 2px;
}

.product-attributes thead {
  background-color: #ed98d2;
}
.product-attributes th {
  width: 50%;
}
.product-attributes .highlight {
  background-color: #ed98d2;
}

.product-table-head {
  border-bottom: 1px solid grey;
}

.productTableRow {
  white-space: nowrap;
}

.product-additional-info {
  font-weight: bolder;
}

.general-info {
  border: 1px solid lightgray;
}

.general-info-label {
  background-color: #F8F8F8;
  border: 1px solid #E5E4E2;
  font-weight: bold;
}

.detail-preview {
  width: 90px;
  height: 90px;
  font-weight: normal;
}

.image-preview-product {
  width: 80px;
  height: 80px;
}

#instruction {
  color: #3c57a6;
  font-weight: bold;
  text-transform: uppercase;
}

.breadcurmb .normalSize {
  color: #3c57a6;
}

.example {
  width: 400px;
  height: 200px;
  overflow-y: scroll; /* Add the ability to scroll */
}

.box {
  border-color: #3c57a6;
}
.box .col-sm-12 {
  color: #3b8c9c;
  font-weight: 900;
}

.storage {
  border-color: #3c57a6;
}
.storage .card-header {
  height: 40px;
  color: #3c57a6;
  font-weight: bold;
}
.storage .col-sm-12 {
  font-weight: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.product-details-tabs {
  margin: 2rem;
  width: 100%;
}
.product-details-tabs .nav-tabs .nav-link.active, .product-details-tabs .nav-tabs .nav-item.show .nav-link {
  color: #3c57a6;
  font-weight: bold;
}
.product-details-tabs .tab-content {
  margin: 1rem;
}

.zipcode-available {
  color: green;
  font-weight: bold;
}

.zipcode-nonavailable {
  color: #ef1d3c;
  font-weight: bold;
}

.estd-delivery {
  color: #3c57a6;
}

@media screen and (max-width: 600px) {
  .detail-preview {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    margin-left: 0%;
  }
  .product-details-tabs {
    margin: 0rem !important;
  }
  #size {
    padding-left: 15px !important;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3c57a6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e4381 !important;
}

.bg-secondary {
  background-color: #ed98d2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e56dbf !important;
}

.bg-success {
  background-color: rgb(34, 34, 34) !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #090909 !important;
}

.bg-info {
  background-color: #3c57a6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2e4381 !important;
}

.bg-warning {
  background-color: #ebaa05 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b98604 !important;
}

.bg-danger {
  background-color: #ef1d3c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cb0e2a !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3c57a6 !important;
}

.border-secondary {
  border-color: #ed98d2 !important;
}

.border-success {
  border-color: rgb(34, 34, 34) !important;
}

.border-info {
  border-color: #3c57a6 !important;
}

.border-warning {
  border-color: #ebaa05 !important;
}

.border-danger {
  border-color: #ef1d3c !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #3c57a6 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #283a6e !important;
}

.text-secondary {
  color: #ed98d2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #e257b6 !important;
}

.text-success {
  color: rgb(34, 34, 34) !important;
}

a.text-success:hover, a.text-success:focus {
  color: black !important;
}

.text-info {
  color: #3c57a6 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #283a6e !important;
}

.text-warning {
  color: #ebaa05 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #a07403 !important;
}

.text-danger {
  color: #ef1d3c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30d25 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfcfcf !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.qr-product-title {
  color: #3c57a6;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.qr-instruction {
  color: #3c57a6;
  font-weight: 300;
  font-style: bold;
  text-transform: uppercase;
}

.logo-header {
  font-size: 35px;
  margin-bottom: 0px !important;
  color: rgb(34, 34, 34);
}

.sub-header {
  font-size: 25px !important;
  margin-bottom: 0px !important;
  color: rgb(34, 34, 34);
}

.required {
  color: red;
}

.form-label {
  float: right;
  margin-right: 10px;
  padding-top: 4px;
  color: #333333;
}
@media (max-width: 767.98px) {
  .form-label {
    float: left;
    margin-right: none;
    padding-top: 0px;
  }
}

.buttons {
  margin-top: 25px;
  padding: 3px 45px;
}
@media (max-width: 767.98px) {
  .buttons {
    margin-top: 15pxpx;
  }
}

.form-control {
  background-color: #ffffff !important;
}

select.form-control {
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid #dee2e6;
  color: #6c757d;
  color: #495057;
}
select.form-control:focus {
  outline: 1px solid #dee2e6;
}
select.form-control.border-primary {
  outline: 1px solid #3c57a6;
}
select.form-control.border-primary:focus {
  outline: 1px solid #3c57a6;
}
select.form-control.border-secondary {
  outline: 1px solid #ed98d2;
}
select.form-control.border-secondary:focus {
  outline: 1px solid #ed98d2;
}
select.form-control.border-success {
  outline: 1px solid rgb(34, 34, 34);
}
select.form-control.border-success:focus {
  outline: 1px solid rgb(34, 34, 34);
}
select.form-control.border-info {
  outline: 1px solid #3c57a6;
}
select.form-control.border-info:focus {
  outline: 1px solid #3c57a6;
}
select.form-control.border-warning {
  outline: 1px solid #ebaa05;
}
select.form-control.border-warning:focus {
  outline: 1px solid #ebaa05;
}
select.form-control.border-danger {
  outline: 1px solid #ef1d3c;
}
select.form-control.border-danger:focus {
  outline: 1px solid #ef1d3c;
}
select.form-control.border-light {
  outline: 1px solid #f5f5f5;
}
select.form-control.border-light:focus {
  outline: 1px solid #f5f5f5;
}
select.form-control.border-dark {
  outline: 1px solid #343a40;
}
select.form-control.border-dark:focus {
  outline: 1px solid #343a40;
}

.gift-form {
  margin-bottom: 50px;
  padding: 35px 15px;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  width: 100%;
  background-color: #ffffff;
}
@media (max-width: 767.98px) {
  .gift-form {
    padding: 10px 30px;
    align-items: center;
    margin-bottom: 20px;
  }
}

.card1 {
  margin-left: 30px;
  display: inline-block;
  padding: 4rem 1rem 7rem 1rem;
  background-color: #EDE8CA;
  position: relative;
}
.card1:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  background-color: #ffffff;
  top: 0px;
  right: 0px;
  border-bottom: 20px solid #bdc68c;
  border-left: 20px solid #bdc68c;
  border-right: 20px solid #ffffff;
  border-top: 20px solid #ffffff;
  position: absolute;
  filter: drop-shadow(-5px 5px 3px rgba(94, 94, 94, 0.5));
}
.card1:before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-top: 40px solid #bdc68c;
  border-right: 40px solid #bdc68c;
  border-left: 40px solid #ffffff;
  border-bottom: 40px solid #ffffff;
  bottom: 0px;
  left: 0px;
  position: absolute;
  filter: drop-shadow(7px -7px 5px rgba(94, 94, 94, 0.5));
  margin-right: 10%;
}
.card1:nth-of-type(1) {
  margin-right: 9%;
}
.card1 h2 {
  margin-bottom: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}
.card1 p {
  font-size: 1.1rem;
  line-height: 140%;
}
.card1 .card-txt {
  color: #3c57a6 !important;
}

.home-breadcrumb:hover {
  cursor: pointer;
}

.cart-list {
  margin: 10px;
  height: 80px;
  width: 100%;
  border: none;
  background-color: #ffffff;
}

.count {
  float: right;
}
.count .plus {
  cursor: pointer;
}
.count .minus {
  cursor: pointer;
}
.count .numbers {
  width: 15px;
  height: 10px;
  font-weight: bolder;
}

.item-name {
  text-align: center;
}

.item-image {
  width: 100px !important;
  height: 80px !important;
  border-radius: 10% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.cost {
  text-align: center;
  font-weight: bolder;
  float: right;
  font-size: large;
}

.close {
  cursor: pointer;
}

.cart-table {
  margin-top: 40px;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  border: none;
}
.cart-table th {
  border: none;
}
.cart-table td {
  padding-bottom: 2px;
  vertical-align: middle;
  align-items: center;
  border: none;
}

.cart-table-container .table th {
  border: none;
}

.submit-action {
  background-color: red;
  border-radius: 25px;
  margin-left: auto;
  color: #ffffff;
}

.add-cart1 {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #3c57a6 !important;
  width: 90px;
}

@media screen and (max-width: 600px) {
  .add-cart1 {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #3c57a6 !important;
    width: 100%;
  }
}
.edit-cart {
  display: flex;
  background-color: #3c57a6;
  width: 65%;
  padding: 5px;
}

.inq-amt-st {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.inq-amt-st .plus:hover {
  cursor: pointer;
}
.inq-amt-st .minus:hover {
  cursor: pointer;
}

.avon-checkout {
  padding-right: 5px !important;
  padding-left: 18px !important;
}
.avon-checkout .plus:hover {
  cursor: pointer;
}
.avon-checkout .minus:hover {
  cursor: pointer;
}

.special-checkout {
  padding-right: 5px !important;
  padding-left: 8px !important;
}
.special-checkout .plus:hover {
  cursor: pointer;
}
.special-checkout .minus:hover {
  cursor: pointer;
}

.total-price {
  text-align: end;
}

@media screen and (max-width: 600px) {
  .cost {
    position: relative;
    left: -2%;
    top: -50px;
  }
  .total-price {
    text-align: center;
  }
  .cost-deera {
    position: relative;
    top: 80px;
    font-weight: 700;
    float: right;
  }
  .cost-price {
    position: relative;
    top: 30px;
    font-weight: 700;
    float: right;
  }
  .trash-position {
    position: relative;
    top: -6px;
    left: 37%;
  }
  .trash-position-bh {
    position: relative;
    top: -10px;
    left: 0%;
  }
  .trash-position-sc {
    position: absolute;
    top: -5px;
    left: 75%;
  }
  .cost-trash-posi {
    position: relative;
    left: -7%;
  }
  .cost-trash-posi-sc {
    position: relative;
    width: 22% !important;
  }
}
@media screen and (min-width: 390px) and (max-width: 600px) {
  .trash-position {
    position: relative;
    top: 0px;
    left: 50%;
  }
  .trash-position-bh {
    position: relative;
    top: 0px;
    left: -10%;
  }
}
.mt-2.inq-cart-pay-st.img-size img {
  width: 100px;
  height: 31px;
}

#emailinput {
  border-radius: 10px !important;
}

@media screen and (max-width: 600px) {
  .inq-cart-ul-st li.nav-item {
    padding: 0px !important;
  }
  .float-right-mob {
    float: right;
  }
}
@media screen and (max-width: 768px) {
  .inq-cart-ul-st li.nav-item {
    margin-bottom: 15px !important;
  }
}
select {
  font-weight: bolder;
}

.font-bold-two {
  font-weight: 500;
  font-family: system-ui;
}

.font-bold {
  font-weight: bolder;
  font-family: system-ui;
}

.inq-btn-st {
  font-weight: 600 !important;
}

.codlable {
  color: orange;
  font-weight: bolder;
  font-size: 22px;
  padding-right: 13px;
}

.inq-mc-ordn1-st p img {
  margin: 0px 5px;
}

.inq-mc-ordn1-st p {
  line-height: 27px;
}

.reward {
  color: rgb(34, 34, 34);
  font-weight: bold;
}

.text-bold {
  font-weight: 600;
}

.image-tick {
  position: absolute;
  top: -4px;
  right: 25%;
}

@media screen and (max-width: 576px) {
  .image-tick {
    right: 36%;
  }
}
#take {
  color: grey;
  font-weight: 500;
}

.add-cart1 .fa-style i.fa {
  background-color: transparent;
}

.cardBox {
  float: left;
  font-size: 1.2em;
  margin: 1% 0 0 1%;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 90%;
}

.cardBox:hover .customer-card {
  transform: rotateY(180deg);
}

.cardBox:hover .back {
  z-index: 99;
}
.cardBox:hover .front {
  z-index: 1;
  display: none;
}

.cardBox:not(:hover) .back {
  z-index: 0;
}
.cardBox:not(:hover) .front {
  z-index: 99;
}

@media screen and (min-width: 1200px) {
  .customer-card {
    height: 350px;
  }
  .cardBox {
    width: 90%;
  }
  #innerpartFront {
    padding: 2.5rem 0rem;
  }
}
.customer-card {
  background: transparent;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
  border: none;
}

#innerpart {
  background-color: #EDE8CA;
  border-radius: 30px;
  padding: 4rem 0rem;
}

#innerpartFront {
  background-color: #E8E8E8;
  border-radius: 30px;
  padding: 2.5rem 0rem;
}

#innerpartFront img {
  max-width: 100%;
  padding: 0.5rem;
  height: 185px;
  margin-bottom: 1rem;
}

.front p {
  color: #323232 !important;
}

.front h5 {
  color: #323232 !important;
  font-size: 17.5px;
}

.checkout {
  color: #FFFCF8;
  font-size: 13px;
  position: absolute;
  padding: 11px 22px;
  text-transform: uppercase;
  text-decoration: none;
  top: 28%;
  left: 28%;
  transform: translate(-28%, -28%);
  border-radius: 28px;
  background: #3c57a6;
  border: 1px solid #3c57a6;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin: 0;
}

@media screen and (max-width: 786px) {
  /* .checkout {
    padding: 6px 11px;
    font-size: 10px;
    position: relative;
    left: 14%;
    bottom: 40px
  } */
}
@media screen and (max-width: 480px) {
  .checkout {
    padding: 6px 11px;
    font-size: 10px;
    position: relative;
    bottom: 3px;
    left: 11%;
  }
}
.checkout a {
  text-decoration: none;
  color: #FFFCF8;
}

.customer-card .front {
  box-sizing: border-box;
  color: white;
  display: block;
  height: 100%;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.customer-card .back {
  box-sizing: border-box;
  color: white;
  display: block;
  font-size: 1.2em;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.customer-card .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.customer-card .back {
  transform: rotateY(180deg);
}

.customer-card .back button {
  background: #3c57a6 !important;
  border: 1px solid #3c57a6 !important;
  border-radius: 20px !important;
  color: white;
  padding: 0.375rem 1.5rem !important;
}

/* .customer-card .back a:hover {
  background: #fff;
  color: #333;
  text-shadow: 0 0 1px #333;
} */
.textblack {
  color: #333 !important;
  font-size: 15px;
  line-height: 1.7;
}

.textblack1 {
  font-size: 22px;
  color: #333 !important;
}

.textbrrown {
  color: rgb(34, 34, 34);
  font-weight: bold;
}

.cardBox:nth-child(2) .customer-card {
  -webkit-animation: giro 1.5s 1;
  animation: giro 1.5s 1;
}

.cardBox:nth-child(3) .customer-card {
  -webkit-animation: giro 2s 1;
  animation: giro 2s 1;
}

.cardBox:nth-child(4) .customer-card {
  -webkit-animation: giro 2.5s 1;
  animation: giro 2.5s 1;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 768px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 90%;
  }
  .customer-card {
    height: 385px;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
  .tick-image {
    position: absolute;
    /* right: 55%; */
    bottom: 4px;
  }
}
@media screen and (max-width: 1199px) and (min-width: 769px) {
  .customer-card {
    height: 385px;
  }
  #innerpart {
    padding: 10px;
  }
  .tick-image {
    position: absolute;
    left: 35%;
    bottom: 4px;
  }
}
@media screen and (max-width: 767px) and (min-width: 481px) {
  .tick-image {
    position: absolute;
    right: 35%;
    bottom: 4px;
  }
}
@media screen and (max-width: 480px) {
  .cardBox {
    width: 94.5%;
  }
  .customer-card {
    height: 345px;
  }
  .tick-image {
    position: absolute;
    right: 35% !important;
    bottom: 0px;
    width: 38px;
    height: 38px;
  }
}
@media screen and (min-width: 1200px) {
  #innerpart {
    height: 350px !important;
  }
  #innerpartFront {
    height: 350px !important;
  }
  .tick-image {
    width: 37px;
    height: 36px;
    margin-top: -2px;
  }
}
.innerpart {
  padding: 30px;
}

.bag {
  left: 17%;
}

.static-image {
  right: 0%;
  margin-top: 75px;
}

.loginLink {
  cursor: pointer;
}

.custom-buttons {
  padding: 1px 20px;
}

.pad {
  margin-right: -70px;
}

.help-text {
  color: #807f7f;
}

.disable-btn {
  pointer-events: none;
}

.reveal-if-active {
  opacity: 1;
  transition: 0.5s;
  overflow: visible;
}

.hide-if-inactive {
  opacity: 0;
  max-height: 0;
  transition: 0.5s;
  transform: scale(0.8);
  overflow: hidden;
}

.custom-brn-grp {
  border-radius: 0px !important;
}

.otp-form {
  margin-top: 30px;
}

.custome-nospace {
  padding-left: 0px !important;
}

.login-seperator {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.login-seperator:before, .login-seperator:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #cacaca;
  margin: auto;
}

.login-seperator:before {
  margin-right: 10px;
}

.login-seperator:after {
  margin-left: 10px;
}

.form-check {
  font-size: 1.4rem !important;
  margin-left: 15px;
  color: rgb(34, 34, 34);
  font-weight: bold;
}

.form-check-label {
  margin-left: 7px !important;
}

.form-check-input {
  margin-top: 7px;
}

.shipping-container {
  border: 1px solid #ced4da;
  padding: 10px 15px;
  border-radius: 0.25rem;
  box-shadow: rgba(140, 148, 91, 0.75) 0px 1px 4px;
}

.sub-heading {
  color: #000000;
  font-weight: 600;
  cursor: pointer;
}

.address-container {
  border: 1px solid #706e6e;
  padding: 10px 15px;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.address-font {
  font-size: 0.8rem;
  color: #706e6e;
}

.address-divider {
  margin: 10px 0px;
  border-top: 1px solid #ced4da;
}

.address-lines {
  margin-top: 8px;
}

.cutome-deliverylabel {
  margin-left: 0px !important;
}

#shadowBox {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/opacity/see-through */
  border: 3px solid;
}

.rainbow {
  margin-top: 5px;
  font-weight: 800;
  font-style: italic;
}

.rainbow_text_animated {
  background: linear-gradient(to right, #F51720, #FA26A0, #F8D210, #2FF3E0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%, 100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.payment-option-card {
  border-radius: 5px;
  padding: 10px 15px;
  box-shadow: 0 0 4px #dad8d8;
  min-width: 450px;
  max-width: 450px;
}

.payment-option-active {
  border: 1px solid rgb(34, 34, 34);
}

.payment-option-title {
  color: #000000;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.payment-option-instructions {
  color: #919191;
  font-size: 0.8rem;
  font-weight: 100;
}

.customer-card {
  display: none;
}

.customer-breadcrumb:hover {
  cursor: pointer;
}

.inq-sp-1 i {
  font-size: 12px !important;
  color: #fff;
}

.inq-sp-3 i {
  font-size: 12px !important;
  color: #fff;
}

.inq-sp-1 i:hover, .inq-sp-3 i:hover {
  background: #3c57a6;
  color: #fff;
  cursor: pointer;
}

.add-cart1 {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  border-radius: 5px;
  background-color: rgb(34, 34, 34) !important;
}
.add-cart1 .inq-cart-bw-st {
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: rgb(34, 34, 34) !important;
}

i.fa {
  background: none;
  padding: 0px 0px;
}

.add-cart2 {
  width: 50%;
  color: #ffffff;
  border-radius: 5px;
  background-color: rgb(34, 34, 34) !important;
}
.add-cart2 .inq-cart-bw-st {
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: rgb(34, 34, 34) !important;
}

.aligncheckout_button {
  width: 100%;
}

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: red;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3c57a6;
  --secondary: #ed98d2;
  --success: rgb(34, 34, 34);
  --info: #3c57a6;
  --warning: #ebaa05;
  --danger: #ef1d3c;
  --light: #f5f5f5;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3c57a6;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #283a6e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c8d0e6;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-primary:hover {
  background-color: #b6c1de;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b6c1de;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fae2f2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f6c9e8;
}

.table-hover .table-secondary:hover {
  background-color: #f6cce8;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f6cce8;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1c1c1;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8c8c8c;
}

.table-hover .table-success:hover {
  background-color: #b4b4b4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b4b4b4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8d0e6;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9aa8d1;
}

.table-hover .table-info:hover {
  background-color: #b6c1de;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b6c1de;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e7b9;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5d37d;
}

.table-hover .table-warning:hover {
  background-color: #f7dfa2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7dfa2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc0c8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7899a;
}

.table-hover .table-danger:hover {
  background-color: #f9a8b3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9a8b3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-light:hover {
  background-color: #efefef;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #efefef;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgb(34, 34, 34);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(34, 34, 34, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: rgb(34, 34, 34);
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3c3c3c;
  background-color: #3c3c3c;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(34, 34, 34);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: rgb(34, 34, 34);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef1d3c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(239, 29, 60, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef1d3c;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef1d3c;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef1d3c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef1d3c;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f24d65;
  background-color: #f24d65;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef1d3c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef1d3c;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef1d3c;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #e777c4;
  border-color: #e56dbf;
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e56dbf;
  border-color: #e462ba;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 135, 185, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:hover {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #090909;
  border-color: #020202;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #32488a;
  border-color: #2e4381;
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #2e4381;
  border-color: #2b3e77;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 112, 179, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #c68f04;
  border-color: #b98604;
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #b98604;
  border-color: #ad7d04;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 150, 10, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #d70f2d;
  border-color: #cb0e2a;
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #cb0e2a;
  border-color: #bf0e28;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 63, 89, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: gainsboro;
  border-color: #d5d5d5;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-secondary {
  color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ed98d2;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ed98d2;
  border-color: #ed98d2;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.btn-outline-success {
  color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: rgb(34, 34, 34);
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
  border-color: rgb(34, 34, 34);
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-info {
  color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3c57a6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.btn-outline-warning {
  color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ebaa05;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ebaa05;
  border-color: #ebaa05;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.btn-outline-danger {
  color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef1d3c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ef1d3c;
  border-color: #ef1d3c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.btn-outline-light {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f5f5f5;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3c57a6;
  text-decoration: none;
}
.btn-link:hover {
  color: #283a6e;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #3c57a6;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8c9fd5;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #b2bee3;
  border-color: #b2bee3;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3c57a6;
  background-color: #3c57a6;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(60, 87, 166, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #8c9fd5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8c9fd5;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3c57a6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b2bee3;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #3c57a6;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3c57a6;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #283a6e;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #ed98d2;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e56dbf;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 152, 210, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: rgb(34, 34, 34);
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #090909;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #3c57a6;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #2e4381;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 87, 166, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ebaa05;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #b98604;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 170, 5, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #ef1d3c;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #cb0e2a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 29, 60, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f5f5f5;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: gainsboro;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-primary hr {
  border-top-color: #b6c1de;
}
.alert-primary .alert-link {
  color: #111931;
}

.alert-secondary {
  color: #7b4f6d;
  background-color: #fbeaf6;
  border-color: #fae2f2;
}
.alert-secondary hr {
  border-top-color: #f6cce8;
}
.alert-secondary .alert-link {
  color: #5c3b51;
}

.alert-success {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1;
}
.alert-success hr {
  border-top-color: #b4b4b4;
}
.alert-success .alert-link {
  color: black;
}

.alert-info {
  color: #1f2d56;
  background-color: #d8dded;
  border-color: #c8d0e6;
}
.alert-info hr {
  border-top-color: #b6c1de;
}
.alert-info .alert-link {
  color: #111931;
}

.alert-warning {
  color: #7a5803;
  background-color: #fbeecd;
  border-color: #f9e7b9;
}
.alert-warning hr {
  border-top-color: #f7dfa2;
}
.alert-warning .alert-link {
  color: #483402;
}

.alert-danger {
  color: #7c0f1f;
  background-color: #fcd2d8;
  border-color: #fbc0c8;
}
.alert-danger hr {
  border-top-color: #f9a8b3;
}
.alert-danger .alert-link {
  color: #4f0914;
}

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light hr {
  border-top-color: #efefef;
}
.alert-light .alert-link {
  color: #666666;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #3c57a6;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #3c57a6;
  border-color: #3c57a6;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-secondary {
  color: #7b4f6d;
  background-color: #fae2f2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7b4f6d;
  background-color: #f6cce8;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7b4f6d;
  border-color: #7b4f6d;
}

.list-group-item-success {
  color: #121212;
  background-color: #c1c1c1;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #121212;
  background-color: #b4b4b4;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #121212;
  border-color: #121212;
}

.list-group-item-info {
  color: #1f2d56;
  background-color: #c8d0e6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1f2d56;
  background-color: #b6c1de;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #1f2d56;
  border-color: #1f2d56;
}

.list-group-item-warning {
  color: #7a5803;
  background-color: #f9e7b9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a5803;
  background-color: #f7dfa2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a5803;
  border-color: #7a5803;
}

.list-group-item-danger {
  color: #7c0f1f;
  background-color: #fbc0c8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c0f1f;
  background-color: #f9a8b3;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7c0f1f;
  border-color: #7c0f1f;
}

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3c57a6 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e4381 !important;
}

.bg-secondary {
  background-color: #ed98d2 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e56dbf !important;
}

.bg-success {
  background-color: rgb(34, 34, 34) !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #090909 !important;
}

.bg-info {
  background-color: #3c57a6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2e4381 !important;
}

.bg-warning {
  background-color: #ebaa05 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b98604 !important;
}

.bg-danger {
  background-color: #ef1d3c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cb0e2a !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3c57a6 !important;
}

.border-secondary {
  border-color: #ed98d2 !important;
}

.border-success {
  border-color: rgb(34, 34, 34) !important;
}

.border-info {
  border-color: #3c57a6 !important;
}

.border-warning {
  border-color: #ebaa05 !important;
}

.border-danger {
  border-color: #ef1d3c !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #3c57a6 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #283a6e !important;
}

.text-secondary {
  color: #ed98d2 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #e257b6 !important;
}

.text-success {
  color: rgb(34, 34, 34) !important;
}

a.text-success:hover, a.text-success:focus {
  color: black !important;
}

.text-info {
  color: #3c57a6 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #283a6e !important;
}

.text-warning {
  color: #ebaa05 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #a07403 !important;
}

.text-danger {
  color: #ef1d3c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30d25 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfcfcf !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
agm-map {
  height: 100%;
  width: 100%;
}

.btn-outline-success {
  color: rgb(34, 34, 34) !important;
  border-color: rgb(34, 34, 34) !important;
}

.btn-outline-success:hover {
  color: #ffffff !important;
  background-color: rgb(34, 34, 34) !important;
}

.custom-font {
  color: #777;
  font-size: 0.8 rem;
}

.checkout-container {
  background-color: #f1eded !important;
  min-height: 100vh;
}
.checkout-container .headings {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
}
.checkout-container .normalText {
  font-size: 14px;
}
.checkout-container .accordian-container {
  margin: 10px 30px 10px 10px;
}
.checkout-container .custom-accordian .card {
  margin: 20px 0px;
  height: auto !important;
  border: none;
  background-color: #ffffff !important;
}
.checkout-container .custom-accordian .havencard {
  margin: 20px 0px;
  height: auto !important;
  border: none;
  background-color: #ffffff !important;
}
.checkout-container .custom-accordian .card-header {
  background-color: #3c57a6;
  color: #ffffff;
  font-size: 16px;
}
.checkout-container .hide-btn {
  display: none;
}
.checkout-container small {
  font-size: 70%;
}

.checkout-alerts {
  top: 60px;
  position: fixed;
  width: 70%;
  z-index: 99;
  margin-left: 10px;
}

.checkout-empty-cart {
  color: #ef1d3c !important;
  height: 65px;
  width: 100px;
  margin-bottom: 15px;
}

.checkout-para {
  color: #777;
  font-size: 14px !important;
  margin-top: 15px;
  margin-bottom: 10px !important;
}

.pac-container {
  z-index: 10000 !important;
}

.hidePayment {
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .mob-checkout {
    position: relative;
    top: -102px;
  }
  .checkout-container .accordian-container {
    margin: 10px 10px 10px 10px !important;
  }
}
.header-section {
  top: 0px;
  width: 100%;
  background-color: #3c57a6;
  min-height: 55px;
}
@media (max-width: 767.98px) {
  .header-section {
    margin-top: 55px;
    min-height: auto;
  }
}

@media (max-width: 640px) {
  .topspace {
    margin-top: 100px;
  }
}
@media only screen and (min-width: 640px) {
  .topspace {
    margin-top: 0px;
  }
}
.logo-section {
  margin-left: 150px;
  padding: 5px 0px;
}
@media (max-width: 767.98px) {
  .logo-section {
    margin-left: 10px;
  }
}

.logo-section1 {
  margin-left: 150px;
  padding: 5px 0px;
}
@media (max-width: 767.98px) {
  .logo-section1 {
    margin-left: 10px;
    margin-top: 12%;
  }
}

.logo-images {
  width: 100px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .logo-images {
    width: 165px;
    height: 40px;
  }
}

.banner-section {
  margin-left: 30px;
  margin-top: 15px;
}
@media (max-width: 767.98px) {
  .banner-section {
    margin-left: 5px;
    margin-top: 3px;
    font-size: 10px !important;
  }
}

@media (max-width: 640px) {
  .header-section1 {
    top: 0px;
    width: 100%;
    margin-top: 100px;
    background-color: #3c57a6;
    min-height: 55px;
  }
}
@media (max-width: 640px) and (max-width: 767.98px) {
  .header-section1 {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (max-width: 640px) {
  .header-section2 {
    top: 0px;
    margin-top: 100px !important;
    width: 100%;
    background-color: #0095da;
    min-height: 55px;
  }
}
@media (max-width: 640px) and (max-width: 767.98px) {
  .header-section2 {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (max-width: 640px) {
  .header-sectionLush {
    top: 0px;
    margin-top: 101px !important;
    width: 100%;
    min-height: 55px;
  }
}
@media (max-width: 640px) and (max-width: 767.98px) {
  .header-sectionLush {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (max-width: 640px) {
  .header-section3 {
    top: 0px;
    margin-top: 100px !important;
    width: 100%;
    background-color: #4e90b2;
    min-height: 55px;
  }
}
@media (max-width: 640px) and (max-width: 767.98px) {
  .header-section3 {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (min-width: 640px) {
  .header-section1 {
    margin-top: 0px;
    top: 0px;
    width: 100%;
    background-color: #3c57a6;
    min-height: 55px;
  }
}
@media (min-width: 640px) and (max-width: 767.98px) {
  .header-section1 {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (min-width: 640px) {
  .header-section2 {
    margin-top: 0px !important;
    top: 0px;
    width: 100%;
    background-color: #0095da;
    min-height: 55px;
  }
}
@media (min-width: 640px) and (max-width: 767.98px) {
  .header-section2 {
    margin-top: 55px;
    min-height: auto;
  }
}
@media (min-width: 640px) {
  .header-section3 {
    margin-top: 0px !important;
    top: 0px;
    width: 100%;
    background-color: #4e90b2;
    min-height: 55px;
  }
}
@media (min-width: 640px) and (max-width: 767.98px) {
  .header-section3 {
    margin-top: 55px;
    min-height: auto;
  }
}
.checkout-forms {
  margin-right: 0px;
  font-size: 12px;
}
.checkout-forms .invalid-feedback {
  font-size: 12px !important;
}
.checkout-forms label {
  margin-bottom: 15px;
  position: relative;
  border-bottom: 1px solid #ddd;
  width: 104%;
  margin-bottom: 0px !important;
}
.checkout-forms .invalid-feedback {
  font-size: 12px !important;
}
.checkout-forms input {
  margin-top: 20px;
  font-size: 14px;
  outline: none !important;
}
.checkout-forms textarea {
  width: 100%;
  padding: 10px 0px;
  margin-top: 20px;
  font-size: 14px;
  border: none !important;
  outline: none !important;
}
.checkout-forms .form-group {
  margin-bottom: 0px !important;
}
.checkout-forms .form-control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.checkout-forms .form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.checkout-forms input:focus {
  border: none;
  outline: none;
}
.checkout-forms input::placeholder {
  opacity: 0;
}
.checkout-forms textarea::placeholder {
  opacity: 0;
}
.checkout-forms span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(30px);
  font-size: 0.825em;
  transition-duration: 300ms;
}
.checkout-forms .checkout-label {
  color: #777;
}
.checkout-forms label:focus-within > span,
.checkout-forms input:not(:placeholder-shown) + span {
  color: #3c57a6;
  transform: translateY(0px);
}
.checkout-forms label:focus-within > span,
.checkout-forms textarea:not(:placeholder-shown) + span {
  color: #3c57a6;
  transform: translateY(0px);
}

.btn-primary1 {
  padding: 8px 0px;
  border-radius: 0px;
  margin-top: 20px;
  font-size: 14px;
  border-radius: 3px !important;
  font-weight: 500;
}

.btn-timeslot {
  border-radius: 5px;
  border: 0px;
  background-color: grey;
  color: white;
  margin-right: 5px;
  margin-left: 5px;
  padding: 4px 15px;
}

.btn-timeslot1 {
  border-radius: 5px;
  border: 0px;
  background-color: #3c57a6;
  color: white;
  margin-right: 5px;
  margin-left: 5px;
  padding: 4px 15px;
}

.btn-timeslot:focus {
  background-color: #3c57a6;
}

.btn-timeslot.selected {
  color: #3c57a6;
}

.btn-secondary1 {
  padding: 8px 20px;
  border-radius: 0px;
  color: #ed98d2;
  font-size: 14px;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  border: 1px solid #3c57a6 !important;
  font-weight: 500;
  color: #3c57a6 !important;
}

.btn-secondary1:hover {
  background-color: #3c57a6 !important;
  color: #ffffff !important;
}

.customer-container {
  padding: 15px 30px 25px;
}

.enableborder {
  background: white;
  font-weight: bold;
  border: 3px solid #0c5798;
  width: 112%;
  color: #0c5798;
}

.disableborder {
  color: gray;
  width: 112%;
  border: 3px solid gray;
  background: #f9f9f9 !important;
}

.disableborder:hover {
  color: gray;
  width: 112%;
  border: 3px solid gray;
  background: #f9f9f9 !important;
}

.enableborder:hover {
  background: white;
  font-weight: bold;
  border: 3px solid #0c5798;
  width: 112%;
  color: #0c5798;
}

.alignaddressbtn {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .alignaddressbtn {
    display: unset;
    position: relative;
    top: 5px;
  }
  .disableborder {
    width: 65% !important;
    margin-left: 17%;
  }
  .enableborder {
    width: 65% !important;
    margin-left: 17%;
  }
  .alignor {
    margin-left: 0px !important;
    margin-top: 6px !important;
  }
}
.alignor {
  margin-left: 20px;
  margin-top: 8px;
}

.custom-link {
  margin-bottom: 10px;
  color: #3c57a6 !important;
  font-weight: bold;
  cursor: pointer;
}

.forgot-link {
  color: #3c57a6 !important;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5px;
}

@media screen and (min-width: 600px) {
  .custom-radios {
    margin-left: 20px;
  }
}
.custom-radio-text {
  color: #3c57a6;
}

.login-link-color {
  color: #3c57a6 !important;
}

.checkout_login_details {
  border-right: 1px solid rgb(235, 234, 234);
  padding-left: 20px;
  padding-right: 20px;
}

.margin_changetop {
  margin-top: 31px;
}

@media screen and (max-width: 600px) {
  .custom-radios {
    margin-left: 0px;
  }
  .customer-container {
    padding: 0px 0px 20px !important;
  }
  .eye-mob {
    position: absolute;
    margin-top: -20%;
    left: 77%;
  }
  .checkout-forms {
    margin-right: 0px;
    font-size: 12px;
  }
  .checkout-forms .invalid-feedback {
    font-size: 12px !important;
  }
  .checkout-forms label {
    margin-bottom: 15px;
    position: relative;
    border-bottom: 1px solid #ddd;
    width: 96%;
    margin-bottom: 0px !important;
  }
}
.order-summary .summary-card {
  margin: 20px 0px !important;
  height: auto !important;
  background-color: #ffffff !important;
}
.order-summary .summary-header {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #777;
  padding: 1rem 1rem 0px 1rem;
}
.order-summary .summary-container {
  border-top: solid 1px #cdcdcd;
  padding: 15px 1rem !important;
  margin-top: 15px;
  margin-bottom: 0px;
}
.order-summary .summary-label p {
  font-size: 14px !important;
}
.order-summary .card-body {
  padding: 0px !important;
}
.order-summary .summary-row {
  padding: 8px 0px !important;
}
.order-summary .summary-total-show {
  font-weight: bold;
  border-top: 1px solid #cdcdcd;
}

.floating-forms {
  margin-top: 20px;
  margin-right: 25px;
  font-size: 12px;
  /****  floating-Lable style start ****/
  /* active state */
  /* highlighter */
  /* active state */
}
.floating-forms .invalid-feedback {
  font-size: 12px !important;
}
.floating-forms .floating-label {
  position: relative;
  margin-bottom: 25px;
  margin-top: 20px;
}
.floating-forms .floating-select {
  font-size: 14px;
  padding: 4px 0px;
  display: block;
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ddd;
}
.floating-forms .floating-input {
  font-size: 14px;
  padding: 4px 0px;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ddd !important;
}
.floating-forms .floating-input:focus, .floating-forms .floating-select:focus {
  outline: none;
  border-bottom: 1px solid #ddd;
}
.floating-forms .form-control {
  border-bottom: 1px solid #ddd !important;
}
.floating-forms .form-control:focus {
  border-bottom: 1px solid #ddd !important;
}
.floating-forms label {
  font-size: 0.825em;
  border-bottom: none !important;
  color: #777;
  font-weight: 600;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.floating-forms .floating-input:focus ~ label, .floating-forms .floating-input:not(:placeholder-shown) ~ label {
  top: -18px;
  color: #3c57a6;
}
.floating-forms .floating-select:focus ~ label, .floating-forms .floating-select:not([value=""]):valid ~ label {
  top: -18px;
  color: #3c57a6;
}
.floating-forms .floating-input:focus ~ .bar:before, .floating-forms .floating-input:focus ~ .bar:after, .floating-forms .floating-select:focus ~ .bar:before, .floating-forms .floating-select:focus ~ .bar:after {
  width: 50%;
}
.floating-forms *, .floating-forms *:before, .floating-forms *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.floating-forms .floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}
.floating-forms .highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.custom-datepicker label {
  font-size: 0.825em;
  border-bottom: none !important;
  color: #3c57a6;
  font-weight: 600;
  pointer-events: none;
}
.custom-datepicker .dp-input-container {
  border-bottom: 1px solid #ddd !important;
  font-size: 14px !important;
}
.custom-datepicker dp-date-picker.dp-material .dp-picker-input {
  font-size: 14px !important;
  color: #495057 !important;
}
.custom-datepicker .form-control {
  border: none;
  font-size: 14px !important;
  padding: 0px !important;
}

.shipping-card {
  font-size: 12px !important;
  background-color: #ffffff;
}

.shipping-card-active {
  background-color: #EDE8CA;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.clickable-tag {
  font-size: 14px !important;
  color: #3c57a6;
  cursor: pointer;
  font-weight: 400;
}

.add-new-address {
  padding: 15px 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 5px;
}

.shipping-font {
  font-size: 14px !important;
}

.shipping-font1 {
  font-size: 14px !important;
}

.checkout-orderdetails-card {
  border-bottom: 1px solid #cdcdcd;
  padding: 10px 0px;
  vertical-align: middle;
}

.orderdetails-image {
  height: 70px;
  width: 130%;
  object-fit: contain;
}

.orderdetails-btn {
  background-color: #3c57a6;
  padding: 3px 5px;
  color: #ffffff;
  font-size: 15px !important;
}

.orderdetails-btn1 {
  background-color: #3c57a6;
  padding: 3px 5px;
  color: #ffffff;
  font-size: 15px !important;
}

.order-actions {
  cursor: pointer;
  margin: 0px 10px;
  font-size: 15px !important;
  font-weight: bold !important;
}

.order-count {
  padding: 0px 10px;
}

.checkout-orders .card {
  margin: 0px !important;
}
.checkout-orders .havencard {
  margin: 0px !important;
}
.checkout-orders .card-header {
  padding: 0px !important;
  padding-left: 10px !important;
  background-color: #ffffff !important;
  border: none !important;
}
.checkout-orders .btn:focus {
  box-shadow: none !important;
}
.checkout-orders .card-body {
  padding: 0px 25px !important;
  background-color: #ffffff !important;
  border: none !important;
}
.checkout-orders .accordion {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px 10px !important;
}

@media screen and (max-width: 600px) {
  .orderdetails-btn {
    width: 70%;
    position: relative;
    left: 5px;
    justify-content: center;
  }
  .orderdetails-btn1 {
    width: 68%;
    position: relative;
    /* align-content: center; */
    justify-content: center;
  }
}
.login-newcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.login-newcard {
  border-radius: 1rem;
  padding: 10px 15px;
}

.login-newcard::before {
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  content: "";
  background: #3c57a6;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.login-newcard::after {
  position: absolute;
  content: attr(data-label);
  top: 11px;
  right: -14px;
  padding: 0.5rem;
  width: 10rem;
  background: #3c57a6;
  color: white;
  text-align: center;
  box-shadow: 4px 4px 15px rgba(26, 25, 126, 0.2);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.surprise-continer {
  background-color: #3c57a6;
}

.title {
  margin-top: 10px;
  font-size: clamp(36px, 0.6rem, 100px);
  color: #fff;
  text-shadow: 2px 2px #d54604;
}

[class^=firework-] {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
}

.firework-1 {
  animation: firework-lg 1.2s both infinite;
  animation-delay: 1.4s;
  top: 90%;
  left: 15%;
}

.firework-2 {
  animation: firework-lg 1.2s both infinite;
  animation-delay: 0.4s;
  top: 30%;
  left: 80%;
}

.firework-3 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 0.8s;
  top: 15%;
  left: 70%;
}

.firework-4 {
  animation: firework-lg 1.2s both infinite;
  animation-delay: 0.8s;
  top: 15%;
  left: 45%;
}

.firework-5 {
  animation: firework-sm 1.2s both infinite;
  animation-delay: 0.6s;
  top: 55%;
  left: 50%;
}

.firework-6 {
  animation: firework-sm 1.2s both infinite;
  animation-delay: 1s;
  top: 85%;
  left: 60%;
}

.firework-7 {
  animation: firework-sm 1.2s both infinite;
  animation-delay: 1.1s;
  top: 25%;
  left: 15%;
}

.firework-8 {
  animation: firework-sm 1.2s both infinite;
  animation-delay: 0.1s;
  top: 80%;
  left: 55%;
}

.firework-9 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 0.5s;
  top: 55%;
  left: 65%;
}

.firework-10 {
  animation: firework-lg 1.2s both infinite;
  animation-delay: 0.2s;
  top: 65%;
  left: 35%;
}

.firework-11 {
  animation: firework-sm 1.2s both infinite;
  animation-delay: 0.6s;
  top: 90%;
  left: 40%;
}

.firework-12 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 0.6s;
  top: 50%;
  left: 50%;
}

.firework-13 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 0.9s;
  top: 45%;
  left: 55%;
}

.firework-14 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 0.6s;
  top: 15%;
  left: 15%;
}

.firework-15 {
  animation: firework-md 1.2s both infinite;
  animation-delay: 1.3s;
  top: 45%;
  left: 45%;
}

@keyframes firework-sm {
  0%, 100% {
    opacity: 0;
  }
  10%, 70% {
    opacity: 1;
  }
  100% {
    box-shadow: -0.5rem 0rem 0 #fff, 0.5rem 0rem 0 #fff, 0rem -0.5rem 0 #fff, 0rem 0.5rem 0 #fff, 0.35rem -0.35rem 0 #fff, 0.35rem 0.35rem 0 #fff, -0.35rem -0.35rem 0 #fff, -0.35rem 0.35rem 0 #fff;
  }
}
@keyframes firework-md {
  0%, 100% {
    opacity: 0;
  }
  10%, 70% {
    opacity: 1;
  }
  100% {
    box-shadow: -0.7rem 0rem 0 #fff, 0.7rem 0rem 0 #fff, 0rem -0.7rem 0 #fff, 0rem 0.7rem 0 #fff, 0.49rem -0.49rem 0 #fff, 0.49rem 0.49rem 0 #fff, -0.49rem -0.49rem 0 #fff, -0.49rem 0.49rem 0 #fff;
  }
}
@keyframes firework-lg {
  0%, 100% {
    opacity: 0;
  }
  10%, 70% {
    opacity: 1;
  }
  100% {
    box-shadow: -0.9rem 0rem 0 #fff, 0.9rem 0rem 0 #fff, 0rem -0.9rem 0 #fff, 0rem 0.9rem 0 #fff, 0.63rem -0.63rem 0 #fff, 0.63rem 0.63rem 0 #fff, -0.63rem -0.63rem 0 #fff, -0.63rem 0.63rem 0 #fff;
  }
}
.surprise-text {
  font-size: 20px;
  color: #ffffff;
  padding: 15px 20px !important;
}

.surprise-btn {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  padding: 3px 30px !important;
  background-color: #d54604;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 0px !important;
  }
}
.login-form {
  padding: 30px 25px;
  color: #3c57a6;
  /* animations */
}
.login-form .input-group {
  position: relative;
  margin: 40px 0 20px;
}
.login-form .btn-primary {
  background-color: #3c57a6 !important;
  color: #ffffff;
  margin-top: 15px;
  border: none;
}
.login-form .btn-secondary {
  background-color: #ffffff !important;
  border: 1px solid #777 !important;
}
.login-form .registration {
  margin-top: 30px !important;
  color: rgb(108, 108, 196) !important;
  cursor: pointer !important;
  font-weight: 500;
  font-size: 0.9rem;
}
.login-form .forgot-pswd {
  color: rgb(108, 108, 196) !important;
  cursor: pointer !important;
  font-weight: 500;
  font-size: 0.9rem;
}
.login-form .input-group-text {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #757575;
}
.login-form .login-sub-text1 {
  color: #777;
  font-size: 0.9rem;
}
.login-form input {
  font-size: 1rem;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
  color: #333333;
}
.login-form input:focus {
  outline: none;
}
.login-form label {
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.login-form input:focus ~ label,
.login-form input:valid ~ label {
  top: -20px;
  font-size: 1rem;
  color: #3c57a6;
}
.login-form .bar {
  position: relative;
  display: block;
  width: 315px;
}
.login-form .bar:before,
.login-form .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #3c57a6;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.login-form .bar:before {
  left: 50%;
}
.login-form .bar:after {
  right: 50%;
}
.login-form input:focus ~ .bar:before,
.login-form input:focus ~ .bar:after {
  width: 50%;
}
.login-form .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.login-form input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
.login-form input:focus {
  border: none;
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #3c57a6;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #3c57a6;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #3c57a6;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.login-container-fluid {
  padding: 0px;
}

.bg-login {
  min-height: 65vh;
}

.login-card {
  float: none;
  margin: auto;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  margin-top: 5%;
  margin-bottom: 5%;
}

.login-sub-text {
  color: #777;
  font-size: 0.9rem;
  padding-bottom: 45px;
}

.detail-part {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 40px 40px;
  padding-top: 25px;
  background-size: 100%;
}
.detail-part img {
  height: 220px;
  width: 250px;
}

.logn-part {
  padding: 30px 50px;
  padding-top: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #cdcdcd;
}

@media (max-width: 600px) {
  .logn-part {
    border-left: 0px solid !important;
  }
}
#sf-faq {
  padding: 10px 50px 30px 55px;
}
#sf-faq .accordion {
  min-width: 80%;
  max-width: 80%;
  margin-top: 30px;
}
#sf-faq .card {
  border: none;
  height: auto;
  margin-bottom: 15px;
}
#sf-faq .card .card-body {
  border: 1px solid #ede8ca;
}
#sf-faq .arrow {
  border: solid #333333;
  border-width: 0 1.8px 1.8px 0;
  display: inline-block;
  padding: 5px;
}
#sf-faq .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#sf-faq .menu-arrow {
  float: right;
  margin-right: 15px;
}
#sf-faq .btn:focus {
  box-shadow: none;
}

.sf-breadcrumb {
  color: #777;
  font-size: 1rem;
}

#myorders {
  padding: 15px 60px 20px 40px;
  background-color: #EDE8CA;
}
#myorders .filter-label {
  font-size: 1.1rem !important;
  color: #212529 !important;
}

.filter-section {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 10px 10px 25px;
}
.filter-heading {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 5px 0px;
}

.filter-subheading {
  font-size: 0.8rem;
  font-weight: 600;
}

.box {
  position: relative;
  margin: 30px auto;
  padding: 20px 20px 20px;
  width: 750px !important;
  height: auto;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  background-color: #ffffff;
}
@media (max-width: 767.98px) {
  .box {
    padding: 10px 30px;
    align-items: center;
    margin-bottom: 20px;
    width: 100% !important;
  }
}
.box .form-control {
  background-color: rgb(241, 243, 247);
}
.box .card {
  max-width: 45rem;
  padding: 0;
  border: none;
  border-radius: 0.5rem;
}
.box label {
  font-weight: 500;
}
.box .form-label {
  float: left !important;
}
.box ul {
  list-style: none;
  margin-top: 1rem;
  padding-inline-start: 0;
}

.customer-address-page .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.box1 {
  position: relative;
  margin: 30px auto;
  padding: 20px 20px 20px;
  width: 950px !important;
  height: auto;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  background-color: #ffffff;
}
@media (max-width: 767.98px) {
  .box1 {
    padding: 10px 30px;
    align-items: center;
    margin-bottom: 20px;
    width: 100% !important;
  }
}
.box1 .form-control {
  background-color: rgb(241, 243, 247);
}
.box1 .card {
  max-width: 45rem;
  padding: 0;
  border: none;
  border-radius: 0.5rem;
}
.box1 label {
  font-weight: 500;
}
.box1 .form-label {
  float: left !important;
}
.box1 ul {
  list-style: none;
  margin-top: 1rem;
  padding-inline-start: 0;
}

.customer-address-page .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

@media screen and (max-width: 600px) {
  .mob-font-13 {
    font-size: 13px !important;
  }
  .mob-address {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }
}
.profile-page .heading {
  font-size: 24px;
}
.profile-page .form-control {
  background-color: white;
}
.profile-page .form-label {
  padding-top: 4px;
}
.profile-page .form-control.edit {
  font-weight: bolder;
  color: #ffffff !important;
  background-color: #3c57a6 !important;
}
.profile-page small {
  font-size: 90%;
}
.profile-page p {
  padding-top: 10px;
  align-items: center;
  padding-left: 90px;
}
.profile-page input[type=text]:focus, .profile-page input[type=email]:focus, .profile-page input[type=password]:focus {
  border: 2px, solid;
}
.profile-page .custom-select {
  background-color: #dadada;
}
.profile-page .pad {
  margin-right: -70px;
}

.my-orders .box-orders {
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 35px 15px;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  width: 98%;
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  .box-orders {
    transform: scale(0.7);
    position: relative;
    left: -19%;
    width: 138% !important;
  }
}
@media screen and (max-width: 374px) {
  .box-orders {
    transform: scale(0.7);
    position: relative;
    left: -19%;
  }
}
.order-details-page {
  /* Checkboxes and Radios */
}
.order-details-page .verticalBar {
  padding: 1%;
  font-size: 25px;
  font-weight: 100;
  color: #777;
}
.order-details-page .align-left {
  text-align: left;
}
.order-details-page .align-right {
  text-align: right;
}
.order-details-page .card-title {
  color: black;
  font-weight: bold;
  display: inline-block;
}
.order-details-page .fa-pencil-square-o {
  float: right;
}
.order-details-page .card-body {
  background-color: white;
}
.order-details-page .logo-header {
  font-size: 35px;
  margin-bottom: 0px !important;
}
.order-details-page .preview-img {
  width: 175px;
  height: 175px;
  border: 1px solid black;
}
.order-details-page .breadcrumb {
  border: none;
  padding-left: 0px;
}
.order-details-page .breadcrumb .breadcrumb-item a {
  color: #000000;
}
.order-details-page .breadcrumb .breadcrumb-item.active {
  color: #777;
}
.order-details-page .upload-form {
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 35px 15px;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  width: 100%;
  background-color: #ffffff;
}
@media (max-width: 767.98px) {
  .order-details-page .upload-form {
    padding: 10px 30px;
    align-items: center;
    margin-bottom: 20px;
  }
}
.order-details-page .upload-form-label {
  float: right;
  margin-right: 10px;
  padding-top: 4px;
  color: #313131;
}
@media (max-width: 767.98px) {
  .order-details-page .upload-form-label {
    float: left;
    margin-right: none;
    padding-top: 0px;
  }
}
@media (max-width: 767.98px) {
  .order-details-page .upload-buttons {
    padding-top: 15px;
  }
}
.order-details-page .form-control {
  background-color: white !important;
}
.order-details-page .copyright-section {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.order-details-page select.form-control {
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid #dee2e6;
  color: #6c757d;
}
.order-details-page select.form-control:focus {
  outline: 1px solid #dee2e6;
}
.order-details-page select.form-control.border-primary {
  outline: 1px solid #3c57a6;
}
.order-details-page select.form-control.border-primary:focus {
  outline: 1px solid #3c57a6;
}
.order-details-page select.form-control.border-secondary {
  outline: 1px solid #ed98d2;
}
.order-details-page select.form-control.border-secondary:focus {
  outline: 1px solid #ed98d2;
}
.order-details-page select.form-control.border-success {
  outline: 1px solid rgb(34, 34, 34);
}
.order-details-page select.form-control.border-success:focus {
  outline: 1px solid rgb(34, 34, 34);
}
.order-details-page select.form-control.border-info {
  outline: 1px solid #3c57a6;
}
.order-details-page select.form-control.border-info:focus {
  outline: 1px solid #3c57a6;
}
.order-details-page select.form-control.border-warning {
  outline: 1px solid #ebaa05;
}
.order-details-page select.form-control.border-warning:focus {
  outline: 1px solid #ebaa05;
}
.order-details-page select.form-control.border-danger {
  outline: 1px solid #ef1d3c;
}
.order-details-page select.form-control.border-danger:focus {
  outline: 1px solid #ef1d3c;
}
.order-details-page select.form-control.border-light {
  outline: 1px solid #f5f5f5;
}
.order-details-page select.form-control.border-light:focus {
  outline: 1px solid #f5f5f5;
}
.order-details-page select.form-control.border-dark {
  outline: 1px solid #343a40;
}
.order-details-page select.form-control.border-dark:focus {
  outline: 1px solid #343a40;
}
.order-details-page .form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 1.25rem;
}
.order-details-page .form-check .form-check-label {
  min-height: 18px;
  display: block;
  margin-left: 0.625rem;
  line-height: 1.5;
  color: #6c757d;
}
.rtl .order-details-page .form-check .form-check-label {
  margin-left: 0;
  margin-right: 1.75rem;
}
.order-details-page .form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.rtl .order-details-page .form-check .form-check-label input {
  left: auto;
  right: 0;
}
.order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:before, .order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}
.rtl .order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:before, .rtl .order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:after {
  left: auto;
  right: 0;
}
.order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid #3c57a6;
  border-width: 2px;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.order-details-page .form-check .form-check-label input[type=checkbox] + .input-helper:after {
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: "Material Design Icons";
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "\f12c";
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
}
.order-details-page .form-check .form-check-label input[type=checkbox]:checked + .input-helper:before {
  background: #3c57a6;
  border-width: 0;
}
.order-details-page .form-check .form-check-label input[type=checkbox]:checked + .input-helper:after {
  width: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  color: black;
}
.order-details-page .form-check .form-check-label input[type=checkbox]:disabled + .input-helper:before {
  border-color: #dee2e6;
}
.order-details-page .form-check .form-check-label input[type=checkbox]:disabled:checked + .input-helper:after {
  background: #dee2e6;
  color: #ffffff;
}
.order-details-page .form-check .form-check-label input[type=radio] + .input-helper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: solid #3c57a6;
  border-width: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.rtl .order-details-page .form-check .form-check-label input[type=radio] + .input-helper:before {
  left: auto;
  right: 0;
}
.order-details-page .form-check .form-check-label input[type=radio] + .input-helper:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
}
.rtl .order-details-page .form-check .form-check-label input[type=radio] + .input-helper:after {
  left: auto;
  right: 6px;
}
.order-details-page .form-check .form-check-label input[type=radio]:checked + .input-helper:before {
  background: #3c57a6;
  border-width: 0;
}
.order-details-page .form-check .form-check-label input[type=radio]:checked + .input-helper:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.order-details-page .form-check .form-check-label input[type=radio]:disabled + .input-helper:before {
  border-color: #dee2e6;
}
.order-details-page .form-check .form-check-label input[type=radio]:disabled:checked + .input-helper:before {
  background: #dee2e6;
}
.order-details-page .form-check .form-check-label input[type=radio]:disabled:checked + .input-helper:after {
  background: #ffffff;
}

.forgot-page .box {
  position: relative;
  margin: 30px auto;
  padding: 20px 20px 20px;
  width: 450px;
  height: auto;
  background: white;
  border-radius: 7px;
  border-color: #D3D3D3;
  border-width: thin;
  border-style: solid;
  font-size: 14px;
}
.forgot-page .heading {
  font-size: 24px;
}
.forgot-page .ecbee-logo {
  width: 250px;
  height: 100px;
}
@media (max-width: 767.98px) {
  .forgot-page .ecbee-logo {
    width: 250px;
    height: 70px;
    background-color: #ffffff;
    margin-top: 10px;
  }
}
.forgot-page h1 {
  font-weight: bold;
  color: #010203;
  letter-spacing: 1px;
}
.forgot-page .form-control {
  background-color: white;
}
.forgot-page .loginLink {
  cursor: pointer;
}
.forgot-page small {
  font-size: 90%;
}
.forgot-page p {
  padding-top: 10px;
  align-items: center;
  padding-left: 90px;
}
.forgot-page input[type=text]:focus, .forgot-page input[type=email]:focus, .forgot-page input[type=password]:focus {
  border-color: #ece3e3;
  border: 2px, solid;
}
.forgot-page .custom-select {
  background-color: #dadada;
}
.forgot-page .pad {
  margin-right: -70px;
}
.forgot-page .help-text {
  font-size: 0.8rem;
  color: #7c7c7c;
}
.forgot-page .reveal-if-active {
  opacity: 1;
  transition: 0.5s;
  overflow: visible;
}
.forgot-page .hide-if-inactive {
  opacity: 0;
  max-height: 0;
  transition: 0.5s;
  transform: scale(0.8);
  overflow: hidden;
}

.box {
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 35px 15px;
  -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
  box-shadow: 0 0 4px rgb(218, 216, 216);
  border-radius: 5px;
  width: 100%;
  background-color: #ffffff;
}

.order-confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.order-circle {
  width: 20px;
  height: 20px;
  border: 2px solid #90ba4c;
  border-radius: 50%;
}

.thanks {
  font-size: 20px;
  font-weight: bolder;
}

.order-info {
  font-size: medium;
}
.order-info a:hover {
  cursor: pointer;
}
.order-info span {
  font-size: large;
}

.order-confirm-page .mt-2.inq-cart-pay-st.img-size img {
  width: 100px;
  height: 31px;
}
.order-confirm-page #emailinput {
  border-radius: 10px !important;
}
@media screen and (max-width: 600px) {
  .order-confirm-page .inq-cart-ul-st li.nav-item {
    padding: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .order-confirm-page .inq-cart-ul-st li.nav-item {
    margin-bottom: 15px !important;
  }
}
.order-confirm-page .inq-btn-st {
  font-weight: 600 !important;
}
.order-confirm-page .inq-mc-ordn1-st p img {
  margin: 0px 5px;
}
.order-confirm-page .inq-mc-ordn1-st p {
  line-height: 27px;
}
@media screen and (min-width: 1200px) {
  .order-confirm-page .card {
    height: 350px;
  }
}
.order-confirm-page .card {
  background: transparent;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
  border: none;
}
.order-confirm-page .front p {
  color: #323232 !important;
}
.order-confirm-page .front h5 {
  color: #323232 !important;
  font-size: 17.5px;
}
.order-confirm-page .card .front {
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  height: 100%;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.order-confirm-page .card .back {
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  font-size: 1.2em;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.order-confirm-page .card .front strong {
  background: #ffffff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}
.order-confirm-page .card .back {
  transform: rotateY(180deg);
}
@media screen and (max-width: 768px) {
  .order-confirm-page .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 90%;
  }
  .order-confirm-page .card {
    height: 385px;
  }
  .order-confirm-page .cardBox:last-child {
    margin-bottom: 3%;
  }
  .order-confirm-page .tick-image {
    position: absolute;
    /* right: 55%; */
    bottom: 4px;
  }
}
@media screen and (max-width: 1199px) and (min-width: 769px) {
  .order-confirm-page .card {
    height: 385px;
  }
  .order-confirm-page #innerpart {
    padding: 10px;
  }
  .order-confirm-page .tick-image {
    position: absolute;
    left: 35%;
    bottom: 4px;
  }
}
@media screen and (max-width: 767px) and (min-width: 481px) {
  .order-confirm-page .tick-image {
    position: absolute;
    right: 35%;
    bottom: 4px;
  }
}
@media screen and (max-width: 480px) {
  .order-confirm-page .cardBox {
    width: 94.5%;
  }
  .order-confirm-page .card {
    height: 170px;
  }
  .order-confirm-page .tick-image {
    position: absolute;
    right: 35% !important;
    bottom: 0px;
    width: 38px;
    height: 38px;
  }
}
.order-confirm-page .help-text {
  color: #807f7f;
}
.order-confirm-page .cutome-deliverylabel {
  margin-left: 0px !important;
}

@media screen and (max-width: 600px) {
  .orders-container-mob {
    margin-left: -5%;
    width: 106%;
    transform: scale(0.9);
  }
}
.rating {
  padding-left: 6px;
  float: left;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t 
  follow these rules. Every browser that supports :checked also supports :not(), so
  it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 300%;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: dodgerblue;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: dodgerblue;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: dodgerblue;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

a {
  text-decoration: none !important;
  color: inherit;
}

a:hover {
  color: #455A64;
}

.card3 {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  height: auto;
  border: 0.2px solid lightgray;
}

.cardlinen {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  height: auto;
  border: 0.2px solid lightgray;
}

.rating-box {
  width: 130px;
  height: 130px;
  margin-right: auto;
  margin-left: auto;
  background-color: #FBC02D;
  color: #fff;
}

.rating-label {
  font-weight: bold;
}

.rating-bar {
  width: 300px;
  padding: 8px;
  border-radius: 5px;
}

.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 5px;
}

#bar-5 {
  height: 13px;
  background-color: #FBC02D;
  border-radius: 20px;
}

#bar-4 {
  height: 13px;
  background-color: #FBC02D;
  border-radius: 20px;
}

#bar-3 {
  height: 13px;
  background-color: #FBC02D;
  border-radius: 20px;
}

#bar-2 {
  height: 13px;
  background-color: #FBC02D;
  border-radius: 20px;
}

#bar-1 {
  height: 13px;
  background-color: #FBC02D;
  border-radius: 20px;
}

td {
  padding-bottom: 10px;
}

.star-active {
  color: #FBC02D;
  margin-top: 10px;
  margin-bottom: 10px;
}

.star-active:hover {
  color: #F9A825;
  cursor: pointer;
}

.star-inactive {
  color: #CFD8DC;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blue-text {
  color: #0091EA;
}

.content {
  font-size: 18px;
}

.profile-pic {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  margin-right: 30px;
}

.pic {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.vote {
  cursor: pointer;
}

.rating {
  margin-right: auto;
  margin-left: auto;
}

.rating:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: #FBC02D;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #FBC02D;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #FBC02D;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.rating1 {
  margin-right: auto;
  margin-left: auto;
}

.rating1:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.rating1:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 100%;
  /* line-height:1.2; */
  color: #ddd;
}

.rating1:not(:checked) > label:before {
  content: "★ ";
}

.rating1 > input:checked ~ label {
  color: #FBC02D;
}

.rating1:not(:checked) > label:hover,
.rating1:not(:checked) > label:hover ~ label {
  color: #FBC02D;
}

.rating1 > input:checked + label:hover,
.rating1 > input:checked + label:hover ~ label,
.rating1 > input:checked ~ label:hover,
.rating1 > input:checked ~ label:hover ~ label,
.rating1 > label:hover ~ input:checked ~ label {
  color: #FBC02D;
}

.rating1 > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.inner-review img {
  position: relative;
  max-width: 74%;
}

.inner-review p {
  left: 18%;
  position: absolute;
  top: 17%;
}

.inner-review {
  position: relative;
}

.checked {
  color: #fdb915;
}

.unchecked {
  color: rgb(215, 212, 212);
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .card3 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.bs4-order-tracking {
  margin-bottom: 30px;
  overflow: visible;
  color: #878788;
  padding-left: 0px;
  margin-top: 30px;
}

.bs4-order-tracking li {
  list-style-type: none;
  font-size: px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  color: #878788;
  text-align: center;
}

.bs4-order-tracking li:first-child:before {
  margin-left: 15px !important;
  padding-left: 11px !important;
  text-align: left !important;
}

.bs4-order-tracking li:last-child:before {
  margin-right: 5px !important;
  padding-right: 11px !important;
  text-align: right !important;
}

.bs4-order-tracking li > div {
  color: #fff;
  width: 29px;
  text-align: center;
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: #878788;
  border-radius: 50%;
  margin: auto;
}

.bs4-order-tracking li:after {
  content: "";
  width: 150%;
  height: 2px;
  background: #878788;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 15px;
  z-index: -1;
}

.bs4-order-tracking li:first-child:after {
  left: 50%;
}

.bs4-order-tracking li:last-child:after {
  left: 0% !important;
  width: 0% !important;
}

.bs4-order-tracking li.active {
  font-weight: bold;
  color: #46c35f;
}

.bs4-order-tracking li.active > div {
  background: #46c35f;
}

.bs4-order-tracking li.active:after {
  background: #46c35f;
}

.card-timeline {
  background-color: #fff;
  z-index: 0;
}

.timelineCard {
  background: transparent;
  cursor: default;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
  border: none;
}

.order-traccking-st {
  padding-left: 30px;
}
.order-traccking-st .hh-grayBox {
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}
.order-traccking-st .pt45 {
  padding-top: 45px;
}
.order-traccking-st .order-tracking {
  text-align: center;
  width: 19%;
  position: relative;
  display: block;
}
.order-traccking-st .order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #AFAFAF;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
.order-traccking-st .order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.order-traccking-st .order-tracking.completed .is-complete {
  border-color: #3c57a6;
  border-width: 0px;
  background-color: #3c57a6;
}
.order-traccking-st .order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
.order-traccking-st .order-tracking p {
  color: #A4A4A4;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}
.order-traccking-st .order-tracking p span {
  font-size: 11px;
}
.order-traccking-st .order-tracking.completed p {
  color: #000;
}
.order-traccking-st .order-tracking::before {
  content: "";
  display: block;
  height: 3px;
  width: calc(100% - 40px);
  background-color: #f7be16;
  top: 13px;
  position: absolute;
  left: calc(-50% + 20px);
  z-index: 0;
}
.order-traccking-st .order-tracking:first-child:before {
  display: none;
}
.order-traccking-st .order-tracking.completed:before {
  background-color: #3c57a6;
}

.order-details-container {
  display: flex;
  width: 33%;
}

.order-details-title {
  margin-left: 5%;
  width: 100%;
  height: 80px;
}

.war-mob {
  width: 60% !important;
  margin-left: 20%;
}

@media screen and (max-width: 600px) {
  .order-traccking-st {
    padding-left: 0px !important;
  }
  .order-details-container {
    width: 100%;
  }
  .order-details-title {
    height: 40px;
  }
  .war-mob {
    width: 50% !important;
    margin-left: 25%;
  }
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.karupattiraja-product-list-cards {
  background-color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
  border: solid;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
}

.product-list-tilekub {
  height: 1.5vw;
  font-family: FontAwesome;
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 200 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
}

.product-list-tilevimpro {
  white-space: nowrap;
  font-family: FontAwesome;
  font-size: 20px !important;
  color: #2554C7 !important;
  font-weight: 200 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
}

.product-list-tile-mayan {
  font-size: 13px;
  color: #3c707c !important;
  font-weight: 600 !important;
}

.thumb3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.thumb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.thumb1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px;
}

.thumb1-deera {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.thumb2-deera {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 7%;
}

.thumb1-kbook {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (min-width: 601px) and (max-width: 1280px) {
  .thumb1-kbook {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  /* CSS rules for medium devices */
}
@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
  .thumb2-deera {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 9%;
  }
}
@media only screen and (min-device-width: 1600px) and (max-device-width: 1920px) {
  .thumb2-deera {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: 10%;
  }
}
.thumb1-avon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 20px;
}

.thumb1-special {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.div-only-mobile {
  display: none;
}

.desk {
  display: block;
}

.thumbkub {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.deera-img {
  text-align: center;
}

.special-img {
  text-align: center;
}

.special-img1 {
  text-align: center;
}

.deera-atc {
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-top: 11%;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  background-color: grey;
}

.deera-atc1 {
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-top: 12px;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  background-color: grey;
}

.avon-atc {
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  background-color: grey;
}

.avon-atc1 {
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  background-color: grey;
}

.special-atc {
  font-size: small;
  font-weight: bold;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #5c7fad !important;
  color: #091214 !important;
  background-color: grey;
}

.vistas-atc {
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #022a19 !important;
  color: #091214 !important;
  background-color: grey;
}

.vistas-atc1 {
  border-radius: 17px;
  font-size: small;
  font-weight: 700;
  width: 45%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 2px solid #4e90b2 !important;
  color: #091214 !important;
  background-color: gray;
}

.special-atc1 {
  font-size: small;
  font-weight: bold;
  width: 67%;
  margin-left: 17%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #003b85 !important;
  color: #091214 !important;
  background-color: grey;
}

@media only screen and (max-width: 768px) {
  .thumbkub {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .thumb1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 20px;
  }
  .thumb {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important;
  }
  .thumb1-deera {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important;
  }
  .thumb2-deera {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important;
  }
  .thumb1-kbook {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important;
  }
  .thumb1-avon {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important;
  }
  .thumb1-special {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .desk {
    display: none;
  }
  .div-only-mobile {
    display: block;
  }
  .deera-img {
    width: 40%;
  }
  .special-img {
    width: 50%;
  }
  .special-img1 {
    width: 80%;
    margin-top: 25px;
  }
  .product-list-description-deera {
    width: 60%;
  }
  .deera-atc {
    width: 60%;
  }
  .deera-atc1 {
    width: 60%;
  }
  .review-mob1 {
    width: 41%;
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob {
    width: 75%;
    height: 10px;
  }
  .review-mob2 {
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob3 {
    transform: scale(0.8);
    height: 10px;
    margin-left: -10px;
    width: 35%;
  }
  .deera-container {
    width: 100%;
  }
}
@media screen and (max-width: 391px) {
  .deera-atc {
    width: 70%;
  }
  .deera-atc1 {
    width: 70%;
  }
}
.thumb-content {
  text-align: left;
}

.price-area {
  margin-top: 4%;
}

.cart-buttons {
  float: right;
}

.product-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.product-description-bh {
  width: 250px;
}

@media (max-width: 1920px) {
  .product-list-price1 {
    font-size: 18px;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
.product-list-tile1 {
  font-size: 14px !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  display: inline-block;
  font-size: 190px;
}

@media (min-width: 360px) and (max-width: 600px) {
  .karupattiraja-product-list-cards {
    background-color: white;
    border: none;
    border-radius: 10px;
    min-width: 92vw !important;
    max-width: 92vw !important;
    min-height: 150px !important;
    max-height: 150px !important;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2), 4px 4px 4px 4px rgba(0, 0, 0, 0.19);
    display: flex !important;
  }
  .karupattiraja-product-list-cards .product-list-price1 {
    padding-left: 1px !important;
    font-size: 14px;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
.product-list-tile-special {
  font-size: 16px !important;
  color: #0095da !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.avon-foot-img {
  padding: 8px;
  object-fit: contain;
  height: 140px;
  width: 185px;
}

.product-list-tile-avon {
  font-size: 16px !important;
  color: #c50 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tile-vistas {
  font-size: 16px !important;
  color: #296c8e !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tile-cle {
  font-size: 16px !important;
  color: #883a01 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tile-deera {
  font-size: 16px !important;
  color: #000 !important;
  height: 75px;
  font-weight: 600 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tile-deera1 {
  font-size: 16px !important;
  color: #000 !important;
  height: 52px;
  font-weight: 600 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .product-list-tilevimpro {
    font-family: FontAwesome;
    font-size: 17px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }
  .product-list-tilekub {
    font-family: FontAwesome;
    font-size: 30px !important;
    color: #2554C7 !important;
    font-weight: 200 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
  }
  .product-list-tile1 {
    font-size: 13px !important;
    font-weight: 700 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 190px;
  }
  .product-list-tile-deera {
    font-size: 14px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
  }
  .product-list-tile-deera1 {
    font-size: 14px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 42px;
  }
  .product-list-price1 {
    padding-left: 1px !important;
    font-size: 14px;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 780px) and (max-width: 1920px) {
  .product-list-descriptionkub {
    min-width: max-content;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 1920px) {
  .product-list-tile2 {
    height: 50px;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }
  .product-list-price2 {
    font-size: 20px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
  .rating1 {
    font-size: 15px !important;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .product-list-descriptionkub {
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
  }
  .product-list-tile2 {
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }
  .product-list-price2 {
    font-size: 12px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
  .rating1 {
    font-size: 12px !important;
  }
}
.product-list-description1 {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}

.product-list-description-deera {
  padding: 20px 15px;
  margin-bottom: 10px;
}

.product-list-descriptionkub {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  margin-top: -35px;
}

.product-list-image1 {
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
}

.product-list-image-deera {
  min-height: 270px;
  max-height: 270px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.product-list-image-avonseals {
  min-height: 275px;
  max-height: 275px;
  width: 100% !important;
  border-radius: 10px;
  object-fit: contain;
}

.other-cart {
  width: 50%;
}

.product-list-image-specialwires1 {
  min-height: 190px;
  max-height: 270px;
  width: 81% !important;
  border-radius: 10px;
  object-fit: contain;
}

.product-list-imagekub {
  max-height: 240px !important;
  width: 80% !important;
  border-radius: 10px !important;
  object-fit: contain;
}

.listing {
  border-radius: 6px !important;
  padding: 5px 20px !important;
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.collection-filter {
  background-color: #fff;
}
.collection-filter .filter-top {
  border-bottom: 1px solid #dddddd;
}
.collection-filter .filter-top .filter-icon {
  display: none;
}
.collection-filter .filter-top .filter-clear {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.collection-filter .layerd-navigation {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}
.collection-filter .layerd-navigation .collection-collapse-block {
  margin-bottom: 0px;
}
.collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content {
  padding-left: 30px;
}
.collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options {
  margin: 8px 0px 8px 0px;
}
.collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options input {
  margin-right: 10px;
}
.collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options label {
  margin-bottom: 0px;
  font-size: 15px;
}
.collection-filter .layerd-navigation .collection-collapse-block .collection-collapse-block-content .options label .count {
  color: #afabab;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .filter-top {
    float: right;
    position: absolute;
    left: 75%;
    margin-top: -10px;
    top: 0%;
    border: none !important;
  }
  .filter-top .filter-icon {
    display: inline !important;
  }
  .thumb {
    grid-template-columns: repeat(1, 1fr);
    width: 111%;
    margin-left: -25px;
  }
  .product-description {
    width: 100% !important;
    display: grid;
    white-space: break-spaces;
  }
}
@media (max-width: 1920px) {
  .cartButton {
    font-size: small;
    font-weight: bold;
    margin-left: 25%;
    margin-top: 4px;
    width: 32%;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButtonvar {
    font-size: small;
    font-weight: bold;
    margin-left: 25%;
    margin-top: 4px;
    width: 100%;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1 {
    font-size: small;
    font-weight: bold;
    width: 152%;
    margin-left: 99%;
    margin-top: 4px;
    text-align: center;
  }
  .cartButton2 {
    font-size: small;
    font-weight: bold;
    width: 45%;
    margin-left: 25%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c2826 !important;
    color: #091214 !important;
  }
  .product-list-image1 {
    min-height: 270px !important;
    max-height: 270px !important;
    width: 100% !important;
    border-radius: 10px !important;
    object-fit: contain;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .mobile-confirm {
    margin-left: 11px;
    display: flex;
    justify-content: center;
  }
  .gen-confirmbutton {
    width: 90px;
  }
  .cartButton {
    font-size: small;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButtonvar {
    font-size: small;
    font-weight: bold;
    margin-left: 25%;
    margin-top: 4px;
    width: 100%;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1 {
    font-size: small;
    font-weight: bold;
    width: 152%;
    margin-left: 0%;
    margin-top: 4px;
    text-align: center;
  }
  .cartButton2 {
    font-size: small;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c2826 !important;
    color: #091214 !important;
  }
  .product-list-image1 {
    min-height: 170px !important;
    max-height: 170px !important;
    width: 100% !important;
    border-radius: 10px !important;
    object-fit: contain;
  }
  .product-list-image-deera {
    min-height: 195px;
    max-height: 195px;
    width: 100%;
    height: 150px;
  }
  .product-list-image-avonseals {
    min-height: 119px;
    max-height: 200px;
    width: 100%;
    height: 100%;
  }
  .product-list-image-specialwires {
    min-height: 133px;
    max-height: 200px;
    width: 100% !important;
  }
}
@media screen and (max-width: 640px) {
  .vistas-atc {
    font-size: small;
    font-weight: bold;
    width: 80%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #022a19 !important;
    color: #091214 !important;
    background-color: grey;
  }
  .vistas-atc1 {
    border-radius: 19px;
    font-size: small;
    font-weight: 700;
    width: 80%;
    margin-top: 4px;
    border: 2px solid #4e90b2 !important;
    color: #091214 !important;
  }
  .avon-foot-img {
    padding: 8px;
    object-fit: contain;
    height: 140px;
    width: 300px;
    margin-left: 10%;
  }
  .avon-foot-img1 {
    padding: 8px;
    object-fit: contain;
    height: 140px;
    width: 300px;
  }
  .avon-atc {
    width: 74%;
    margin-left: 13%;
  }
  .avon-atc1 {
    margin-left: 0%;
    width: 94%;
  }
  .special-atc {
    margin-left: -3%;
    bottom: 46px;
    position: absolute;
  }
  .special-atc1 {
    width: 53%;
  }
}
.wrapper > .wrapper {
  margin-left: 10px;
  font-size: 95%;
}

.sub-list {
  cursor: pointer;
}

.sub-list2 {
  cursor: pointer;
}

.sub-list.active {
  font-weight: bold;
  color: #FBCB0C !important;
}

.sub-list2.active {
  font-weight: bold;
  color: #FBCB0C !important;
}

.sub-list1 {
  cursor: pointer;
}

.sub-list1.active {
  font-weight: bold;
  color: #ed98d2 !important;
}

.sublist1-font {
  font-size: 14px !important;
}
.sublist1-font :hover {
  color: #ed98d2 !important;
}

.sub-list-mob-aiema {
  cursor: pointer;
}

.sub-list-mob-aiema.active {
  font-weight: 900 !important;
  border-bottom: 1px solid #D57C30;
}

h5 {
  font-size: 16px !important;
  text-transform: lowercase;
}

h5:first-line {
  text-transform: capitalize;
}

.sublist-font {
  font-size: 14px !important;
}
.sublist-font :hover {
  color: #FBCB0C !important;
}

.titleproper {
  text-transform: lowercase;
}

.titleproper:first-line {
  text-transform: capitalize;
}

.checkbox-container {
  font-size: 15px !important;
}

.arrow-size {
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  #collapseFilter {
    transition: none;
  }
}
.variantBag .product-weight-card {
  padding: 2px 10px !important;
  border: 1px solid #3c57a6 !important;
  margin: 3px !important;
  border-radius: 50%;
  color: #3c57a6 !important;
  cursor: pointer !important;
}

div.checkRadioContainer > label > input {
  display: none;
}

div.checkRadioContainer {
  max-width: 10em;
}

div.checkRadioContainer > label > span {
  display: inline-block;
  vertical-align: top;
  line-height: 2em;
}

div.checkRadioContainer > label > input + span {
  visibility: visible;
  padding: 2px 10px !important;
  border: 1px solid #3c57a6 !important;
  margin: 3px !important;
  border-radius: 50%;
  color: #3c57a6 !important;
  cursor: pointer !important;
}

div.checkRadioContainer > label > input:checked + span {
  visibility: visible;
  background-color: #3c57a6 !important;
  border: 1px solid #3c57a6 !important;
  color: #ffffff !important;
  box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.variantBag.active {
  background-color: #3c57a6 !important;
  border: 1px solid #3c57a6 !important;
  color: #ffffff !important;
  box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-listing-container {
  margin: 10px 20px !important;
}

.product-list-card {
  background-color: rgba(244, 248, 249, 0.5411764706) !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border: none !important;
  height: auto !important;
}

.review-list-card {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none !important;
  height: 320px;
}

.product-list-image {
  min-height: 100%;
  max-height: 100%;
  width: 80% !important;
  border-radius: 10px !important;
  object-fit: contain;
}

@media (min-width: 360px) and (max-width: 600px) {
  .review-list-card {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: none !important;
    height: 320px !important;
    width: 90%;
    margin-left: 4%;
  }
  .product-list-image {
    border-radius: 10px !important;
    object-fit: contain !important;
    min-height: 150px !important;
    max-height: 150px !important;
    width: 100% !important;
    height: 150px !important;
  }
}
.product-list-description {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}

.product-list-description_karupatti {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 768px) {
  .review-section {
    margin-top: 46px !important;
  }
  .product-list-description {
    float: left;
    width: 100%;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
    position: relative;
    top: 25px;
  }
  .product-list-description_karupatti {
    float: left;
    width: 100%;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
    position: relative;
  }
}
.carience-detail-cart {
  white-space: nowrap;
  width: fit-content;
  position: relative;
  left: 30px !important;
  margin-top: 8px;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  font-size: small;
  top: 10px !important;
}

.vimpro-detail-cart {
  white-space: nowrap;
  width: fit-content;
  position: relative;
  left: 60px !important;
  margin-top: 8px;
  background-color: transparent !important;
  border: 3px solid #FEAE01 !important;
  color: #091214 !important;
  font-size: small;
  top: 10px !important;
}

.avon-detail-cart {
  white-space: nowrap;
  width: fit-content;
  position: relative;
  margin-top: 8px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  font-size: small;
  top: 10px !important;
}

.cle-detail-cart {
  white-space: nowrap;
  width: fit-content;
  position: relative;
  margin-top: 8px;
  background-color: transparent !important;
  border: 3px solid #883a01 !important;
  color: #091214 !important;
  font-size: small;
  top: 10px !important;
}

.product-list-tile {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.product-list-price {
  font-size: 16px !important;
  color: #d54604 !important;
  font-weight: 600 !important;
}

.product-weight-card {
  padding: 2px 10px !important;
  border: 1px solid #3c57a6 !important;
  margin: 3px !important;
  border-radius: 50%;
  color: #3c57a6 !important;
  cursor: pointer !important;
}

.product-weight-card:hover {
  background-color: transparent !important;
  color: #ffffff !important;
  box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-list-cart {
  font-size: 13px !important;
  color: #3c57a6 !important;
  font-weight: bold !important;
  cursor: pointer;
}

@media (max-width: 640px) {
  .avon-detail-cart {
    white-space: nowrap;
    width: fit-content;
    position: relative;
    left: 0px !important;
    margin-top: 8px;
    background-color: transparent !important;
    border: 3px solid #f33601 !important;
    color: #091214 !important;
    font-size: small;
    top: 10px !important;
  }
  .cle-detail-cart {
    white-space: nowrap;
    width: fit-content;
    position: relative;
    left: 0px !important;
    margin-top: -18px;
    background-color: transparent !important;
    border: 3px solid #883a01 !important;
    color: #091214 !important;
    font-size: small;
    top: 10px !important;
  }
}
.earth-worthy-logo {
  margin-left: 30px !important;
}

.earthy-worthy-carousel {
  height: 650px !important;
}

.earthy-feature-collections {
  background-color: #272829;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.subhcards-feature-collections {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.luckme-feature-collections {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#luckmebrands .tab-image {
  min-height: 300px;
  max-height: 300px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
}
#luckmebrands .tab-image .background-image {
  background-size: 100% 300px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  min-height: 300px;
}
#luckmebrands .tab-image .background-image:hover {
  transform: scale(1.2);
}
#luckmebrands .tab-image .bg-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 25px;
}
#luckmebrands .tab-image .bg-content p {
  font-size: 15px;
}

.luckme-tabimage {
  border: 1px solid rgb(194, 194, 194) !important;
  border-radius: 10px;
}

.earthy-tabimage {
  border: 1px solid rgb(194, 194, 194) !important;
  border-radius: 10px;
}

.shubcards-tabimage {
  border: 1px solid #CC5500 !important;
  border-radius: 20px;
}

.shubcards-tabimage1 {
  border: 1px solid #2554C7 !important;
  border-radius: 20px;
}

.earthy-card {
  margin: 15px !important;
  min-height: 340px;
  max-height: 380px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.earthy-image {
  min-height: 200px;
  max-height: 240px;
  padding: 10px;
}

.earthy-description {
  padding: 10px;
  min-height: 100px;
  max-height: 140px;
  background-color: #ffffff;
}

.earthy-product-title {
  color: #3c57a6;
  font-weight: 500;
  font-size: 15px;
}

.earthy-worthy-price {
  padding-top: 5px;
  font-weight: 500;
  font-size: 15px;
}

.earthy-cart-btn {
  display: none;
}

.earthyworthy-footer {
  color: #212529 !important;
}

.luckme-card {
  margin: 5px !important;
  min-height: 350px;
  max-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.luckme-image {
  min-height: 220px;
  max-height: 220px;
  padding: 10px;
}

.luckme-description {
  padding: 10px;
  min-height: 130px;
  max-height: 130px;
  background-color: #ffffff;
}

.luckme-product-title {
  color: #3c57a6;
  font-weight: 500;
  font-size: 16px;
}

.luckme-worthy-price {
  padding-top: 5px;
  font-weight: 500;
  font-size: 15px;
}

.luckme-cart-btn {
  display: none;
}

.luckme-card:hover {
  min-height: 350px;
  max-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.luckme-card:hover .luckme-image {
  min-height: 190px;
  max-height: 190px;
  padding: 10px;
  object-fit: contain !important;
}
.luckme-card:hover .luckme-description {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  min-height: 160px;
  max-height: 160px;
}
.luckme-card:hover .luckme-product-title {
  margin-top: 5px;
  font-size: 14px;
}
.luckme-card:hover .luckme-cart-btn {
  padding-top: 10px;
  display: block;
}
.luckme-card:hover .shubhcards-cart-btn {
  padding-top: 10px;
  display: block;
}
.luckme-card:hover .luckme-btn {
  border-radius: 6px !important;
  padding: 5px 20px !important;
  background-color: #BDC62B !important;
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.luckme-footer {
  color: #212529 !important;
}

.aboutus-img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.ew-wrapper {
  margin-top: 20px !important;
  border-top-width: 20px !important;
  padding-top: 20px !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px !important;
}

.ew-header {
  color: #bdc72c;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
}

.para-container {
  padding-right: 18px !important;
  margin: 0 0 18px 0px !important;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 24px;
  color: #555;
}

.e3s-img {
  max-height: 40px !important;
}

.approach-container {
  background-color: #f4f4f4 !important;
  padding: 25px 20px !important;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px !important;
}

.left-cv-text {
  padding-top: 80px !important;
}

.right-cv-text {
  padding-bottom: 40px !important;
}

.bootom-caertificate {
  background-color: #48959F !important;
  width: 100%;
  padding: 35px 80px;
  color: #ffffff !important;
  padding-bottom: 45px;
}

.product-customize {
  padding: 20px 30px;
  min-height: 50vh;
  margin-top: 10px !important;
}

.menu-content {
  padding-bottom: 12px;
}

.menu-item {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
}

.sub-menu-container {
  padding-right: 18px;
}

.sub-menu-item {
  font-size: 14px;
  color: rgb(83, 82, 82);
  cursor: pointer;
  padding-bottom: 3px;
}

.menu-item:hover {
  cursor: pointer;
  color: rgb(34, 34, 34);
}

.sub-menu-item:hover {
  cursor: pointer;
  color: rgb(34, 34, 34);
}

.sub-selected-item {
  color: #000;
  font-weight: 500;
}

.hide-menu {
  height: 0px;
  visibility: hidden;
  transition: visibility height 2s;
}

.show-menu {
  visibility: visible;
  height: auto;
  transition: visibility 3s;
}

.selected-item {
  color: rgb(34, 34, 34);
  font-weight: 600;
  font-size: 18px;
}

.category-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #777 !important;
  padding: 4px;
  margin-right: 3px;
}

.category-icons {
  border: 1px solid rgb(34, 34, 34) !important;
}

.single-category {
  padding-right: 14px;
}

.menus-title {
  font-size: 32px;
  color: rgb(34, 34, 34);
  font-weight: 200 !important;
  padding-top: 4px;
}

.selected-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid rgb(34, 34, 34) !important;
  padding: 4px;
  margin-left: 8px;
}

.category-products-container {
  padding-left: 25px;
  border-left: 1px solid #dad9d9;
}

.product-list-container {
  padding-top: 15px;
  padding-right: 10px;
}

.category-product-card {
  padding-right: 15px;
  border-bottom: 1px solid rgb(218, 218, 218) !important;
  padding-bottom: 10px;
  padding-top: 10px;
}

.cp-title {
  font-size: 16px;
  font-weight: 500;
  color: #3e4152;
  word-break: break-word;
  padding-bottom: 6px;
  padding-top: 6px;
}

.rating-text {
  font-size: 14.5px;
  color: rgb(139, 139, 139) !important;
}

.cp-price {
  padding-top: 8px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  color: rgb(49, 49, 49);
}

.cp-description {
  color: rgba(40, 44, 63, 0.45);
  font-size: 13.5px;
  padding-bottom: 10px;
}

.cp-image {
  width: 118px !important;
  height: 96px !important;
  border-radius: 6px;
}

.cp-button-container {
  position: absolute;
  bottom: 8px;
  width: 96px;
  min-height: 36px;
  line-height: 36px;
  left: 10%;
  width: 80%;
  top: 76%;
}

.cp-image-container {
  min-width: 118px;
  min-height: 96px;
  max-width: 118px;
  max-height: 96px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
}

.cp-button {
  padding: 2px 15px;
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: #ffffff;
  width: 100% !important;
  color: rgb(34, 34, 34) !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  font-weight: 500 !important;
  font-size: 15px;
}

.cp-sm-btn {
  padding: 0px 10px;
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34) !important;
  background-color: #ffffff;
  color: rgb(34, 34, 34) !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
}

.customise-text {
  font-size: 11px !important;
  color: rgb(139, 139, 139) !important;
}

.m-content {
  padding: 10px 30px 0 30px;
}

.title-container {
  padding-bottom: 20px;
  border-bottom: 1px dashed #bebfc5;
}

.m-title {
  font-weight: 500;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 490px;
}

.enquiry-title {
  font-family: "proxima-regular", "sans-serif" !important;
  font-weight: 600 !important;
  color: #D57C30 !important;
  font-size: 22px !important;
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-word;
  white-space: normal;
  white-space: -webkit-pre-wrap;
}

.m-price {
  margin-top: 7px;
  color: #535665;
  font-size: 15px;
}

.options-container {
  margin-top: 10px;
  padding-bottom: 20px;
}

.options-title {
  font-size: 18px;
  color: rgb(65, 65, 65);
  font-weight: 400 !important;
  padding-top: 4px;
  padding-bottom: 10px;
}

.option-product {
  margin-left: 9px !important;
  font-size: 15px !important;
  font-weight: 200 !important;
}

input.largerCheckbox {
  margin-top: 5px;
  width: 16px;
  height: 16px;
}

input.largerCheckbox:hover {
  font-weight: 500;
}

.m-total {
  font-size: 18px;
  font-weight: 300 !important;
  padding-right: 25px !important;
  color: rgb(34, 34, 34);
}

.m-btn {
  color: #ffffff !important;
  background-color: rgb(34, 34, 34) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 6px 20px !important;
}

.cart-item-name {
  font-size: 12px;
  font-weight: 500;
}

.customize-txt {
  cursor: pointer;
  font-size: 11px;
  color: rgb(139, 139, 139) !important;
}

.cart-container {
  padding-top: 15px;
}

.error-img {
  height: 320px;
  width: 75%;
  margin-bottom: 15px;
}

.custom-btn {
  padding: 5px 30px !important;
  border-radius: 20px !important;
  margin-top: 10px !important;
  background-color: rgb(34, 34, 34) !important;
  border: none !important;
}

.customize-empty-cart {
  height: 210px;
  width: 260px;
  margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .enquiry-title {
    white-space: -webkit-pre-wrap;
    font-size: 20px !important;
  }
  .m-content {
    padding: 0 !important;
  }
}
.cart-nw-img {
  height: 200px;
  width: 300px;
  padding-bottom: 25px;
}

.cart-new-container {
  padding: 30px 20px;
}

.cart-em-header {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 20px;
}

.cart-mn-container {
  margin: 40px 50px !important;
}

.cart-new-card {
  height: 160px !important;
}

.cart-new-image {
  height: 160px;
  width: 100%;
}

.cart-footer {
  height: 40px;
  border-top: 1px solid rgb(212, 211, 211);
  padding-left: 1.25rem;
}

.cart-body {
  height: 120px;
  padding: 1.2rem 1rem;
  overflow-y: auto;
  padding-bottom: 0.5rem;
}

.cart-a {
  border-right: 1px solid rgb(212, 211, 211);
}

.cart-btn {
  color: #777 !important;
  font-size: 0.85rem !important;
  padding-top: 8px !important;
  cursor: pointer !important;
}

.cart-b {
  padding-top: 8px;
  color: rgb(34, 34, 34);
}

.cart-customize {
  color: rgb(34, 34, 34);
  font-size: 0.85rem !important;
  float: right;
  cursor: pointer !important;
}

.cart-customize:hover {
  font-weight: bold;
}

.earth-worthy-logo {
  margin-left: 30px !important;
}

.earthy-worthy-carousel {
  height: 650px !important;
}

.earthy-feature-collections {
  background-color: #272829;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.subhcards-feature-collections {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.luckme-feature-collections {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#luckmebrands .tab-image {
  min-height: 300px;
  max-height: 300px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
}
#luckmebrands .tab-image .background-image {
  background-size: 100% 300px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  min-height: 300px;
}
#luckmebrands .tab-image .background-image:hover {
  transform: scale(1.2);
}
#luckmebrands .tab-image .bg-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 25px;
}
#luckmebrands .tab-image .bg-content p {
  font-size: 15px;
}

.luckme-tabimage {
  border: 1px solid rgb(194, 194, 194) !important;
  border-radius: 10px;
}

.earthy-tabimage {
  border: 1px solid rgb(194, 194, 194) !important;
  border-radius: 10px;
}

.shubcards-tabimage {
  border: 1px solid #CC5500 !important;
  border-radius: 20px;
}

.earthy-card {
  margin: 5px !important;
  min-height: 340px;
  max-height: 380px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.earthy-image {
  min-height: 220px;
  max-height: 220px;
  padding: 10px;
}

.earthy-description {
  padding: 10px;
  min-height: 120px;
  max-height: 120px;
  background-color: #ffffff;
}

.earthy-product-title {
  color: #3c57a6;
  font-weight: 500;
  font-size: 15px;
}

.earthy-worthy-price {
  padding-top: 5px;
  font-weight: 500;
  font-size: 15px;
}

.earthy-cart-btn {
  display: none;
}

@media screen and (min-width: 601px) {
  .earthy-card:hover {
    min-height: 320px;
    max-height: 320px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .earthy-card:hover .earthy-image {
    min-height: 180px;
    max-height: 180px;
    padding: 10px;
  }
  .earthy-card:hover .earthy-description {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    min-height: 140px;
    max-height: 140px;
  }
  .earthy-card:hover .earthy-product-title {
    margin-top: 5px;
    font-size: 14px;
  }
  .earthy-card:hover .earthy-cart-btn {
    padding-top: 10px;
    display: block;
  }
  .earthy-card:hover .earthy-btn {
    border-radius: 6px !important;
    padding: 5px 20px !important;
    background-color: #BDC62B !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .earthy-card:hover .karupattiraja-btn {
    margin-top: 0px !important;
    border-radius: 6px !important;
    padding: 5px 20px !important;
    background-color: #212529 !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
}
.earthyworthy-footer {
  color: #212529 !important;
}

.luckme-card {
  margin: 5px !important;
  min-height: 350px;
  max-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.luckme-image {
  min-height: 220px;
  max-height: 220px;
  padding: 10px;
}

.luckme-description {
  padding: 10px;
  min-height: 130px;
  max-height: 130px;
  background-color: #ffffff;
}

.luckme-product-title {
  color: #3c57a6;
  font-weight: 500;
  font-size: 16px;
}

.luckme-worthy-price {
  padding-top: 5px;
  font-weight: 500;
  font-size: 15px;
}

.luckme-cart-btn {
  display: none;
}

.luckme-card:hover {
  min-height: 350px;
  max-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.luckme-card:hover .luckme-image {
  min-height: 190px;
  max-height: 190px;
  padding: 10px;
  object-fit: contain !important;
}
.luckme-card:hover .luckme-description {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  min-height: 160px;
  max-height: 160px;
}
.luckme-card:hover .luckme-product-title {
  margin-top: 5px;
  font-size: 14px;
}
.luckme-card:hover .luckme-cart-btn {
  padding-top: 10px;
  display: block;
}
.luckme-card:hover .shubhcards-cart-btn {
  padding-top: 10px;
  display: block;
}
.luckme-card:hover .luckme-btn {
  border-radius: 6px !important;
  padding: 5px 20px !important;
  background-color: #BDC62B !important;
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.luckme-footer {
  color: #212529 !important;
}

@media screen and (max-width: 600px) {
  .karupattiraja-btn {
    margin-top: 70px !important;
    border-radius: 6px !important;
    padding: 5px 20px !important;
    background-color: #212529 !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .earthy-card {
    margin: 15px !important;
  }
  .earthy-cart-btn {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 50px;
    top: 50px;
    left: 0%;
  }
  .earthy-cart-btn1 {
    display: flex;
    top: 50px;
    justify-content: center;
  }
}